import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import { enqueueSnackbar } from 'notistack';

interface InpaintInputsProps {
  setManual: (stage: string, state: boolean) => void;
}

const InpaintInputs = forwardRef<
  {
    getInputs: () => any;
    resetModal: () => void;
  },
  InpaintInputsProps
>((props, ref) => {
  const configuration = useAppSelector(
    (state: RootState) => state.configurationReducer.data!.services.inpaint,
  );
  const defaultManuals = Object.fromEntries(
    configuration.manual_inputs.map((name: string) => {
      return [name, ''];
    }),
  );
  const [isManualInput, setIsManualInput] = useState(configuration.is_manual);
  const [manualInputs, setManualInputs] = useState<{ [name: string]: string }>(
    defaultManuals,
  );

  useEffect(() => {
    props.setManual('inpaint', isManualInput);
    // eslint-disable-next-line
  }, [isManualInput]);

  useImperativeHandle(ref, () => ({
    async getInputs() {
      if (isManualInput) {
        for (const name of Object.keys(manualInputs)) {
          if (manualInputs[name].length === 0) {
            enqueueSnackbar(`Inpaint ${name} path not found!`, {
              variant: 'warning',
            });
            return null;
          }
        }
        return { manual: manualInputs };
      }
      return {};
    },
    resetModal() {
      setManualInputs(defaultManuals);
      setIsManualInput(false);
    },
  }));

  return <Container></Container>;
});

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

export default InpaintInputs;
