import { JsonEditor } from 'json-edit-react';
import React from 'react';
import styled from 'styled-components';
import { FormHelperText, Grid, Paper } from '@mui/material';

interface JsonProps {
  data: object;
  onUpdate: (newData: object) => void;
  description: string;
}
const JsonControl = (props: JsonProps) => {
  return (
    <Grid container>
      <Grid item xs={1.75} sx={{ paddingTop: '15px' }}>
        <FormHelperText>{props.description}</FormHelperText>
      </Grid>
      <Grid item xs={10.25}>
        <Paper elevation={5}>
          <JsonContainer>
            <JsonEditor
              minWidth="auto"
              maxWidth="auto"
              rootName={props.description}
              theme="githubLight"
              data={props.data}
              onUpdate={(newData: any) => {
                props.onUpdate(newData.newData);
              }}
            />
          </JsonContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

const JsonContainer = styled.div`
  height: 280px;
  display: flex;
  overflow: scroll;
  width: 100%;
  border-radius: 5px;
  margin-top: 7px;
`;
export default JsonControl;
