import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import { UploadFile } from '../../Controls/SelectFiles';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import { deleteTasks, loadTask } from '../../../stores/tasks';
import HeadDesignsTable from '../../Tables/HeadDesignsTable';
import DialogControl from '../../Controls/DialowWindow';
import { Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingIcon from '../../Controls/LoadingIcon';
import Modal from '@mui/material/Modal';
import MannequinSystemsApi from '../../../api/MannequinSystemsApi';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import SelectControl from '../../Controls/SelectControl';
import CachedIcon from '@mui/icons-material/Cached';
import TextFieldControl from '../../Controls/TextFieldControl';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

const defaultPositivePrompt =
  '(close-up, editorial photograph of {gender}), (highly detailed face:1.4) nikon d850, film stock photograph ,4 kodak portra 400 ,camera f1.6 lens ,rich colors ,hyper realistic ,lifelike texture, dramatic lighting , cinestill 800"';

const HeadDesigner = () => {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector((state: RootState) => state.tasksReducer);
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const headDesign = jobs.head_design;

  const [archiveToUpload, setArchiveToUpload] = useState<File | null>(null);
  const [newArchiveName, setNewArchiveName] = useState('');
  const [dialogOpened, setDialogOpened] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [selectedGender, setSelectedGender] = useState('woman');
  const [positivePrompt, setPositivePrompt] = useState(defaultPositivePrompt);

  const loadJobs = (extend?: boolean) => {
    dispatch(
      loadTask({
        lastKey: extend ? headDesign?.lastKey : null,
        type: 'head_design',
      }),
    );
  };

  useEffect(() => {
    if (headDesign === null) loadJobs();
    // eslint-disable-next-line
  }, []);

  const removeTasks = async (taskIds: string[]) => {
    await dispatch(deleteTasks({ taskIds: taskIds, type: 'head_design' }));
  };

  const processArchive = () => {
    if (archiveToUpload) {
      let fileName = archiveToUpload.name.replaceAll(' ', '_');
      setIsProcessing(true);
      getUploadUrl(fileName).then((uploadUrlData) => {
        axios
          .put(uploadUrlData.presignedUrl, archiveToUpload)
          .then(() => {
            MannequinSystemsApi.scheduleHeadDesign(
              userData!.userName,
              uploadUrlData.s3_path,
              newArchiveName,
              selectedGender,
              positivePrompt,
            )
              .then((response) => {
                if (response.status !== 200)
                  enqueueSnackbar(
                    `Failed to schedule head design jobs. Error: ${response.statusText}`,
                    { variant: 'error' },
                  );
                else if (response.data.errorMessage)
                  enqueueSnackbar(
                    `Failed to schedule head design jobs. Error: ${response.data.errorMessage}`,
                    { variant: 'error' },
                  );
                else
                  enqueueSnackbar(`${fileName} jobs are scheduled.`, {
                    variant: 'success',
                    action: () => (
                      <IconButton
                        onClick={() => {
                          loadJobs();
                        }}
                      >
                        <CachedIcon />
                      </IconButton>
                    ),
                  });
              })
              .catch((error) => {
                enqueueSnackbar(
                  `Failed to schedule head design jobs. Error: ${error}`,
                  { variant: 'error' },
                );
              });
            setIsProcessing(false);
            enqueueSnackbar(`Scheduling jobs.`, { variant: 'info' });
          })
          .catch((error) => {
            enqueueSnackbar(
              `Failed to schedule head design jobs. Error: ${error}`,
              { variant: 'error' },
            );
            setIsProcessing(false);
          });
      });
    }
  };

  const getUploadUrl = async (fileName: string) => {
    const response = await MannequinSystemsApi.getUploadUrl(
      fileName,
      'head_design/jobs',
    );
    if (response.status === 200 && response.data) {
      return response.data;
    }
  };

  return (
    <Container>
      <Modal open={isProcessing}>
        <LoadingIcon />
      </Modal>
      <DialogControl
        isOpen={dialogOpened}
        title={`Upload new head design`}
        description={''}
        setIsOpen={setDialogOpened}
        approveAction={processArchive}
      >
        <ArchiveWrapper>
          <FileSelectWrapper>
            <UploadFile
              setFilesToUpload={(files: File[]) => {
                setArchiveToUpload(files[0]);
                setNewArchiveName(
                  files[0].name.split('.').slice(0, -1).join('.'),
                );
              }}
              accept={{
                'application/zip': ['.zip'],
              }}
              multiple={false}
            />
          </FileSelectWrapper>
          {archiveToUpload && (
            <ZipContainer>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <ArchiveDetails>
                    <Typography fontWeight={700}>
                      {archiveToUpload.name}
                    </Typography>
                    <Typography fontWeight={300}>
                      {(archiveToUpload.size / 1048576).toFixed(2)} MB
                    </Typography>
                  </ArchiveDetails>
                </Grid>
                <Grid item xs={5}>
                  <TextFieldControl
                    description={''}
                    value={newArchiveName}
                    id={''}
                    onChange={(field) => {
                      setNewArchiveName(field.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectControl
                    id={''}
                    value={selectedGender}
                    items={['woman', 'man']}
                    description={''}
                    disabled={false}
                    canBeEmpty={false}
                    onChange={(field) => {
                      setSelectedGender(field.value);
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      setArchiveToUpload(null);
                    }}
                  >
                    <DeleteIcon fontSize={'medium'} />
                  </IconButton>
                </Grid>
                <Grid item xs={11}>
                  <TextFieldControl
                    description={'Positive prompt'}
                    value={positivePrompt}
                    id={''}
                    onChange={(field) => {
                      setPositivePrompt(field.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    paddingTop: '28px',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setPositivePrompt(defaultPositivePrompt);
                    }}
                  >
                    <SettingsBackupRestoreIcon fontSize={'medium'} />
                  </IconButton>
                </Grid>
              </Grid>
            </ZipContainer>
          )}
        </ArchiveWrapper>
      </DialogControl>
      <Header name={'Head Designer'} />
      <HeadDesignsContainer>
        <HeadDesignsTable
          key={'head_design'}
          data={headDesign ? Object.values(headDesign.tasks) : []}
          deleteTasks={removeTasks}
          reloadData={loadJobs}
          uploadDesign={() => {
            setPositivePrompt(defaultPositivePrompt);
            setArchiveToUpload(null);
            setDialogOpened(true);
          }}
          loading={headDesign?.isLoading || false}
          moreDataExist={!!headDesign?.lastKey}
        />
      </HeadDesignsContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const HeadDesignsContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const ArchiveWrapper = styled.div`
  width: 1000px;
  height: 450px;
`;

const FileSelectWrapper = styled.div`
  height: 150px;
  border: 2px dashed rgba(55, 55, 55, 0.58);
  border-radius: 10px;
  text-align: center;
`;

const ZipContainer = styled.div`
  margin-top: 2%;
  border-radius: 10px;
  border: 2px solid rgba(55, 55, 55, 0.58);
  align-items: center;
  padding: 2%;
`;

const ArchiveDetails = styled.div`
  padding-left: 2%;
`;

export default HeadDesigner;
