import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';
import SelectControl from '../../Controls/SelectControl';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldControl from '../../Controls/TextFieldControl';
import {
  AnyObject,
  Components,
  DynamicComponentProps,
  HandleType,
} from '../../../configs';
import PresetControl from '../../Controls/PresetControl';
import EditIcon from '@mui/icons-material/Edit';
import DialogControl from '../../Controls/DialowWindow';
import { MlPipelineDto } from '../../../api/types/GetConfigurationResponseDto';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { presetAdd, presetRemove } from '../../../stores/configuration';
import { RootState } from '../../../stores/types';
import { enqueueSnackbar } from 'notistack';
import SearchIcon from '@mui/icons-material/Search';

interface MlInputsProps {
  setManual: (stage: string, state: boolean) => void;
  sourceLink: string;
}

const MlInputs = forwardRef<
  { getInputs: () => any; resetModal: () => void },
  MlInputsProps
>((props, ref) => {
  const dispatch = useAppDispatch();
  const configuration = useAppSelector(
    (state: RootState) => state.configurationReducer.data,
  );
  const [selectedPipelineVersion, setSelectedPipelineVersion] = useState(
    configuration!.default_ml_pipeline,
  );
  const presetNames = Object.keys(configuration!.ml_presets);
  const [structure, setStructure] = useState<any[]>([]);
  const [state, setState] = useState({});
  const [defaultState, setDefaultState] = useState({});
  const [accordionsState, setAccordionsState] = useState({});
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [selectedPreset, setSelectedPreset] = useState<null | string>(null);
  const [presetInEditMode, setPresetInEditMode] = useState(false);
  const [selectedPresetPipelineVersion, setSelectedPresetPipelineVersion] =
    useState<null | string>(null);
  const [textFieldIds, setTextFieldIds] = useState<string[]>([]);

  const mlConfiguration = configuration!.services.ml;
  const defaultManuals = Object.fromEntries(
    mlConfiguration.manual_inputs.map((name: string) => {
      return [name, ''];
    }),
  );
  const [isManualInput, setIsManualInput] = useState(mlConfiguration.is_manual);
  const [manualInputs, setManualInputs] = useState<{ [name: string]: string }>(
    defaultManuals,
  );

  useEffect(() => {
    props.setManual('ml', isManualInput);
    // eslint-disable-next-line
  }, [isManualInput]);

  useImperativeHandle(ref, () => ({
    getInputs() {
      return processInputs();
    },
    resetModal,
  }));

  useEffect(() => {
    if (selectedPipelineVersion !== '') {
      buildForm(configuration!.ml_pipelines[selectedPipelineVersion]);
    }
    // eslint-disable-next-line
  }, [selectedPipelineVersion]);

  useEffect(() => {
    if (selectedPreset) {
      let preset = configuration!.ml_presets[selectedPreset];
      if (preset) {
        setSelectedPresetPipelineVersion(preset.pipeline_version);
        setSelectedPipelineVersion(preset.pipeline_version);
        setState(preset.data);
      }
    }
    // eslint-disable-next-line
  }, [selectedPreset]);

  const addPreset = (name: string, data: {}, pipelineVersion: string) => {
    dispatch(
      presetAdd({
        name: name,
        data: data,
        pipelineVersion: pipelineVersion,
      }),
    );
  };

  const buildForm = (pipelineVersion: MlPipelineDto) => {
    let tempExpandedModals = {};
    let tempTextFieldIds: string[] = [];
    pipelineVersion.components.forEach((component: any) => {
      if (component.type === 'accordion') {
        // @ts-ignore
        tempExpandedModals[component.data.id] = component.data.expanded;
        component.children.forEach((child: any) => {
          if (child.type === 'textField' && child.data.required) {
            tempTextFieldIds.push(child.data.id);
          }
        });
      }
    });
    setAccordionsState(tempExpandedModals);
    setStructure(pipelineVersion.components);
    if (selectedPresetPipelineVersion !== pipelineVersion.name) {
      setSelectedPresetPipelineVersion(null);
      setSelectedPreset(null);
      setState(pipelineVersion.data);
      setDefaultState(pipelineVersion.data);
      setTextFieldIds(tempTextFieldIds);
    }
  };

  const render = (component: DynamicComponentProps, componentKey: number) => {
    let componentType = component.type;
    const TagName = Components[componentType as keyof AnyObject];
    let params = {};
    if (component.data.params) {
      component.data.params.forEach((param: HandleType) => {
        // @ts-ignore
        params[param.name] = state[param.value];
      });
    }
    if (componentType === 'accordion') {
      let componentId = component.data.id;
      // @ts-ignore
      params['expanded'] = accordionsState[componentId];
    }
    let handleFunction =
      componentType === 'accordion' ? handleExpand : handleOnChange;
    let renderedComponent = (
      <TagName {...component.data} {...params} onChange={handleFunction}>
        {component.children &&
          component.children.map((children, key) => {
            return <div key={key}>{render(children, key)}</div>;
          })}
      </TagName>
    );
    if (component.gridWrap) {
      return (
        <Grid item xs={12} key={componentKey}>
          {renderedComponent}
        </Grid>
      );
    }
    return renderedComponent;
  };

  const handleOnChange = (field: HandleType) => {
    setState((prevState) => ({ ...prevState, [field.name]: field.value }));
  };

  const handleExpand = (field: HandleType) => {
    setAccordionsState((prevState) => ({
      ...prevState,
      [field.name]: field.value,
    }));
  };

  const findPreset = () => {
    if (props.sourceLink !== '') {
      let indexFrom = props.sourceLink.indexOf('model_');
      if (indexFrom !== -1) {
        let modelName = props.sourceLink.substring(
          indexFrom,
          props.sourceLink.indexOf('/', indexFrom),
        );
        if (presetNames.includes(modelName)) {
          setSelectedPreset(modelName);
        } else {
          enqueueSnackbar('Preset "' + modelName + '" not found!', {
            variant: 'warning',
          });
          setSelectedPreset(null);
        }
      } else {
        enqueueSnackbar('Preset not found!', { variant: 'warning' });
        setSelectedPreset(null);
      }
    }
  };

  const resetModal = () => {
    setSelectedPipelineVersion(configuration!.default_ml_pipeline);
    setSelectedPreset(null);
    setState(defaultState);
    setPresetInEditMode(false);
    setManualInputs(defaultManuals);
    setIsManualInput(false);
  };

  const updateManualInput = (name: string, value: string) => {
    setManualInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const prepareRunData = async () => {
    let runData = new Map();
    Object.keys(state).forEach((key: string) => {
      if (!key.includes('Expanded') && key !== 'preset') {
        runData.set(key, state[key as keyof typeof state]);
      }
    });
    runData.set('pipeline_version', selectedPipelineVersion);
    return { data: Object.fromEntries(runData) };
  };

  const savePreset = () => {
    if (
      selectedPreset &&
      (!presetNames.includes(selectedPreset) || presetInEditMode)
    )
      addPreset(selectedPreset, state, selectedPipelineVersion);
  };

  const deletePreset = () => {
    if (selectedPreset) {
      setSelectedPreset(null);
      dispatch(presetRemove(selectedPreset));
    }
  };

  const processInputs = () => {
    savePreset();
    if (isManualInput) {
      for (const name of Object.keys(manualInputs)) {
        if (manualInputs[name].length === 0) {
          enqueueSnackbar(`Ml ${name} path not found!`, {
            variant: 'warning',
          });
          return null;
        }
      }
      return { manual: manualInputs };
    }
    for (const index of textFieldIds) {
      // @ts-ignore
      if (state[index].length === 0) {
        enqueueSnackbar(`The field ${index} is not filled`, {
          variant: 'warning',
        });
        return null;
      }
    }
    return prepareRunData();
  };

  return (
    <Container>
      <DialogControl
        isOpen={dialogOpened}
        title={'Remove preset'}
        description={dialogMessage}
        setIsOpen={setDialogOpened}
        approveAction={deletePreset}
      ></DialogControl>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <FormControl>
            <FormHelperText>Manual</FormHelperText>
            <Checkbox
              checked={isManualInput}
              color="default"
              onChange={() => {
                if (isManualInput) {
                  setManualInputs(defaultManuals);
                }
                setIsManualInput(!isManualInput);
              }}
              size={'medium'}
            />
          </FormControl>
        </Grid>
        {isManualInput ? (
          <Grid item xs={11}>
            {Object.keys(manualInputs).map((name: string, key) => {
              return (
                <TextFieldControl
                  key={key}
                  value={manualInputs[name]}
                  onChange={(field) => {
                    updateManualInput(name, field.value);
                  }}
                  id={`${name}_input`}
                  description={`${name} link`}
                />
              );
            })}
          </Grid>
        ) : (
          <Grid container item xs={11} spacing={1}>
            <Grid item xs={5.5}>
              <SelectControl
                id={'pipelineVersion'}
                value={selectedPipelineVersion}
                items={Object.keys(configuration!.ml_pipelines)}
                description={'Pipeline version'}
                disabled={false}
                canBeEmpty={false}
                onChange={(field) => {
                  setSelectedPipelineVersion(field.value);
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <PresetControl
                items={presetNames}
                selectedPreset={selectedPreset}
                setSelectedPreset={setSelectedPreset}
                disabled={presetInEditMode}
              />
            </Grid>
            <Grid item xs={0.5}>
              <FormControl style={{ width: '100%' }}>
                <FormControl>
                  <FormHelperText> </FormHelperText>
                  <IconButton
                    disabled={props.sourceLink === '' || presetInEditMode}
                    onClick={findPreset}
                  >
                    <SearchIcon fontSize="inherit" />
                  </IconButton>
                </FormControl>
              </FormControl>
            </Grid>
            <Grid item xs={0.5} style={{ textAlign: 'center' }}>
              <FormControl>
                <FormHelperText> </FormHelperText>
                <IconButton
                  disabled={
                    selectedPreset === null ||
                    !presetNames.includes(selectedPreset)
                  }
                  onClick={() => {
                    setPresetInEditMode(!presetInEditMode);
                  }}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </FormControl>
            </Grid>
            <Grid item xs={0.5} style={{ textAlign: 'center' }}>
              <FormControl>
                <FormHelperText> </FormHelperText>
                <IconButton
                  disabled={
                    selectedPreset === null ||
                    presetInEditMode ||
                    !presetNames.includes(selectedPreset)
                  }
                  onClick={() => {
                    setDialogMessage(
                      'Are you sure you want to delete preset "' +
                        selectedPreset +
                        '"?',
                    );
                    setDialogOpened(true);
                  }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </FormControl>
            </Grid>

            {structure.map((component, key) => {
              return render(component, key);
            })}
          </Grid>
        )}
      </Grid>
    </Container>
  );
});

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

export default MlInputs;
