import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import MannequinSystemsApi from '../../api/MannequinSystemsApi';

interface TasksData {
  tasks: { [index: string]: any };
  isLoading: boolean;
  lastKey: any;
}

const initialState: Record<string, TasksData | null> = {
  task: null,
  segmentation: null,
  render: null,
  ml: null,
  fit: null,
  inpaint: null,
  head_design: null,
  photoshoot: null,
  reconstruction: null,
  run: null,
};

export const loadTask = createAsyncThunk(
  'tasks/loadTasks',
  async ({ lastKey, type }: { lastKey: any; type: string }) => {
    const tasksResponse = await MannequinSystemsApi.getTasks(lastKey, type);
    return tasksResponse.data;
  },
);

export const setTaskCheckingState = createAsyncThunk(
  'tasks/setTaskCheckingState',
  async ({
    taskIds,
    checkingState,
  }: {
    taskIds: string[];
    checkingState: string;
  }) => {
    const response = await MannequinSystemsApi.setTaskCheckingState(
      taskIds,
      checkingState,
    );
    return response.data;
  },
);

export const addTask = createAsyncThunk(
  'addTask',
  async ({
    taskData,
    stage,
    userName,
  }: {
    taskData: {};
    stage: string;
    userName: string;
  }) => {
    const addTaskResponse = await MannequinSystemsApi.createTask(
      taskData,
      stage,
      userName,
    );
    return addTaskResponse.data;
  },
);

export const deleteTasks = createAsyncThunk(
  'deleteTask',
  async ({ taskIds, type }: { taskIds: string[]; type: string }) => {
    const deleteTaskResponse = await MannequinSystemsApi.deleteTasks(
      taskIds,
      type,
    );
    return deleteTaskResponse.data;
  },
);

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(loadTask.pending, (state, action) => {
        const key = action.meta.arg;
        if (!state[key.type]) {
          state[key.type] = {
            tasks: {},
            isLoading: false,
            lastKey: null,
          };
        }
        state[key.type]!.isLoading = true;
      })
      .addCase(loadTask.fulfilled, (state, action) => {
        const key = action.meta.arg;
        state[key.type]!.isLoading = false;
        if (action.payload.statusCode === 200) {
          state[key.type]!.lastKey = action.payload.lastKey;
          state[key.type]!.tasks = {
            ...(key.lastKey ? state[key.type]!.tasks : {}),
            ...Object.fromEntries(
              action.payload.tasks.map((obj: any) => [obj.id, obj]),
            ),
          };
        } else {
          enqueueSnackbar(
            'Failed to load tasks. Error:' + action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(loadTask.rejected, (state, action) => {
        const key = action.meta.arg;
        state[key.type]!.isLoading = false;
        enqueueSnackbar(
          'Failed to load tasks. Error:' + action.error.message || 'null',
          { variant: 'error' },
        );
      })
      .addCase(addTask.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          const task = action.payload.task;
          if (state.task !== null) {
            state.task = {
              [task.id]: task,
              ...state.task,
            };
          }
        } else {
          enqueueSnackbar(
            'Failed to create new task. Error:' + action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(addTask.rejected, (state, action) => {
        enqueueSnackbar(
          'Failed to create new task. Error:' + action.error.message || 'null',
          { variant: 'error' },
        );
      })
      .addCase(setTaskCheckingState.fulfilled, (state, action) => {
        const { checkingState, taskIds } = action.meta.arg;
        let successTasks: string[] = taskIds.filter(
          (item) => !(action.payload.failedTasks || []).includes(item),
        );
        successTasks.forEach((taskId) => {
          state.task!.tasks[taskId].checking_state = checkingState;
        });
        if (action.payload.statusCode !== 200) {
          enqueueSnackbar(
            `Failed to set tasks ${action.payload.failedTasks} as ${checkingState}. Error: ${action.payload.errorMessage}`,
            { variant: 'error' },
          );
        }
      })
      .addCase(setTaskCheckingState.rejected, (state, action) => {
        enqueueSnackbar(
          `Failed to set task as ${action.meta.arg.checkingState}. Error: ${
            action.error.message || 'null'
          }`,
          { variant: 'error' },
        );
      })
      .addCase(deleteTasks.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          action.payload.ids.forEach((id: string) => {
            delete state[action.meta.arg.type]!.tasks[id];
          });
        } else {
          enqueueSnackbar(
            'Failed to delete tasks. Error:' + action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(deleteTasks.rejected, (state, action) => {
        const key = action.meta.arg;
        state[key.type]!.isLoading = false;
        enqueueSnackbar(
          'Failed to delete tasks.' + action.error.message || 'null',
          { variant: 'error' },
        );
      });
  },
});

export default tasksSlice.reducer;
