import MannequinSystemsApi from '../../api/MannequinSystemsApi';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import {
  TableSettings,
  UserDataDto,
} from '../../api/types/GetUserDataResponseDto';

interface UserData {
  userData: UserDataDto | null;
  isMaintenanceModeOn: boolean;
  tableSettings: Record<string, TableSettings>;
  isLoading: boolean;
}

const initialState: UserData = {
  userData: null,
  isMaintenanceModeOn: true,
  tableSettings: {},
  isLoading: true,
};

export const getUserData = createAsyncThunk(
  'getUserData',
  async (userName: string) => {
    const response = await MannequinSystemsApi.getUserData(userName);
    return response.data;
  },
);

export const updateTableSettings = createAsyncThunk(
  'updateTableSettings',
  async ({
    userName,
    tableName,
    tableSettings,
  }: {
    userName: string;
    tableName: string;
    tableSettings: TableSettings;
  }) => {
    const response = await MannequinSystemsApi.updateTableSettings(
      userName,
      tableName,
      tableSettings,
    );
    return response.data;
  },
);

export const usersSlice = createSlice({
  name: 'usersData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userData = action.payload.userData;
        state.tableSettings = action.payload.tableSettings;
        state.isMaintenanceModeOn = action.payload.isMaintenanceModeOn;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.isLoading = false;
        enqueueSnackbar(
          'Failed to load user data.' + action.error.message || 'null',
          { variant: 'error' },
        );
      })
      .addCase(updateTableSettings.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          enqueueSnackbar(
            'The table settings have been successfully changed.',
            {
              variant: 'success',
            },
          );
          const { tableName, tableSettings } = action.meta.arg;
          state.tableSettings[tableName] = tableSettings;
        }
      })
      .addCase(updateTableSettings.rejected, (state, action) => {
        enqueueSnackbar(
          'Failed to update table settings.' + action.error.message || 'null',
          { variant: 'error' },
        );
      });
  },
});

export default usersSlice.reducer;
