import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface DialogControlProps {
  isOpen: boolean;
  title: string;
  description: string;
  setIsOpen: { (selection: boolean): void };
  approveAction: { (): void };
  actionButtonsHide?: boolean;
  children?: React.ReactNode;
  approveButtonDisabled?: boolean;
  onCloseAction?: { (): void };
  isClosable?: boolean;
}

const DialogControl = (props: DialogControlProps) => {
  const handleClose = () => {
    if (props.isClosable === undefined || props.isClosable) {
      props.setIsOpen(false);
      if (props.onCloseAction) {
        props.onCloseAction();
      }
    }
  };

  const handleApprove = () => {
    props.approveAction();
  };

  return (
    <Dialog
      open={props.isOpen}
      onClose={handleClose}
      maxWidth={'lg'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
        </DialogContentText>
        {props.children}
      </DialogContent>
      <DialogActions>
        {!props.actionButtonsHide && (
          <div>
            <Button color="inherit" onClick={handleClose}>
              Cancel
            </Button>
            <span>
              <Button
                color="inherit"
                onClick={handleApprove}
                autoFocus
                disabled={props.approveButtonDisabled}
              >
                Approve
              </Button>
            </span>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogControl;
