import React, { useEffect, useState } from 'react';
import './App.css';
import styled from 'styled-components';
import MannequinSystems from './components/MannequinSystems';
import { Amplify, Auth } from 'aws-amplify';
import { COGNITO } from './configs';
import LoadingIcon from './components/Controls/LoadingIcon';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { store } from './stores/store';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

const App = () => {
  const [loading, setLoading] = useState(true);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((userData) => {
      if (userData) {
        setUserName(userData.username);
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingIcon />
      </LoadingContainer>
    );
  } else
    return (
      <Provider store={store}>
        <SnackbarProvider maxSnack={5} autoHideDuration={7000}>
          <MannequinSystems userName={userName} />
        </SnackbarProvider>
      </Provider>
    );
};

const LoadingContainer = styled.div`
  min-height: 775px;
  display: grid;
`;

export default withAuthenticator(App);
