import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import styled from 'styled-components';
import { Checkbox, FormControl, FormHelperText, Grid } from '@mui/material';
import TextFieldControl from '../../Controls/TextFieldControl';
import { useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import { enqueueSnackbar } from 'notistack';

interface SegmentationInputsProps {
  setManual: (stage: string, state: boolean) => void;
}

const SegmentationInputs = forwardRef<
  {
    getInputs: () => any;
    resetModal: () => void;
  },
  SegmentationInputsProps
>((props, ref) => {
  const configuration = useAppSelector(
    (state: RootState) =>
      state.configurationReducer.data!.services.segmentation,
  );
  const defaultManuals = Object.fromEntries(
    configuration.manual_inputs.map((name: string) => {
      return [name, ''];
    }),
  );
  const [isManualInput, setIsManualInput] = useState(configuration.is_manual);
  const [manualInputs, setManualInputs] = useState<{ [name: string]: string }>(
    defaultManuals,
  );

  useEffect(() => {
    props.setManual('segmentation', isManualInput);
    // eslint-disable-next-line
  }, [isManualInput]);

  useImperativeHandle(ref, () => ({
    async getInputs() {
      if (isManualInput) {
        for (const name of Object.keys(manualInputs)) {
          if (manualInputs[name].length === 0) {
            enqueueSnackbar(`Segmentation ${name} path not found!`, {
              variant: 'warning',
            });
            return null;
          }
        }
        return { manual: manualInputs };
      }
      return {};
    },
    resetModal() {
      setManualInputs(defaultManuals);
      setIsManualInput(false);
    },
  }));

  const updateManualInput = (name: string, value: string) => {
    setManualInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <FormControl>
            <FormHelperText>Manual</FormHelperText>
            <Checkbox
              checked={isManualInput}
              color="default"
              onChange={() => {
                if (isManualInput) {
                  setManualInputs(defaultManuals);
                }
                setIsManualInput(!isManualInput);
              }}
              size={'medium'}
            />
          </FormControl>
        </Grid>
        {isManualInput && (
          <Grid item xs={11}>
            {Object.keys(manualInputs).map((name: string, key) => {
              return (
                <TextFieldControl
                  key={key}
                  value={manualInputs[name]}
                  onChange={(field) => {
                    updateManualInput(name, field.value);
                  }}
                  id={`${name}_input`}
                  description={`${name} link`}
                />
              );
            })}
          </Grid>
        )}
      </Grid>
    </Container>
  );
});

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

export default SegmentationInputs;
