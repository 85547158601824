import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MannequinSystemsApi from '../../api/MannequinSystemsApi';
import {
  ConfigurationDto,
  PresetDto,
} from '../../api/types/GetConfigurationResponseDto';
import { enqueueSnackbar } from 'notistack';
import { Changes } from '../../components/Settings';

interface Configuration {
  data: ConfigurationDto | null;
  isLoading: boolean;
}

const initialState: Configuration = {
  data: null,
  isLoading: false,
};

export const loadConfiguration = createAsyncThunk(
  'loadConfiguration',
  async () => {
    const responseLoadConfiguration =
      await MannequinSystemsApi.getConfiguration();
    return responseLoadConfiguration.data;
  },
);

export const presetAdd = createAsyncThunk(
  'presetAdd',
  async ({
    name,
    data,
    pipelineVersion,
  }: {
    name: string;
    data: {};
    pipelineVersion: string;
  }) => {
    const response = await MannequinSystemsApi.savePreset(
      name,
      data,
      pipelineVersion,
    );
    return response.data;
  },
);

export const presetRemove = createAsyncThunk(
  'presetRemove',
  async (presetName: string) => {
    const response = await MannequinSystemsApi.removePreset(presetName);
    return response.data;
  },
);

export const updateConfiguration = createAsyncThunk(
  'updateConfiguration',
  async (changes: Changes) => {
    const response = await MannequinSystemsApi.updateConfiguration(changes);
    return response.data;
  },
);

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(loadConfiguration.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadConfiguration.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload.statusCode === 200) {
          state.data = action.payload.configuration;
        } else {
          enqueueSnackbar(
            'Failed to load configuration. Error:' +
              action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(loadConfiguration.rejected, (state, action) => {
        state.isLoading = false;
        enqueueSnackbar(
          'Failed to load configuration. Error:' + action.error.message ||
            'null',
          { variant: 'error' },
        );
      })
      .addCase(presetAdd.fulfilled, (state, action) => {
        if (action.payload.statusCode !== 200) {
          enqueueSnackbar(
            'Failed to save preset. Error:' + action.payload.errorMessage,
            { variant: 'error' },
          );
        } else {
          const newPreset = action.meta.arg;
          const preset: PresetDto = {
            name: newPreset.name,
            data: newPreset.data,
            pipeline_version: newPreset.pipelineVersion,
          };
          state.data!.ml_presets = {
            [preset.name]: preset,
            ...state.data!.ml_presets,
          };
        }
      })
      .addCase(presetAdd.rejected, (state, action) => {
        enqueueSnackbar(
          'Failed to save preset. Error:' + action.error.message || `null`,
          { variant: 'error' },
        );
      })
      .addCase(presetRemove.fulfilled, (state, action) => {
        if (action.payload.statusCode !== 200) {
          enqueueSnackbar(
            'Failed to delete preset. Error:' + action.payload.errorMessage,
            { variant: 'error' },
          );
        } else {
          delete state.data!.ml_presets[action.meta.arg];
        }
      })
      .addCase(presetRemove.rejected, (state, action) => {
        enqueueSnackbar(
          'Failed to delete preset. Error:' + action.error.message || `null`,
          { variant: 'error' },
        );
      })
      .addCase(updateConfiguration.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          let changes: Changes = action.meta.arg;
          if (changes.changed) {
            changes.changed.forEach((change) => {
              // @ts-ignore
              let section = state.data[change.objectSection];
              section[change.objectName] = {
                ...section[change.objectName],
                ...change.newValue,
              };
            });
          }
          if (changes.deleted) {
            changes.deleted.forEach((change) => {
              // @ts-ignore
              delete state.data![change.objectSection][change.objectName];
            });
          }
          if (changes.created) {
            changes.created.forEach((change) => {
              // @ts-ignore
              state.data[change.objectSection] = {
                [change.objectName]: change.newValue,
                // @ts-ignore
                ...state.data[change.objectSection],
              };
            });
          }
          if (changes.defaults) {
            changes.defaults.forEach((change) => {
              Object.entries(change.newValue).forEach(([key, value]) => {
                // @ts-ignore
                state.data[key] = value;
              });
            });
          }
        } else {
          enqueueSnackbar(
            'Failed to save configuration. Error:' +
              action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(updateConfiguration.rejected, (state, action) => {
        enqueueSnackbar(
          'Failed to save configuration. Error:' + action.error.message ||
            `null`,
          { variant: 'error' },
        );
      });
  },
});

export default configurationSlice.reducer;
