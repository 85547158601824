import { combineReducers } from '@reduxjs/toolkit';
import entitiesReducer from './entities';
import tasksReducer from './tasks';
import configurationReducer from './configuration';
import usersReducer from './users';

export const rootReducer = combineReducers({
  entitiesReducer,
  tasksReducer,
  configurationReducer,
  usersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
