import { FormControl, FormHelperText, MenuItem, Select } from '@mui/material';
import React from 'react';

interface SelectProps {
  description: string;
  value: any;
  onChange: (event: any) => void;
  disabled: boolean;
  data: object;
  renderValue: (value: any) => any;
  displayText: (item: any) => string;
}

const CustomSelect = (props: SelectProps) => {
  return (
    <div>
      <FormControl
        sx={{
          width: '100%', // input label when focused
          '& label.Mui-focused': {
            color: 'black',
          },
          // focused color for input with variant='standard'
          '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
          },
          // focused color for input with variant='filled'
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'black',
          },
          // focused color for input with variant='outlined'
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
        }}
      >
        <FormHelperText>{props.description}</FormHelperText>
        <Select
          variant={'outlined'}
          size={'small'}
          fullWidth
          renderValue={props.renderValue}
          value={props.value}
          displayEmpty={true}
          inputProps={{ 'aria-label': 'Without label' }}
          onChange={props.onChange}
          disabled={props.disabled}
          MenuProps={{
            PaperProps: {
              sx: { maxHeight: '300px', overflow: 'scroll' },
            },
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Object.values(props.data).map((item, key) => {
            return (
              <MenuItem key={key} value={item}>
                {props.displayText(item)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
