import React from 'react';
import styled from 'styled-components';
import Header from '../Header';
import { Typography } from '@mui/material';
import { useAppSelector } from '../../stores/hooks';
import { RootState } from '../../stores/types';

const Home = () => {
  const { userData, isMaintenanceModeOn } = useAppSelector(
    (state: RootState) => state.usersReducer,
  );

  return (
    <Container>
      <Header name={'Home'} />
      {isMaintenanceModeOn ? (
        <MessageContainer>
          <Typography variant="h3">Technical Maintenance</Typography>
          <Typography variant="h5">
            The system is currently undergoing maintenance. Please check back
            later.
          </Typography>
        </MessageContainer>
      ) : userData && userData.permissions.length > 0 ? (
        <MessageContainer>
          <Typography variant="h3">Welcome to Mannequin Systems!</Typography>
          <Typography variant="h5">Stay tuned for updates.</Typography>
        </MessageContainer>
      ) : (
        <MessageContainer>
          <Typography variant="h3">You don't have any permissions</Typography>
          <Typography variant="h5">
            Please contact your system administrator.
          </Typography>
        </MessageContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const MessageContainer = styled.section`
  padding: 1%;
  min-height: 400px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
  text-align: center;
  align-content: center;
`;

export default Home;
