import React from 'react';
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { Changes } from './index';
import { SecurityChanges } from '../Security';

interface ConfirmChangesModalProps {
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
  changes: Changes | SecurityChanges;
}

const ConfirmChangesModal = (props: ConfirmChangesModalProps) => {
  const formatValue = (value: any) => {
    if (typeof value === 'boolean') {
      return value ? 'true' : 'false';
    }
    if (typeof value === 'object') {
      return JSON.stringify(value, null, 2);
    }
    return value;
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="confirm-changes-modal-title"
      aria-describedby="confirm-changes-modal-description"
    >
      <Wrapper>
        <Grid
          container
          sx={{
            height: '97%',
            borderBottom: '1px solid black',
          }}
        >
          <Grid item xs={4}>
            <Typography
              id="confirm-changes-modal-title"
              variant="h6"
              component="h2"
            >
              Confirm Changes
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              overflow: 'auto',
              height: '90%',
            }}
          >
            {Object.keys(props.changes).map(
              (type: string, key) =>
                // @ts-ignore
                props.changes[type].length > 0 && (
                  <div key={key}>
                    <Typography variant="subtitle1">{type}:</Typography>
                    {['deleted', 'reset_password'].includes(type) ? (
                      <List
                        sx={{
                          paddingTop: 0,
                        }}
                      >
                        {
                          // @ts-ignore
                          props.changes[type].map((item, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={
                                  type === 'deleted'
                                    ? item.objectSection +
                                      ':' +
                                      ' ' +
                                      item.objectName
                                    : item
                                }
                              />
                            </ListItem>
                          ))
                        }
                      </List>
                    ) : (
                      // @ts-ignore
                      props.changes[type].map((change, key) => (
                        <div key={key}>
                          <Typography
                            variant="subtitle1"
                            sx={{ paddingLeft: '2%' }}
                          >
                            {change.objectName}:
                          </Typography>
                          <TableContainer
                            sx={{ width: '96%', paddingLeft: '3%' }}
                          >
                            <Table size="small" aria-label="params table">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      width: '10%',
                                      backgroundColor: '#c9c9c9',
                                    }}
                                  >
                                    Parameter
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: '45%',
                                      backgroundColor: '#c9c9c9',
                                    }}
                                  >
                                    Old Value
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: '45%',
                                      backgroundColor: '#c9c9c9',
                                    }}
                                  >
                                    New Value
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(change.newValue).map(
                                  (param, index) => (
                                    <TableRow
                                      key={index}
                                      sx={{ verticalAlign: 'sub' }}
                                    >
                                      <TableCell>{param}</TableCell>
                                      <TableCell sx={{ verticalAlign: 'sub' }}>
                                        {change.oldValue
                                          ? formatValue(change.oldValue[param])
                                          : '-'}
                                      </TableCell>
                                      <TableCell sx={{ verticalAlign: 'sub' }}>
                                        {formatValue(change.newValue[param])}
                                      </TableCell>
                                    </TableRow>
                                  ),
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      ))
                    )}
                  </div>
                ),
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={10} />
          <Grid item xs={1}>
            <Button
              color="inherit"
              onClick={props.onApprove}
              sx={{
                width: '100%',
                minWidth: '100px',
              }}
            >
              Approve
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              color="inherit"
              onClick={props.onClose}
              sx={{
                width: '100%',
                minWidth: '100px',
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
    </Modal>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 80%;
  background-color: #fff;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 1%;
`;

export default ConfirmChangesModal;
