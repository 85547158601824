import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, DialogActions, Grid, Typography } from '@mui/material';
import { loadConfiguration } from '../../stores/configuration';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { RootState } from '../../stores/store';
import Header from '../Header';
import LoadingIcon from '../Controls/LoadingIcon';
import { enqueueSnackbar } from 'notistack';
import DialogControl from '../Controls/DialowWindow';
import SelectControl from '../Controls/SelectControl';
import MannequinSystemsApi from '../../api/MannequinSystemsApi';
import { GPUState } from '../../api/types/GetWorkersDataResponseDto';

const Workers = () => {
  const dispatch = useAppDispatch();
  const { data: configuration, isLoading: configurationLoading } =
    useAppSelector((state: RootState) => state.configurationReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [stopWorkerConfirmIsOpen, setStopWorkerConfirmIsOpen] = useState(false);
  const [startWorkerConfirmIsOpen, setStartWorkerConfirmIsOpen] =
    useState(false);
  const [serversData, setServersData] = useState<Map<string, GPUState[]>>(
    new Map(),
  );
  const [selectedServer, setSelectedServer] = useState('');
  const [selectedGpu, setSelectedGpu] = useState<GPUState | null>(null);
  const [selectedSystem, setSelectedSystem] = useState('');
  const [selectedEnv, setSelectedEnv] = useState('');

  const getServersData = async () => {
    setIsLoading(true);
    const tempServersData = new Map<string, GPUState[]>();

    await Promise.all(
      configuration!.worker_servers
        .split(',')
        .map(async (serverAddress: string) => {
          try {
            const serverData = await MannequinSystemsApi.callWorkerApi(
              serverAddress,
              'state',
            );
            if (serverData) {
              tempServersData.set(serverAddress, serverData.data.body.gpus);
            }
          } catch (error) {
            enqueueSnackbar(`Failed to load data for ${serverAddress}!`, {
              variant: 'error',
            });
          }
        }),
    );

    setServersData(tempServersData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (configuration) getServersData().then();
    // eslint-disable-next-line
  }, [configuration]);

  useEffect(() => {
    if (configuration === null) dispatch(loadConfiguration());
    // eslint-disable-next-line
  }, []);

  const startWorker = async () => {
    setIsLoading(true);
    setStartWorkerConfirmIsOpen(false);
    try {
      const response = await MannequinSystemsApi.callWorkerApi(
        selectedServer,
        'start_worker',
        'POST',
        {
          system: selectedSystem,
          env: selectedEnv,
          gpu_number: selectedGpu?.gpu_index,
        },
      );
      if (response) {
        setServersData((prevData) => {
          const newData = new Map(prevData);
          newData.set(selectedServer, response.data.body.gpus);
          return newData;
        });
        enqueueSnackbar(
          `${selectedSystem} (${selectedEnv}) started on ${selectedServer}!`,
          {
            variant: 'success',
          },
        );
      }
    } catch (error) {
      enqueueSnackbar(
        `Failed to start ${selectedSystem} (${selectedEnv}) on ${selectedServer}!`,
        {
          variant: 'error',
        },
      );
    }
    setIsLoading(false);
  };

  const stopWorker = async () => {
    setIsLoading(true);
    setStopWorkerConfirmIsOpen(false);
    setSelectedEnv('');
    setSelectedSystem('');
    try {
      const response = await MannequinSystemsApi.callWorkerApi(
        selectedServer,
        'stop_worker',
        'POST',
        {
          system: selectedGpu?.system_running.system_name,
          env: selectedGpu?.system_running.env,
          gpu_number: selectedGpu?.gpu_index,
        },
      );
      if (response) {
        setServersData((prevData) => {
          const newData = new Map(prevData);
          newData.set(selectedServer, response.data.body.gpus);
          return newData;
        });
        enqueueSnackbar(
          `${selectedGpu?.system_running.system_name} (${selectedGpu?.system_running.env}) stopped on ${selectedServer}!`,
          {
            variant: 'success',
          },
        );
      }
    } catch (error) {
      enqueueSnackbar(
        `Failed to stop ${selectedGpu?.system_running.system_name} (${selectedGpu?.system_running.env}) on ${selectedServer}!`,
        {
          variant: 'error',
        },
      );
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <DialogControl
        isOpen={stopWorkerConfirmIsOpen}
        title={`Stop worker`}
        description={''}
        setIsOpen={setStopWorkerConfirmIsOpen}
        approveAction={stopWorker}
      >
        <Typography sx={{ fontSize: 14 }} variant="subtitle1">
          Are you sure to stop{' '}
          {selectedGpu?.system_running?.system_name +
            ' (' +
            selectedGpu?.system_running?.env +
            ')'}
          ? Please note that if there are tasks running on the workers, this may
          affect the overall results.
        </Typography>
      </DialogControl>
      <DialogControl
        isOpen={startWorkerConfirmIsOpen}
        title={`Start worker`}
        description={'Please select system and name'}
        setIsOpen={setStartWorkerConfirmIsOpen}
        approveAction={startWorker}
        approveButtonDisabled={selectedSystem === '' || selectedEnv === ''}
        onCloseAction={() => {
          setSelectedEnv('');
          setSelectedSystem('');
        }}
      >
        <ModalWrapper>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SelectControl
                id={''}
                description="System"
                value={selectedSystem}
                onChange={(event) => {
                  setSelectedSystem(event.value);
                }}
                items={['mannequin_systems', 'prod_pipeline']}
                canBeEmpty={true}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectControl
                id={''}
                description="Environment"
                value={selectedEnv}
                onChange={(event) => {
                  setSelectedEnv(event.value);
                }}
                items={['prod', 'dev']}
                canBeEmpty={true}
              />
            </Grid>
          </Grid>
        </ModalWrapper>
      </DialogControl>
      <Header name={'Workers'} />
      <WorkersContainer>
        {isLoading || configurationLoading ? (
          <LoadingContainer>
            <LoadingIcon />
          </LoadingContainer>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Button color="inherit" onClick={getServersData}>
                Reload data
              </Button>
            </Grid>
            {configuration?.worker_servers
              .split(',')
              .map((serverAddress: string, key) => {
                return serversData.has(serverAddress) ? (
                  <Grid item xs={12} key={key}>
                    <ServerDetails>
                      <legend> Server {serverAddress.split(':')[0]}</legend>
                      <Grid container spacing={1}>
                        {serversData
                          .get(serverAddress)!
                          .map((gpuStage: GPUState) => {
                            return (
                              <Grid item xs={3} key={gpuStage.gpu_index}>
                                <GpuDetails>
                                  <legend>GPU {gpuStage.gpu_index}</legend>
                                  <Typography
                                    sx={{ fontSize: 13 }}
                                    variant="overline"
                                  >
                                    System:{' '}
                                    {gpuStage.system_running
                                      ? gpuStage.system_running?.system_name +
                                        ' (' +
                                        gpuStage.system_running?.env +
                                        ')'
                                      : 'UNKNOWN'}
                                  </Typography>
                                  <Typography
                                    sx={{ fontSize: 14 }}
                                    variant="subtitle1"
                                  >
                                    Memory used:{' '}
                                    {gpuStage.memory_used +
                                      ' / ' +
                                      gpuStage.memory_total +
                                      ' MiB'}
                                  </Typography>
                                  <DialogActions>
                                    {gpuStage.system_running && (
                                      <Button
                                        variant="outlined"
                                        color="inherit"
                                        sx={{ fontSize: 12 }}
                                        onClick={() => {
                                          setSelectedServer(serverAddress);
                                          setSelectedGpu(gpuStage);
                                          setStopWorkerConfirmIsOpen(true);
                                        }}
                                      >
                                        Stop worker
                                      </Button>
                                    )}
                                    <Button
                                      variant="outlined"
                                      color="inherit"
                                      sx={{ fontSize: 12 }}
                                      onClick={() => {
                                        setSelectedServer(serverAddress);
                                        setSelectedGpu(gpuStage);
                                        setStartWorkerConfirmIsOpen(true);
                                      }}
                                    >
                                      Start worker
                                    </Button>
                                  </DialogActions>
                                </GpuDetails>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </ServerDetails>
                  </Grid>
                ) : (
                  <></>
                );
              })}
          </Grid>
        )}
      </WorkersContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const ModalWrapper = styled.div`
  width: 550px;
  min-height: 100px;
`;

const WorkersContainer = styled.div`
  padding: 1%;
  min-height: 500px;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const LoadingContainer = styled.div`
  height: 500px;
`;

const ServerDetails = styled.fieldset`
  border-radius: 4px;
  border-color: #afafaf;
  box-shadow: 5px 5px 5px rgba(58, 58, 58, 0.3);
`;

const GpuDetails = styled.fieldset`
  border-radius: 4px;
  border-color: #c8c8c8;
  box-shadow: 5px 5px 5px rgba(58, 58, 58, 0.3);
  display: grid;
`;

export default Workers;
