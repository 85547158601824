import { FormControl, FormHelperText, Grid, Typography } from '@mui/material';
import SelectControl from '../Controls/SelectControl';
import React from 'react';
import { HandleType } from '../../configs';
import ButtonControl from '../Controls/ButtonControl';

interface ControlPanelProps {
  value: string;
  items: string[];
  onChange: { (field: HandleType): void };
  onCreate: () => void;
  onDelete: () => void;
  name: string;
  description: string;
}

const ControlPanel = ({
  value,
  items,
  onChange,
  onCreate,
  onDelete,
  name,
  description,
}: ControlPanelProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 'x-large', textAlign: 'center' }}>
          {description}
        </Typography>
      </Grid>
      <Grid item xs={1.75}>
        <FormHelperText
          sx={{
            paddingTop: '30px',
          }}
        >
          {name}
        </FormHelperText>
      </Grid>
      <Grid
        item
        xs={4}
        sx={{
          paddingBottom: '3px',
        }}
      >
        <SelectControl
          id={''}
          value={value}
          items={items}
          description={' '}
          canBeEmpty={false}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={0.25} />
      <Grid item xs={1}>
        <FormControl>
          <FormHelperText> </FormHelperText>
          <ButtonControl description="Create" onClick={onCreate} />
        </FormControl>
      </Grid>
      <Grid item xs={0.25} />
      <Grid item xs={1}>
        <FormControl>
          <FormHelperText> </FormHelperText>
          <ButtonControl description="Delete" onClick={onDelete} />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ControlPanel;
