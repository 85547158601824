import Header from '../Header';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  ListItemText,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import SelectControl from '../Controls/SelectControl';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { RootState } from '../../stores/types';
import TextFieldControl from '../Controls/TextFieldControl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { enqueueSnackbar } from 'notistack';
import DialogControl from '../Controls/DialowWindow';
import {
  loadConfiguration,
  updateConfiguration,
} from '../../stores/configuration';
import ConfirmChangesModal from './confirmChangesModal';
import ControlPanel from './controlPanel';
import JsonControl from '../Controls/JsonControl';
import ButtonControl from '../Controls/ButtonControl';
import LoadingIcon from '../Controls/LoadingIcon';
import Modal from '@mui/material/Modal';
import { TabLabel } from '../../api/types/GetSecurityDataResponseDto';
import { Change } from '../../configs';

export interface Changes {
  created: Change[];
  deleted: Change[];
  changed: Change[];
  defaults: Change[];
}

const tabLabels: Record<TabLabel, number> = {
  Default: 0,
  Ml_Pipeline: 1,
  Preset: 2,
  Service: 3,
};

const MannequinSystemsSettings = () => {
  const dispatch = useAppDispatch();
  const { data: configuration, isLoading: configurationLoading } =
    useAppSelector((state: RootState) => state.configurationReducer);
  const [mlPipelines, setMlPipelines] = useState(
    configuration?.ml_pipelines || {},
  );
  const [mlPresets, setMlPresets] = useState(configuration?.ml_presets || {});
  const [services, setServices] = useState(configuration?.services || {});
  const [mlData, setMlData] = useState(configuration?.ml_data || {});
  const [selectedDefaultPipelineVersion, setSelectedDefaultPipelineVersion] =
    useState(configuration?.default_ml_pipeline || '');
  const [selectedPipelineVersion, setSelectedPipelineVersion] = useState(
    Object.keys(mlPipelines || {})[0],
  );
  const [selectedPreset, setSelectedPreset] = useState(
    Object.keys(mlPresets || {})[0],
  );
  const [selectedService, setSelectedService] = useState(
    Object.keys(services || {})[0],
  );
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
  const [currentTab, setCurrentTab] = useState<TabLabel>('Default');
  const [workerServers, setWorkerServers] = useState('');
  const [newObjectName, setNewObjectName] = useState('');
  const [dialogOpened, setDialogOpened] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [creatingObjectType, setCreatingObjectType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const changeTypes = {
    created: [],
    deleted: [],
    changed: [],
    defaults: [],
  };
  const [changes, setChanges] = useState<Changes>(changeTypes);

  useEffect(() => {
    if (configuration === null) {
      setIsUpdating(true);
      dispatch(loadConfiguration());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (configuration) {
      resetModal();
      setIsUpdating(false);
    }
    // eslint-disable-next-line
  }, [configuration]);

  const detectChanges = () => {
    const newChanges: Changes = changeTypes;

    let types: any = {
      services: {
        oldValues: configuration!.services,
        newValues: services,
      },
      ml_pipelines: {
        oldValues: configuration!.ml_pipelines,
        newValues: mlPipelines,
      },
      ml_presets: {
        oldValues: configuration!.ml_presets,
        newValues: mlPresets,
      },
    };

    if (selectedDefaultPipelineVersion !== configuration!.default_ml_pipeline) {
      newChanges.defaults.push({
        objectSection: 'Defaults',
        objectName: 'Defaults',
        oldValue: { default_ml_pipeline: configuration!.default_ml_pipeline },
        newValue: { default_ml_pipeline: selectedDefaultPipelineVersion },
      });
    }

    if (mlData !== configuration!.ml_data) {
      newChanges.defaults.push({
        objectSection: 'Defaults',
        objectName: 'Defaults',
        oldValue: { ml_data: configuration!.ml_data },
        newValue: { ml_data: mlData },
      });
    }

    if (isMaintenanceMode !== configuration!.is_maintenance_mode) {
      newChanges.defaults.push({
        objectSection: 'Defaults',
        objectName: 'Defaults',
        oldValue: { is_maintenance_mode: configuration!.is_maintenance_mode },
        newValue: { is_maintenance_mode: isMaintenanceMode },
      });
    }

    if (workerServers !== configuration!.worker_servers) {
      newChanges.defaults.push({
        objectSection: 'Defaults',
        objectName: 'Defaults',
        oldValue: { worker_servers: configuration!.worker_servers },
        newValue: { worker_servers: workerServers },
      });
    }

    Object.keys(types).forEach((type) => {
      let typeData = types[type];
      let oldKeys = Object.keys(typeData.oldValues);
      let newKeys = Object.keys(typeData.newValues);
      newKeys.forEach((objectKey) => {
        if (!oldKeys.includes(objectKey))
          newChanges.created.push({
            objectSection: type,
            objectName: objectKey,
            newValue: typeData.newValues[objectKey],
          });
        else {
          let newObject: any = typeData.newValues[objectKey];
          let oldObject: any = typeData.oldValues[objectKey];
          if (JSON.stringify(newObject) !== JSON.stringify(oldObject)) {
            const originals: Partial<Record<string, any>> = {};
            const changes: Partial<Record<string, any>> = {};
            for (const key in oldObject) {
              if (
                JSON.stringify(oldObject[key]) !==
                JSON.stringify(newObject[key])
              ) {
                changes[key] = newObject[key];
                originals[key] = oldObject[key];
              }
            }
            newChanges.changed.push({
              objectSection: type,
              objectName: objectKey,
              oldValue: originals,
              newValue: changes,
            });
          }
        }
      });

      oldKeys.forEach((objectKey) => {
        if (!newKeys.includes(objectKey))
          newChanges.deleted.push({
            objectSection: type,
            objectName: objectKey,
          });
      });
    });
    return newChanges;
  };

  const handleSaveButtonClick = () => {
    const changesDetected = detectChanges();
    if (
      changesDetected.created.length === 0 &&
      changesDetected.deleted.length === 0 &&
      changesDetected.changed.length === 0 &&
      changesDetected.defaults.length === 0
    ) {
      enqueueSnackbar('No changes to save', { variant: 'info' });
    } else {
      setChanges(changesDetected);
      setIsModalOpen(true);
    }
  };
  const handleApprove = async () => {
    setIsModalOpen(false);
    setIsUpdating(true);
    await dispatch(updateConfiguration(changes))
      .then((action) => {
        if (action.payload.statusCode === 200)
          enqueueSnackbar('Configuration saved successfully', {
            variant: 'success',
          });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const resetModal = () => {
    setMlPipelines(configuration!.ml_pipelines);
    setMlPresets(configuration!.ml_presets);
    setServices(configuration!.services);
    setMlData(configuration!.ml_data);
    setSelectedDefaultPipelineVersion(configuration!.default_ml_pipeline);
    setSelectedPipelineVersion(Object.keys(configuration!.ml_pipelines)[0]);
    setSelectedPreset(Object.keys(configuration!.ml_presets)[0]);
    setSelectedService(Object.keys(configuration!.services)[0]);
    setChanges(changeTypes);
    setIsMaintenanceMode(configuration!.is_maintenance_mode);
    setWorkerServers(configuration!.worker_servers);
  };

  const checkNewObjectName = (
    objectName: string,
    oldObjects: any,
    type: string,
  ) => {
    if (objectName.trim() === '') {
      setNewObjectName('');
      enqueueSnackbar(`${type} name cannot be empty`, { variant: 'warning' });
      return false;
    }
    if (oldObjects[objectName]) {
      enqueueSnackbar(`${type} name already exists`, { variant: 'warning' });
      return false;
    }
    return true;
  };

  const handleStartCreatingNewObject = (type: string) => {
    setNewObjectName('');
    setDialogMessage(`Please enter a name for the new ${type}`);
    setDialogOpened(true);
    setCreatingObjectType(type);
  };

  const handleCreateItem = (
    name: string,
    items: any,
    setItems: (callback: (prev: any) => any) => void,
    setSelectedItem: (item: string) => void,
  ) => {
    setItems((prev) => {
      return { ...prev, [name]: items };
    });
    setSelectedItem(name);
  };

  const handleDeleteItem = (
    name: string,
    items: any,
    setItems: (callback: (prev: any) => any) => void,
    checkUsage: (name: string) => void,
    successMessage: string,
    setSelectedItem: (item: string) => void,
  ) => {
    checkUsage(name);
    setItems((prev) => {
      const newItems = { ...prev };
      delete newItems[name];
      return newItems;
    });
    const newSelectedItem = Object.keys(items).find((i) => i !== name) || '';
    setSelectedItem(newSelectedItem);
    enqueueSnackbar(`${successMessage} ${name} deleted`, {
      variant: 'info',
    });
  };

  const removePipelineDependencies = (version: string) => {
    const usedInPresets = Object.keys(mlPresets).filter(
      (key) => mlPresets[key].pipeline_version === version,
    );

    usedInPresets.forEach((presetName) => {
      const newPreset = Object.keys(mlPresets).find(
        (preset) => preset !== presetName,
      );
      handleDeleteItem(
        presetName,
        mlPresets[presetName],
        setMlPresets,
        () => [],
        'Preset',
        () => {
          setSelectedPreset(newPreset!);
        },
      );
    });
    if (selectedDefaultPipelineVersion === version) {
      const newDefaultPipelineVersion = Object.keys(mlPipelines).find(
        (pipeline) => pipeline !== version,
      );
      if (newDefaultPipelineVersion) {
        setSelectedDefaultPipelineVersion(newDefaultPipelineVersion);
      } else {
        setSelectedDefaultPipelineVersion('');
      }
    }
  };

  const removeServiceDependencies = (name: string) => {
    const usedInServices = Object.keys(services).filter((key) =>
      services[key].requires.includes(name),
    );
    usedInServices.forEach((service) => {
      const updateRequires = services[service].requires.filter(
        (required) => required !== name,
      );
      updateData(setServices, service, {
        requires: updateRequires,
      });
    });
  };

  const handleManualInputChange = (index: number, value: string) => {
    setServices((prev) => {
      const newService = { ...prev };
      const newVersion = { ...newService[selectedService] };
      const newInputs = [...newVersion.manual_inputs];
      if (index === -1) newInputs.push('');
      else newInputs[index] = value;
      newVersion.manual_inputs = newInputs;
      newService[selectedService] = newVersion;
      return newService;
    });
  };

  const updateData = (setter: any, objectKey: string, params: any) => {
    setter((prev: any) => {
      const newObjects = { ...prev };
      const newObject = {
        ...newObjects[objectKey],
      };
      Object.keys(params).forEach((paramKey) => {
        newObject[paramKey] = params[paramKey];
      });
      newObjects[objectKey] = newObject;
      return newObjects;
    });
  };

  const handleApproveAction = () => {
    if (
      creatingObjectType === 'pipeline' &&
      checkNewObjectName(newObjectName, mlPipelines, `Pipeline`)
    ) {
      handleCreateItem(
        newObjectName,
        {
          name: newObjectName,
          components: [],
          data: {},
        },
        setMlPipelines,
        setSelectedPipelineVersion,
      );
    } else if (
      creatingObjectType === 'preset' &&
      checkNewObjectName(newObjectName, mlPresets, `Preset`)
    ) {
      handleCreateItem(
        newObjectName,
        {
          name: newObjectName,
          data: {},
          pipeline_version: selectedDefaultPipelineVersion,
        },
        setMlPresets,
        setSelectedPreset,
      );
    } else if (
      creatingObjectType === 'service' &&
      checkNewObjectName(newObjectName, services, `Service`)
    ) {
      handleCreateItem(
        newObjectName,
        {
          name: newObjectName,
          requires: [],
          manual_inputs: [],
          is_manual: false,
        },
        setServices,
        setSelectedService,
      );
    }
    setNewObjectName('');
    setDialogOpened(false);
    setCreatingObjectType('');
  };

  return (
    <Container>
      <Modal open={isUpdating || configurationLoading}>
        <LoadingIcon />
      </Modal>
      {isModalOpen && (
        <ConfirmChangesModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onApprove={handleApprove}
          changes={changes}
        />
      )}
      <DialogControl
        isOpen={dialogOpened}
        title={``}
        description={dialogMessage}
        setIsOpen={setDialogOpened}
        approveAction={handleApproveAction}
      >
        <TextFieldControl
          value={newObjectName}
          onChange={(field) => {
            setNewObjectName(field.value);
          }}
          id={'newItemName'}
          description={''}
        />
      </DialogControl>
      <Header name={'Settings'} />
      <SystemsSettingsContainer>
        <TabsContainer>
          <SettingTabs
            orientation="vertical"
            variant="scrollable"
            value={tabLabels[currentTab]}
            onChange={(_, newValue: number) => {
              const newTab = Object.keys(tabLabels).find(
                (key) => tabLabels[key as TabLabel] === newValue,
              ) as TabLabel | undefined;

              if (newTab) {
                setCurrentTab(newTab);
              }
            }}
            aria-label="Vertical tabs example"
            textColor="inherit"
          >
            <Tab label="Default settings" />
            <Tab label="Ml Pipeline settings" />
            <Tab label="Preset settings" />
            <Tab label="Service settings" />
          </SettingTabs>
          <div>
            <TabsChildrenContainer
              style={{ display: currentTab === 'Default' ? '' : 'none' }}
            >
              <Wrapper>
                <Grid container spacing={1} display="flex">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontSize: 'x-large', textAlign: 'center' }}
                      >
                        Default settings
                      </Typography>
                    </Grid>
                    {userData!.permissions.includes('maintenance_mode') && (
                      <>
                        <Grid item xs={1.75}>
                          <FormHelperText
                            sx={{
                              paddingTop: '7px',
                            }}
                          >
                            Maintenance mode
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={10.25}>
                          <Switch
                            id={'isManualService'}
                            onChange={(event) => {
                              setIsMaintenanceMode(event.target.checked);
                            }}
                            checked={isMaintenanceMode}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={1.75}>
                      <FormHelperText
                        sx={{
                          paddingTop: '7px',
                        }}
                      >
                        Worker servers
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={10.25}>
                      <TextFieldControl
                        value={workerServers}
                        onChange={(field) => {
                          setWorkerServers(field.value);
                        }}
                        id={``}
                        description={``}
                      />
                    </Grid>
                    <Grid item xs={1.75}>
                      <FormHelperText sx={{ paddingTop: '30px' }}>
                        Default pipeline version
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={4} sx={{ paddingBottom: '3px' }}>
                      <SelectControl
                        id={'defaultPipelineVersion'}
                        value={selectedDefaultPipelineVersion}
                        items={Object.keys(mlPipelines)}
                        description={' '}
                        disabled={false}
                        canBeEmpty={false}
                        onChange={(field) => {
                          setSelectedDefaultPipelineVersion(field.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6.25} />
                    <JsonControl
                      description="Ml Data"
                      data={mlData}
                      onUpdate={(newData) => {
                        setMlData(newData);
                      }}
                    />
                  </Grid>
                </Grid>
              </Wrapper>
            </TabsChildrenContainer>
            <TabsChildrenContainer
              style={{ display: currentTab === 'Ml_Pipeline' ? '' : 'none' }}
            >
              <Wrapper>
                <Grid container spacing={1} display="flex">
                  {Object.keys(mlPipelines).length === 0 ? (
                    <Grid item xs={1}>
                      <FormControl>
                        <FormHelperText> </FormHelperText>
                        <ButtonControl
                          description="Create"
                          onClick={() => {
                            handleStartCreatingNewObject('pipeline');
                          }}
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <>
                      <Grid container>
                        <ControlPanel
                          description="Pipeline settings"
                          value={selectedPipelineVersion}
                          items={Object.keys(mlPipelines)}
                          onChange={(field) => {
                            setSelectedPipelineVersion(field.value);
                          }}
                          onCreate={() => {
                            handleStartCreatingNewObject('pipeline');
                          }}
                          onDelete={() => {
                            handleDeleteItem(
                              selectedPipelineVersion,
                              mlPipelines,
                              setMlPipelines,
                              removePipelineDependencies,
                              'Pipeline version',
                              setSelectedPipelineVersion,
                            );
                          }}
                          name="Pipeline version"
                        />
                        <JsonControl
                          description="Components"
                          data={mlPipelines[selectedPipelineVersion].components}
                          onUpdate={(newData) => {
                            updateData(
                              setMlPipelines,
                              selectedPipelineVersion,
                              {
                                components: newData,
                              },
                            );
                          }}
                        />
                        <JsonControl
                          description="Data"
                          data={mlPipelines[selectedPipelineVersion].data}
                          onUpdate={(newData) => {
                            updateData(
                              setMlPipelines,
                              selectedPipelineVersion,
                              {
                                data: newData,
                              },
                            );
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Wrapper>
            </TabsChildrenContainer>
            <TabsChildrenContainer
              style={{ display: currentTab === 'Preset' ? '' : 'none' }}
            >
              <Wrapper>
                <Grid container spacing={1} display="flex">
                  {Object.keys(mlPresets).length === 0 ? (
                    <Grid item xs={1}>
                      <FormControl>
                        <FormHelperText> </FormHelperText>
                        <ButtonControl
                          description="Create"
                          onClick={() => {
                            handleStartCreatingNewObject('preset');
                          }}
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <>
                      <Grid container>
                        <ControlPanel
                          description="Preset settings"
                          value={selectedPreset}
                          items={Object.keys(mlPresets)}
                          onChange={(field) => {
                            setSelectedPreset(field.value);
                          }}
                          onCreate={() => {
                            handleStartCreatingNewObject('preset');
                          }}
                          onDelete={() => {
                            handleDeleteItem(
                              selectedPreset,
                              mlPresets,
                              setMlPresets,
                              () => [],
                              'Preset',
                              setSelectedPreset,
                            );
                          }}
                          name="Preset"
                        />
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={1.75}
                          sx={{
                            paddingTop: '15px',
                          }}
                        >
                          <FormHelperText>Pipeline version</FormHelperText>
                        </Grid>
                        <Grid
                          item
                          xs={6.5}
                          sx={{
                            paddingBottom: '3px',
                            paddingTop: '5px',
                          }}
                        >
                          <SelectControl
                            id={'presetPipelineVersion'}
                            value={mlPresets[selectedPreset].pipeline_version}
                            items={
                              configuration ? Object.keys(mlPipelines) : []
                            }
                            description={''}
                            disabled={false}
                            canBeEmpty={false}
                            onChange={(field) => {
                              updateData(setMlPresets, selectedPreset, {
                                pipeline_version: field.value,
                                data: mlPipelines[field.value].data,
                              });
                            }}
                          />
                        </Grid>
                        <JsonControl
                          description="Parameters"
                          data={mlPresets[selectedPreset].data}
                          onUpdate={(newData) => {
                            updateData(setMlPresets, selectedPreset, {
                              data: newData,
                            });
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Wrapper>
            </TabsChildrenContainer>
            <TabsChildrenContainer
              style={{ display: currentTab === 'Service' ? '' : 'none' }}
            >
              <Wrapper>
                <Grid container spacing={1} display="flex">
                  {Object.keys(services).length === 0 ? (
                    <Grid item xs={1}>
                      <FormControl>
                        <FormHelperText> </FormHelperText>
                        <ButtonControl
                          description="Create"
                          onClick={() => {
                            handleStartCreatingNewObject('service');
                          }}
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <>
                      <Grid container>
                        <ControlPanel
                          description="Services settings"
                          name="Service"
                          value={selectedService}
                          items={Object.keys(services)}
                          onChange={(field) => {
                            setSelectedService(field.value);
                          }}
                          onCreate={() => {
                            handleStartCreatingNewObject('service');
                          }}
                          onDelete={() => {
                            handleDeleteItem(
                              selectedService,
                              services,
                              setServices,
                              removeServiceDependencies,
                              'Service',
                              setSelectedService,
                            );
                          }}
                        />
                        <Grid item xs={1.75}>
                          <FormHelperText
                            sx={{
                              paddingTop: '7px',
                            }}
                          >
                            Is manual
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={10.25}>
                          <Switch
                            id={'isManualService'}
                            onChange={(event) => {
                              updateData(setServices, selectedService, {
                                is_manual: event.target.checked,
                              });
                            }}
                            checked={services[selectedService].is_manual}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={1.75}
                          sx={{
                            paddingTop: '15px',
                          }}
                        >
                          <FormHelperText>Required services</FormHelperText>
                        </Grid>
                        <Grid
                          item
                          xs={6.25}
                          sx={{
                            paddingTop: '10px',
                          }}
                        >
                          {Object.keys(services)
                            .filter((service) => service !== selectedService)
                            .map((service) => (
                              <Grid item xs={12} key={service}>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{ display: 'flex' }}
                                  key={service}
                                >
                                  <ListItemText primary={service} />
                                  <Switch
                                    id={'requires'}
                                    onChange={(event) => {
                                      const requires = event.target.checked
                                        ? [
                                            ...services[selectedService]
                                              .requires,
                                            service,
                                          ]
                                        : services[
                                            selectedService
                                          ].requires.filter(
                                            (reqService) =>
                                              reqService !== service,
                                          );
                                      updateData(setServices, selectedService, {
                                        requires: requires,
                                      });
                                    }}
                                    checked={
                                      Array.isArray(
                                        services[selectedService].requires,
                                      ) &&
                                      services[
                                        selectedService
                                      ].requires.includes(service)
                                    }
                                  />
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid
                          item
                          xs={1.75}
                          sx={{
                            paddingTop: '10px',
                          }}
                        >
                          <FormHelperText>Manual inputs</FormHelperText>
                        </Grid>
                        <Grid
                          item
                          xs={10.25}
                          sx={{
                            paddingBottom: '3px',
                            paddingTop: '10px',
                          }}
                        >
                          <IconButton
                            disabled={false}
                            onClick={() => {
                              handleManualInputChange(-1, '');
                            }}
                          >
                            <AddCircleIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                        {services[selectedService] &&
                        services[selectedService].manual_inputs &&
                        services[selectedService].manual_inputs.length > 0 ? (
                          services[selectedService].manual_inputs.map(
                            (name, index) => (
                              <Grid
                                container
                                key={index}
                                sx={{
                                  paddingBottom: '5px',
                                }}
                              >
                                <Grid item xs={1.75} />
                                <Grid item xs={9.5}>
                                  <TextFieldControl
                                    key={index}
                                    value={name}
                                    onChange={(field) => {
                                      handleManualInputChange(
                                        index,
                                        field.value,
                                      );
                                    }}
                                    id={`${name}_input`}
                                    description={``}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={0.75}
                                  style={{ textAlign: 'center' }}
                                >
                                  <IconButton
                                    disabled={false}
                                    onClick={() => {
                                      setServices((prev) => {
                                        const newService = { ...prev };
                                        const newVersion = {
                                          ...newService[selectedService],
                                        };
                                        const newInputs = [
                                          ...newVersion.manual_inputs,
                                        ];
                                        newInputs.splice(index, 1);
                                        newVersion.manual_inputs = newInputs;
                                        newService[selectedService] =
                                          newVersion;
                                        return newService;
                                      });
                                    }}
                                  >
                                    <DeleteIcon fontSize="inherit" />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            ),
                          )
                        ) : (
                          <>
                            <Grid item xs={1.75} />
                            <Grid
                              item
                              xs={10.25}
                              sx={{
                                paddingTop: '3px',
                              }}
                            >
                              <Typography>No manual inputs found.</Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Wrapper>
            </TabsChildrenContainer>
            <div>
              <Grid container spacing={1}>
                <Grid item xs={5} />
                <Grid item xs={1}>
                  <ButtonControl
                    onClick={handleSaveButtonClick}
                    description="Save"
                    size="large"
                  />
                </Grid>
                <Grid item xs={0.25} />
                <Grid item xs={1}>
                  <ButtonControl
                    description="Reset"
                    onClick={resetModal}
                    size="large"
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </TabsContainer>
      </SystemsSettingsContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const SystemsSettingsContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const SettingTabs = styled(Tabs)`
  padding-right: 2px;
  border-right: 1px solid rgba(71, 71, 71, 0.46);
  width: 20%;
`;

const Wrapper = styled.div`
  min-height: 500px;
  margin-bottom: 10px;
`;

const TabsContainer = styled.div`
  display: flex;
  min-height: 500px;
  max-height: max-content;
  width: 100%;
`;

const TabsChildrenContainer = styled.div`
  width: 1100px;
  margin-left: 1%;
`;

export default MannequinSystemsSettings;
