import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import { UploadFile } from '../../Controls/SelectFiles';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import { deleteTasks, loadTask } from '../../../stores/tasks';
import DialogControl from '../../Controls/DialowWindow';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MannequinSystemsApi from '../../../api/MannequinSystemsApi';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import PhotoshootsTable from '../../Tables/PhotoshootsTable';
import { loadEntities } from '../../../stores/entities';
import TextFieldControl from '../../Controls/TextFieldControl';
import CachedIcon from '@mui/icons-material/Cached';
import CustomSelect from '../../Controls/CustomSelect';

interface ArchiveToUpload {
  name: string;
  file: File;
  status: 'idle' | 'uploading' | 'completed' | 'error';
  progress: number;
  errorMessage?: string;
}

const Photoshoots = () => {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector((state: RootState) => state.tasksReducer);
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const entitiesStore = useAppSelector(
    (state: RootState) => state.entitiesReducer,
  );
  const studios = entitiesStore.studio;
  const photoshoots = jobs.photoshoot;

  const [archivesToUpload, setArchivesToUpload] = useState<
    Map<string, ArchiveToUpload>
  >(new Map());
  const [dialogOpened, setDialogOpened] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [allCompleted, setAllCompleted] = useState(false);

  const [selectedStudio, setSelectedStudio] = useState<any>('');

  const loadJobs = (extend?: boolean) => {
    dispatch(
      loadTask({
        lastKey: extend ? photoshoots?.lastKey : null,
        type: 'photoshoot',
      }),
    );
  };

  useEffect(() => {
    if (photoshoots === null) loadJobs();
    if (studios === null) dispatch(loadEntities('studio'));
    // eslint-disable-next-line
  }, []);

  const updateArchives = (fileName: string, updates: any) => {
    setArchivesToUpload((prev) => {
      const newMap = new Map(prev);
      newMap.set(fileName, {
        ...prev.get(fileName),
        ...updates,
      });
      return newMap;
    });
  };

  const processArchive = async () => {
    if (archivesToUpload && archivesToUpload.size > 0) {
      if (selectedStudio === '') {
        enqueueSnackbar(`Studio should be selected.`, {
          variant: 'warning',
        });
        return;
      }
      setIsProcessing(true);
      setAllCompleted(false);
      const uploadPromises = Array.from(archivesToUpload.values()).map(
        (archiveToUpload) => {
          let fileName = archiveToUpload.name.replaceAll(' ', '_');
          updateArchives(archiveToUpload.file.name, {
            status: 'uploading',
            progress: 0,
          });

          return getUploadUrl(fileName)
            .then((uploadUrlData) => {
              return axios
                .put(uploadUrlData.presignedUrl, archiveToUpload.file, {
                  onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                      // @ts-ignore
                      (progressEvent.loaded * 99) / progressEvent.total,
                    );
                    updateArchives(archiveToUpload.file.name, {
                      progress: percentCompleted,
                    });
                  },
                })
                .then(() => {
                  updateArchives(archiveToUpload.file.name, { progress: 99 });
                  return MannequinSystemsApi.schedulePhotoshoot(
                    userData!.userName,
                    uploadUrlData.s3_path,
                    fileName,
                    selectedStudio.id,
                  );
                })
                .then((response) => {
                  if (response.status !== 200) {
                    throw new Error(response.statusText);
                  } else if (response.data.errorMessage) {
                    throw new Error(response.data.errorMessage);
                  } else {
                    updateArchives(archiveToUpload.file.name, {
                      progress: 100,
                      status: 'completed',
                    });
                    enqueueSnackbar(`${fileName} photoshoot is scheduled.`, {
                      variant: 'success',
                    });
                  }
                })
                .catch((error) => {
                  updateArchives(archiveToUpload.file.name, {
                    status: 'error',
                    errorMessage: error.message,
                  });
                  enqueueSnackbar(
                    `Failed to schedule photoshoot. Error: ${error.message}`,
                    { variant: 'error' },
                  );
                });
            })
            .catch((error) => {
              updateArchives(archiveToUpload.file.name, {
                status: 'error',
                errorMessage: error.message,
              });
              enqueueSnackbar(
                `Failed to upload file. Error: ${error.message}`,
                { variant: 'error' },
              );
            });
        },
      );
      await Promise.all(uploadPromises);
      setIsProcessing(false);
      setAllCompleted(true);
    }
  };

  const removeTasks = async (taskIds: string[]) => {
    await dispatch(deleteTasks({ taskIds: taskIds, type: 'photoshoot' }));
  };

  const getUploadUrl = async (fileName: string) => {
    const response = await MannequinSystemsApi.getUploadUrl(
      fileName,
      'photoshoots',
    );
    if (response.status === 200 && response.data) {
      return response.data;
    }
  };

  const addFilesToUpload = (files: File[]) => {
    setArchivesToUpload((prevFiles) => {
      const fileMap = new Map(prevFiles);
      files.forEach((file) => {
        fileMap.set(file.name, {
          name: file.name
            .replaceAll(' ', '_')
            .split('.')
            .slice(0, -1)
            .join('.'),
          file: file,
          status: 'idle',
          progress: 0,
        });
      });
      return fileMap;
    });
  };

  return (
    <Container>
      <DialogControl
        isOpen={dialogOpened}
        title={`Upload new photoshoot`}
        description={''}
        setIsOpen={setDialogOpened}
        approveAction={processArchive}
        actionButtonsHide={isProcessing || allCompleted}
        approveButtonDisabled={studios?.isLoading}
        isClosable={!isProcessing || allCompleted}
        onCloseAction={() => {
          if (allCompleted) {
            setDialogOpened(false);
            loadJobs();
            setAllCompleted(false);
            setArchivesToUpload(new Map());
          }
          setSelectedStudio('');
        }}
      >
        {isProcessing || allCompleted ? (
          <>
            <ProgressContainer>
              <Grid item xs={12}>
                {Array.from(archivesToUpload.values()).map((file, key) => (
                  <ArchiveDetails key={key}>
                    <ProcessStatus>
                      <Typography
                        style={{
                          textAlign: 'start',
                          color:
                            file.status === 'completed'
                              ? 'green'
                              : file.status === 'error'
                              ? 'grey'
                              : 'inherit',
                        }}
                      >
                        {file.status === 'error'
                          ? `${file.file.name} `
                          : file.status === 'completed'
                          ? `${file.file.name}`
                          : file.file.name}
                      </Typography>
                      <Tooltip
                        key={file.file.name}
                        title={
                          file.status === 'error' ? file.errorMessage || '' : ''
                        }
                        placement="right-end"
                      >
                        <Typography
                          style={{
                            justifyContent: 'flex-end',
                            display: 'flex',
                            flex: 1,
                            cursor:
                              file.status === 'error' ? 'help' : 'inherit',
                            textDecorationLine:
                              file.status === 'error' ? 'underline' : 'none',
                            textDecorationStyle:
                              file.status === 'error' ? 'dotted' : 'solid',
                            textUnderlineOffset:
                              file.status === 'error' ? '4px' : '0px',
                          }}
                        >
                          {file.status === 'error'
                            ? `Failed to schedule photoshoot.`
                            : file.status === 'completed'
                            ? `Completed`
                            : `${file.progress} %`}
                        </Typography>
                      </Tooltip>
                    </ProcessStatus>
                    <LinearProgress
                      variant="determinate"
                      value={file.status === 'error' ? 0 : file.progress || 0}
                      sx={{
                        width: '100%',
                        backgroundColor:
                          file.status === 'error' ? 'red' : 'inherit',
                        '& .MuiLinearProgress-bar': {
                          backgroundColor:
                            file.status === 'completed'
                              ? 'green'
                              : file.status === 'error'
                              ? 'red'
                              : 'blue',
                        },
                      }}
                    />
                  </ArchiveDetails>
                ))}
              </Grid>
              {allCompleted && (
                <ButtonContainer item xs={12}>
                  <Button
                    color="inherit"
                    onClick={() => {
                      setDialogOpened(true);
                      setAllCompleted(false);
                      setArchivesToUpload(new Map());
                      setSelectedStudio('');
                    }}
                  >
                    Schedule more
                  </Button>
                  <Button
                    color="inherit"
                    onClick={() => {
                      setDialogOpened(false);
                      loadJobs();
                      setAllCompleted(false);
                      setArchivesToUpload(new Map());
                      setSelectedStudio('');
                    }}
                  >
                    Close
                  </Button>
                </ButtonContainer>
              )}
            </ProgressContainer>
          </>
        ) : (
          <ArchiveWrapper>
            <FileSelectWrapper>
              <UploadFile
                setFilesToUpload={addFilesToUpload}
                accept={{
                  'application/zip': ['.zip'],
                }}
                multiple={true}
              />
            </FileSelectWrapper>
            {archivesToUpload.size > 0 && (
              <ZipContainer>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Typography
                      fontWeight={700}
                      style={{
                        paddingLeft: '2%',
                        paddingTop: '2%',
                      }}
                    >
                      Studio
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomSelect
                      data={studios!.entities}
                      value={selectedStudio}
                      onChange={(event) => {
                        setSelectedStudio(event.target.value);
                      }}
                      disabled={studios!.isLoading}
                      description=""
                      renderValue={(value) =>
                        value && value.name ? `${value.name}` : 'None'
                      }
                      displayText={(item) => `${item.name}`}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    {studios?.isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <IconButton
                        onClick={() => {
                          dispatch(loadEntities('studio'));
                        }}
                      >
                        <CachedIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
                <Divider
                  sx={{
                    margin: '6px',
                  }}
                />
                {Array.from(archivesToUpload.values()).map(
                  (archiveToUpload, key) => (
                    <Grid container spacing={1} key={key}>
                      <Grid item xs={5}>
                        <ArchiveDetails>
                          <Typography fontWeight={700}>
                            {archiveToUpload.file.name}
                          </Typography>
                          <Typography fontWeight={300}>
                            {(archiveToUpload.file.size / 1048576).toFixed(2)}{' '}
                            MB
                          </Typography>
                        </ArchiveDetails>
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldControl
                          value={archiveToUpload.name}
                          onChange={(field) => {
                            updateArchives(archiveToUpload.file.name, {
                              name: field.value,
                            });
                          }}
                          description=""
                          id=""
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            setArchivesToUpload((prevValues) => {
                              const newArchivesToUpload = new Map(prevValues);
                              newArchivesToUpload.delete(
                                archiveToUpload.file.name,
                              );
                              return newArchivesToUpload;
                            });
                          }}
                        >
                          <DeleteIcon fontSize={'medium'} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ),
                )}
              </ZipContainer>
            )}
          </ArchiveWrapper>
        )}
      </DialogControl>
      <Header name={'Photoshoots'} />
      <PhotoshootsContainer>
        <PhotoshootsTable
          key={'photoshoot'}
          data={photoshoots ? Object.values(photoshoots.tasks) : []}
          deleteTasks={removeTasks}
          studios={
            new Map(
              Object.values(studios?.entities || {}).map((studio) => {
                return [studio.id, studio.name];
              }),
            )
          }
          reloadData={loadJobs}
          uploadPhotoshoot={() => {
            setArchivesToUpload(new Map());
            setDialogOpened(true);
          }}
          loading={photoshoots?.isLoading || false}
          moreDataExist={!!photoshoots?.lastKey}
        />
      </PhotoshootsContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const PhotoshootsContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const ArchiveWrapper = styled.div`
  width: 850px;
  min-height: 400px;
  max-height: max-content;
`;

const FileSelectWrapper = styled.div`
  height: 150px;
  border: 2px dashed rgba(55, 55, 55, 0.58);
  border-radius: 10px;
  text-align: center;
`;

const ZipContainer = styled.div`
  margin-top: 2%;
  border-radius: 10px;
  border: 2px solid rgba(55, 55, 55, 0.58);
  align-items: center;
  padding: 2%;
`;

const ProcessStatus = styled.div`
  display: flex;
`;

const ButtonContainer = styled(Grid)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  text-align: end;
  display: flex;
  justify-content: flex-end;
`;

const ProgressContainer = styled.div`
  width: 850px;
  min-height: 400px;
  max-height: max-content;
  position: relative;
  padding-bottom: 60px;
`;

const ArchiveDetails = styled.div`
  padding-left: 2%;
`;

export default Photoshoots;
