import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import CreateTask from '../Systems/CreateTask';
import Tasks from '../Systems/Tasks';
import Entities from '../Systems/Entities';
import Jobs from '../Systems/Jobs';
import MannequinSystemsSettings from '../Settings';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { RootState } from '../../stores/types';
import LoadingIcon from '../Controls/LoadingIcon';
import styled from 'styled-components';
import HeadDesigner from '../Systems/HeadDesigner';
import Photoshoots from '../Systems/Photoshoots';
import Runs from '../Systems/Runs';
import { getUserData } from '../../stores/users';
import Home from '../Home';
import Security from '../Security';
import Workers from '../Workers';

interface MannequinSystemsProps {
  userName: string;
}

const MannequinSystems = (props: MannequinSystemsProps) => {
  const dispatch = useAppDispatch();
  const { userData, isMaintenanceModeOn, isLoading } = useAppSelector(
    (state: RootState) => state.usersReducer,
  );

  useEffect(() => {
    if (userData === null) {
      dispatch(getUserData(props.userName));
    }
    // eslint-disable-next-line
  }, []);

  return isLoading ? (
    <LoadingContainer>
      <LoadingIcon />
    </LoadingContainer>
  ) : (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        {(!isMaintenanceModeOn ||
          userData?.permissions.includes('maintenance_mode')) && (
          <>
            {userData?.permissions.includes('photoshoots') && (
              <Route path="/photoshoots" element={<Photoshoots />} />
            )}
            {userData?.permissions.includes('runs') && (
              <Route path="/runs" element={<Runs />} />
            )}
            {userData?.permissions.includes('tasks') && (
              <Route path="/tasks" element={<Tasks />} />
            )}
            {userData?.permissions.includes('jobs') && (
              <Route path="/jobs" element={<Jobs />} />
            )}
            {userData?.permissions.includes('head_designer') && (
              <Route path="/headDesigner" element={<HeadDesigner />} />
            )}
            {userData?.permissions.includes('create_task') && (
              <Route path="/createTask" element={<CreateTask />} />
            )}
            {userData?.permissions.includes('entities') && (
              <Route path="/entities" element={<Entities />} />
            )}
            {userData?.permissions.includes('workers') && (
              <Route path="/workers" element={<Workers />} />
            )}
            {userData?.permissions.includes('settings') && (
              <Route path="/settings" element={<MannequinSystemsSettings />} />
            )}
            {userData?.permissions.includes('security') && (
              <Route path="/security" element={<Security />} />
            )}
          </>
        )}
      </Routes>
    </Router>
  );
};

const LoadingContainer = styled.div`
  min-height: 775px;
  display: grid;
`;

export default MannequinSystems;
