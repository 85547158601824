import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateTableSettings } from '../../../stores/users';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import { TableSettings } from '../../../api/types/GetUserDataResponseDto';
import Link from '@mui/material/Link';

interface RunsTableProps {
  data: any[];
  moreDataExist: boolean;
  reloadData: (fetchMore?: boolean) => void;
  scheduleRuns: () => void;
  loading: boolean;
  deleteTasks: (taskIds: string[]) => void;
}

const defaultTableSettings: TableSettings = {
  columnVisibility: {
    id: false,
    created_by: false,
    created_at: false,
    error: false,
  },
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  density: 'compact',
  columnSize: {},
};

const RunsTable = (props: RunsTableProps) => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const data = useMemo(() => props.data, [props.data]);
  const tableSettings = useAppSelector(
    (state: RootState) =>
      state.usersReducer.tableSettings?.['runs'] ?? defaultTableSettings,
  );
  const [currentTableSettings, setCurrentTableSettings] =
    useState<TableSettings>(tableSettings);
  const [savedPageIndex, setSavedPageIndex] = useState<number | null>(null);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [showProgressBars, setShowProgressBars] = useState(false);
  const isLoading = useMemo(() => props.loading, [props.loading]);

  const [fullScreenImage, setFullScreenImage] = useState('');
  const [showFullScreen, setShowFullScreen] = useState(false);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => {
      const baseColumns: MRT_ColumnDef<any>[] = [
        {
          accessorKey: 'id',
          header: 'ID',
          size: 280,
        },
        {
          accessorKey: 'source_link',
          header: 'Source link',
          size: 350,
          enableClickToCopy: true,
        },
        {
          accessorKey: 'identity',
          header: 'Identity',
          size: 170,
        },
        {
          accessorKey: 'hair_dynamic',
          header: 'Hair dynamics',
          size: 170,
          Cell: ({ cell }) => (cell.getValue<Boolean>() ? 'Yes' : 'No'),
        },
        {
          accessorKey: 'manual_head_fit',
          header: 'Manual head fit',
          size: 170,
          Cell: ({ cell }) => (cell.getValue<Boolean>() ? 'Yes' : 'No'),
        },
        {
          accessorKey: 'manual_render',
          header: 'Manual render',
          size: 170,
          Cell: ({ cell }) => (cell.getValue<Boolean>() ? 'Yes' : 'No'),
        },
        {
          accessorKey: 'poses',
          header: 'Poses',
          size: 170,
        },
        {
          accessorKey: 'created_at',
          size: 220,
          accessorFn: (originalRow) => new Date(originalRow.created_at),
          header: 'Created at',
          filterVariant: 'date',
          filterFn: 'equals',
          muiFilterDatePickerProps: {
            format: 'DD/MM/YYYY',
          },
          enableGlobalFilter: false,
          Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
        },
        {
          accessorKey: 'created_by', //normal accessorKey
          header: 'Created by',
          size: 180,
        },
        {
          accessorKey: 'state',
          header: 'State',
          size: 170,
          filterVariant: 'multi-select',
          filterSelectOptions: ['created', 'processing', 'finished', 'failed'],
        },
        {
          accessorKey: 'aruco_link',
          header: 'Results link',
          size: 150,
          enableColumnFilter: false,
          Cell: ({ cell }) => (
            <Box>
              {cell.getValue<string>() && (
                <Link
                  href={cell.getValue<string>()}
                  style={{ cursor: 'pointer', color: 'black' }}
                >
                  download results
                </Link>
              )}
            </Box>
          ),
          enableGlobalFilter: false,
        },
        {
          accessorKey: 'result_links',
          header: 'Results',
          size: 180,
          enableGlobalFilter: false,
          Cell: ({ row }) => {
            return row.original.result_links ? (
              <Box>
                <ResultsWrapper>
                  {row.original.result_links.map(
                    (link: string, key: number) => (
                      <ImgWrapperSmall key={key}>
                        <Img
                          onClick={() => {
                            setFullScreenImage(link);
                            setShowFullScreen(true);
                          }}
                          src={link}
                          alt="img"
                        />
                      </ImgWrapperSmall>
                    ),
                  )}
                </ResultsWrapper>
              </Box>
            ) : (
              <></>
            );
          },
        },
        {
          accessorKey: 'error',
          header: 'Error',
          size: 200,
          enableClickToCopy: true,
          Cell: ({ cell }) => (
            <Box sx={{ textWrap: 'nowrap' }} title={cell.getValue<string>()}>
              {cell.getValue<string>()}
            </Box>
          ),
        },
      ];
      return baseColumns;
    },
    // eslint-disable-next-line
    [props],
  );

  const handleTableSettingsChange = async (settings: TableSettings) => {
    setShowProgressBars(true);
    await dispatch(
      updateTableSettings({
        userName: userData!.userName,
        tableName: 'runs',
        tableSettings: settings,
      }),
    );
    setShowProgressBars(false);
  };

  const resetTableSettings = () => {
    setCurrentTableSettings(tableSettings);
  };

  const showTableSettingsSaveResetButtons =
    currentTableSettings.pagination.pageSize !==
      tableSettings.pagination?.pageSize ||
    JSON.stringify(currentTableSettings.columnSize) !==
      JSON.stringify(tableSettings.columnSize) ||
    JSON.stringify(currentTableSettings.columnVisibility) !==
      JSON.stringify(tableSettings.columnVisibility) ||
    currentTableSettings.density !== tableSettings.density;

  const updateCurrentTableSettings = <K extends keyof TableSettings>(
    key: K,
    value: TableSettings[K] | ((old: TableSettings[K]) => TableSettings[K]),
  ) => {
    setCurrentTableSettings((prev) => ({
      ...prev,
      [key]:
        typeof value === 'function'
          ? (value as (old: TableSettings[K]) => TableSettings[K])(prev[key])
          : value,
    }));
  };

  const handleSaveTableSettingsChanges = () => {
    const updatedSettings: TableSettings = {
      ...tableSettings,
      ...currentTableSettings,
    };
    if (JSON.stringify(updatedSettings) !== JSON.stringify(tableSettings)) {
      setCurrentTableSettings(updatedSettings);
      handleTableSettingsChange(updatedSettings);
    }
  };

  useEffect(() => {
    if (
      (currentTableSettings.pagination.pageIndex + 1) *
        currentTableSettings.pagination.pageSize >
        props.data.length &&
      props.data.length > 0 &&
      props.moreDataExist
    ) {
      setSavedPageIndex(currentTableSettings.pagination.pageIndex);
      props.reloadData(true);
    }
    // eslint-disable-next-line
  }, [currentTableSettings.pagination]);

  useEffect(() => {
    if (savedPageIndex !== null && props.data.length > 0) {
      Promise.resolve().then(() => {
        updateCurrentTableSettings('pagination', (prevPagination) => ({
          ...prevPagination,
          pageIndex: savedPageIndex,
        }));
      });
      setSavedPageIndex(null);
    }
    // eslint-disable-next-line
  }, [props.data]);

  const openDeleteConfirmModal = async () => {
    setShowProgressBars(true);
    if (window.confirm('Are you sure you want to delete these runs?')) {
      await props.deleteTasks(Object.keys(rowSelection));
      setShowProgressBars(false);
    } else {
      setShowProgressBars(false);
    }
  };

  useEffect(() => {
    setRowSelection({});
  }, [props.data]);

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: props.moreDataExist ? props.data.length + 1 : props.data.length,
    getRowId: (row) => row.id,
    positionToolbarAlertBanner: 'bottom',
    muiTableContainerProps: {
      sx: {
        minHeight: '405px',
      },
    },
    renderTopToolbarCustomActions: () => (
      <TopToolbarContainer>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => props.reloadData()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Delete rows">
          <span>
            <IconButton
              onClick={openDeleteConfirmModal}
              disabled={Object.keys(rowSelection).length === 0}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip arrow title="Schedule runs">
          <IconButton onClick={() => props.scheduleRuns()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        {showTableSettingsSaveResetButtons && (
          <TableSettingsButtonsContainer>
            <Typography
              variant="body1"
              color={'rgb(1, 67, 97)'}
              style={{
                paddingRight: '25px',
              }}
            >
              Table structure changes detected
            </Typography>
            <Button onClick={handleSaveTableSettingsChanges}>
              Save changes
            </Button>
            <Button onClick={resetTableSettings}>Reset changes</Button>
          </TableSettingsButtonsContainer>
        )}
      </TopToolbarContainer>
    ),
    muiTopToolbarProps: showTableSettingsSaveResetButtons
      ? {
          sx: {
            backgroundColor: 'rgb(229, 246, 253)',
          },
        }
      : undefined,
    renderDetailPanel: ({ row }) => {
      return row.original.result_links ? (
        <Box>
          <ResultsWrapper>
            {row.original.result_links.map((link: string, key: number) => (
              <ImgWrapper key={key}>
                <Img
                  onClick={() => {
                    setFullScreenImage(link);
                    setShowFullScreen(true);
                  }}
                  src={link}
                  alt="img"
                  height="100%"
                />
              </ImgWrapper>
            ))}
          </ResultsWrapper>
        </Box>
      ) : (
        <></>
      );
    },
    state: {
      isLoading,
      pagination: currentTableSettings.pagination,
      rowSelection,
      showProgressBars,
      columnVisibility: currentTableSettings.columnVisibility,
      density: currentTableSettings.density,
      columnSizing: currentTableSettings.columnSize,
    },
    enableRowSelection: (row) =>
      row.original.state !== 'processing' && row.original.state !== 'created',
    onRowSelectionChange: setRowSelection,
    onPaginationChange: (updaterOrValue) => {
      updateCurrentTableSettings('pagination', updaterOrValue);
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnVisibility', updaterOrValue);
    },
    onColumnSizingChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnSize', updaterOrValue);
    },
    onDensityChange: (updaterOrValue) => {
      updateCurrentTableSettings('density', updaterOrValue);
    },
    enableColumnResizing: true,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ModalContainer
        open={showFullScreen}
        onClose={() => setShowFullScreen(false)}
      >
        <Image
          src={fullScreenImage}
          alt={'img'}
          onClick={() => setShowFullScreen(false)}
        />
      </ModalContainer>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  max-height: 80%;
  cursor: zoom-out;
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 300px;
`;

const TopToolbarContainer = styled.div`
  display: flex;
`;

const TableSettingsButtonsContainer = styled.div`
  display: flex;
  padding-left: 100px;
  align-items: center;
`;

const ImgWrapperSmall = styled(ImgWrapper)`
  width: 50px;
  height: 50px;
`;

export const Img = styled.img`
  height: 100%;
  cursor: zoom-in;
`;

const ResultsWrapper = styled.div`
  display: flex;
`;

export default RunsTable;
