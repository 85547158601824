import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateTableSettings } from '../../../stores/users';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import { TableSettings } from '../../../api/types/GetUserDataResponseDto';

interface HeadDesignsTableProps {
  data: any[];
  moreDataExist: boolean;
  reloadData: (fetchMore?: boolean) => void;
  uploadDesign: () => void;
  loading: boolean;
  deleteTasks: (taskIds: string[]) => void;
}

const defaultTableSettings: TableSettings = {
  columnVisibility: {
    id: false,
    created_by: false,
    created_at: false,
  },
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  density: 'compact',
  columnSize: {},
};

const HeadDesignsTable = (props: HeadDesignsTableProps) => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const data = useMemo(() => props.data, [props.data]);
  const tableSettings = useAppSelector(
    (state: RootState) =>
      state.usersReducer.tableSettings?.['head_design'] ?? defaultTableSettings,
  );
  const [currentTableSettings, setCurrentTableSettings] =
    useState<TableSettings>(tableSettings);
  const [savedPageIndex, setSavedPageIndex] = useState<number | null>(null);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [showProgressBars, setShowProgressBars] = useState(false);
  const isLoading = useMemo(() => props.loading, [props.loading]);

  const [fullScreenImage, setFullScreenImage] = useState('');
  const [showFullScreen, setShowFullScreen] = useState(false);

  const getJobData = (jobData: any) => {
    if (jobData.error)
      return (
        <div
          style={{
            height: '100%',
            color: 'red',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Failed
        </div>
      );
    if (jobData.state === 'finished')
      return (
        <Box>
          <Img
            onClick={() => {
              setFullScreenImage(jobData.results.result);
              setShowFullScreen(true);
            }}
            src={jobData.results.thumbnail}
            alt="img"
          />
        </Box>
      );
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {jobData.state}
      </div>
    );
  };

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => {
      const baseColumns: MRT_ColumnDef<any>[] = [
        {
          accessorKey: 'id', //access nested data with dot notation
          header: 'ID',
          size: 260,
        },
        {
          accessorKey: 'name', //normal accessorKey
          header: 'Name',
          size: 220,
        },
        {
          accessorKey: 'created_by', //normal accessorKey
          header: 'Created by',
          size: 180,
        },
        {
          accessorKey: 'created_at',
          size: 160,
          accessorFn: (originalRow) => new Date(originalRow.created_at),
          header: 'Created at',
          filterVariant: 'date',
          filterFn: 'greaterThan',
          enableGlobalFilter: false,
          Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
        },
        {
          accessorKey: 'ph1', //normal accessorKey
          header: 'PH1',
          size: 100,
          Cell: ({ row }) => (
            <ImgWrapperSmall>
              {getJobData(row.original.jobs.ph1)}
            </ImgWrapperSmall>
          ),
        },
        {
          accessorKey: 'ph4', //normal accessorKey
          header: 'PH4',
          size: 100,
          Cell: ({ row }) => (
            <ImgWrapperSmall>
              {getJobData(row.original.jobs.ph4)}
            </ImgWrapperSmall>
          ),
        },
        {
          accessorKey: 'ph8', //normal accessorKey
          header: 'PH8',
          size: 100,
          Cell: ({ row }) => (
            <ImgWrapperSmall>
              {getJobData(row.original.jobs.ph8)}
            </ImgWrapperSmall>
          ),
        },
      ];

      return baseColumns;
    },
    // eslint-disable-next-line
    [props],
  );

  const handleTableSettingsChange = async (settings: TableSettings) => {
    setShowProgressBars(true);
    await dispatch(
      updateTableSettings({
        userName: userData!.userName,
        tableName: 'head_design',
        tableSettings: settings,
      }),
    );
    setShowProgressBars(false);
  };

  const resetTableSettings = () => {
    setCurrentTableSettings(tableSettings);
  };

  const showTableSettingsSaveResetButtons =
    currentTableSettings.pagination.pageSize !==
      tableSettings.pagination?.pageSize ||
    JSON.stringify(currentTableSettings.columnSize) !==
      JSON.stringify(tableSettings.columnSize) ||
    JSON.stringify(currentTableSettings.columnVisibility) !==
      JSON.stringify(tableSettings.columnVisibility) ||
    currentTableSettings.density !== tableSettings.density;

  const updateCurrentTableSettings = <K extends keyof TableSettings>(
    key: K,
    value: TableSettings[K] | ((old: TableSettings[K]) => TableSettings[K]),
  ) => {
    setCurrentTableSettings((prev) => ({
      ...prev,
      [key]:
        typeof value === 'function'
          ? (value as (old: TableSettings[K]) => TableSettings[K])(prev[key])
          : value,
    }));
  };

  const handleSaveTableSettingsChanges = () => {
    const updatedSettings: TableSettings = {
      ...tableSettings,
      ...currentTableSettings,
    };
    if (JSON.stringify(updatedSettings) !== JSON.stringify(tableSettings)) {
      setCurrentTableSettings(updatedSettings);
      handleTableSettingsChange(updatedSettings);
    }
  };

  useEffect(() => {
    if (
      (currentTableSettings.pagination.pageIndex + 1) *
        currentTableSettings.pagination.pageSize >
        props.data.length &&
      props.data.length > 0 &&
      props.moreDataExist
    ) {
      setSavedPageIndex(currentTableSettings.pagination.pageIndex);
      props.reloadData(true);
    }
    // eslint-disable-next-line
  }, [currentTableSettings.pagination]);

  useEffect(() => {
    if (savedPageIndex !== null && props.data.length > 0) {
      Promise.resolve().then(() => {
        updateCurrentTableSettings('pagination', (prevPagination) => ({
          ...prevPagination,
          pageIndex: savedPageIndex,
        }));
      });
      setSavedPageIndex(null);
    }
    // eslint-disable-next-line
  }, [props.data]);

  const openDeleteConfirmModal = async () => {
    setShowProgressBars(true);
    if (window.confirm('Are you sure you want to delete these head designs?')) {
      await props.deleteTasks(Object.keys(rowSelection));
      setShowProgressBars(false);
    } else {
      setShowProgressBars(false);
    }
  };

  useEffect(() => {
    setRowSelection({});
  }, [props.data]);

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: props.moreDataExist ? props.data.length + 1 : props.data.length,
    getRowId: (row) => row.id,
    positionToolbarAlertBanner: 'bottom',
    muiTableContainerProps: {
      sx: {
        minHeight: '405px',
      },
    },
    renderTopToolbarCustomActions: () => (
      <TopToolbarContainer>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => props.reloadData()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Delete rows">
          <span>
            <IconButton
              onClick={openDeleteConfirmModal}
              disabled={Object.keys(rowSelection).length === 0}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip arrow title="Upload design">
          <IconButton onClick={() => props.uploadDesign()}>
            <AddIcon />
          </IconButton>
        </Tooltip>
        {showTableSettingsSaveResetButtons && (
          <TableSettingsButtonsContainer>
            <Typography
              variant="body1"
              color={'rgb(1, 67, 97)'}
              style={{
                paddingRight: '25px',
              }}
            >
              Table structure changes detected
            </Typography>
            <Button onClick={handleSaveTableSettingsChanges}>
              Save changes
            </Button>
            <Button onClick={resetTableSettings}>Reset changes</Button>
          </TableSettingsButtonsContainer>
        )}
      </TopToolbarContainer>
    ),
    muiTopToolbarProps: showTableSettingsSaveResetButtons
      ? {
          sx: {
            backgroundColor: 'rgb(229, 246, 253)',
          },
        }
      : undefined,
    renderDetailPanel: ({ row }) => {
      if (
        (row.original.jobs.ph1.state !== 'processing' &&
          row.original.jobs.ph1.state !== 'created') ||
        (row.original.jobs.ph4.state !== 'processing' &&
          row.original.jobs.ph4.state !== 'created') ||
        (row.original.jobs.ph8.state !== 'processing' &&
          row.original.jobs.ph8.state !== 'created')
      ) {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ImgWrapper>{getJobData(row.original.jobs.ph1)}</ImgWrapper>
            <ImgWrapper>{getJobData(row.original.jobs.ph4)}</ImgWrapper>
            <ImgWrapper>{getJobData(row.original.jobs.ph8)}</ImgWrapper>
          </Box>
        );
      } else {
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <TextWrapper>{`PH1: ${row.original.jobs.ph1.state}`}</TextWrapper>
            <TextWrapper>{`PH4: ${row.original.jobs.ph4.state}`}</TextWrapper>
            <TextWrapper>{`PH8: ${row.original.jobs.ph8.state}`}</TextWrapper>
          </Box>
        );
      }
    },
    state: {
      isLoading,
      pagination: currentTableSettings.pagination,
      rowSelection,
      showProgressBars,
      columnVisibility: currentTableSettings.columnVisibility,
      density: currentTableSettings.density,
      columnSizing: currentTableSettings.columnSize,
    },
    onPaginationChange: (updaterOrValue) => {
      updateCurrentTableSettings('pagination', updaterOrValue);
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnVisibility', updaterOrValue);
    },
    onColumnSizingChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnSize', updaterOrValue);
    },
    onDensityChange: (updaterOrValue) => {
      updateCurrentTableSettings('density', updaterOrValue);
    },
    enableColumnResizing: true,
    enableRowSelection: (row) => {
      const jobs = row.original.jobs ? Object.values(row.original.jobs) : [];
      return jobs.some(
        (job: any) => job.state !== 'processing' && job.state !== 'created',
      );
    },
    onRowSelectionChange: setRowSelection,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ModalContainer
        open={showFullScreen}
        onClose={() => setShowFullScreen(false)}
      >
        <Image
          src={fullScreenImage}
          alt={'img'}
          onClick={() => setShowFullScreen(false)}
        />
      </ModalContainer>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopToolbarContainer = styled.div`
  display: flex;
`;

const TableSettingsButtonsContainer = styled.div`
  display: flex;
  padding-left: 100px;
  align-items: center;
`;

const Image = styled.img`
  max-height: 80%;
  cursor: zoom-out;
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 450px;
  height: 300px;
`;

const ImgWrapperSmall = styled(ImgWrapper)`
  width: 50px;
  height: 50px;
`;

const TextWrapper = styled(ImgWrapper)`
  height: 30px;
`;

const Img = styled.img`
  height: 100%;
  cursor: zoom-in;
`;

export default HeadDesignsTable;
