import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { taskColumns } from '../Configuration';
import { ColumnFilter } from '@tanstack/table-core/src/features/Filters';
import { useSearchParams } from 'react-router-dom';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { setTaskCheckingState } from '../../../stores/tasks';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateTableSettings } from '../../../stores/users';
import { RootState } from '../../../stores/types';
import styled from 'styled-components';
import { TableSettings } from '../../../api/types/GetUserDataResponseDto';

interface TasksTableProps {
  data: any[];
  moreDataExist: boolean;
  reloadData: (fetchMore?: boolean) => void;
  loading: boolean;
  checkingState: string;
  deleteTasks: (taskIds: string[]) => void;
}

const defaultTableSettings: TableSettings = {
  columnVisibility: {
    created_at: false,
    started_at: false,
    finished_at: false,
    created_by: false,
    segmentation_data: false,
    inpaint_data: false,
    render_data: false,
    ml_data: false,
    outputs_path: false,
    error: false,
  },
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  density: 'compact',
  columnSize: {},
};

const tasksFilters = ['id'];

const TasksTable = (props: TasksTableProps) => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const tableSettings = useAppSelector(
    (state: RootState) =>
      state.usersReducer.tableSettings?.['tasks'] ?? defaultTableSettings,
  );
  const [currentTableSettings, setCurrentTableSettings] =
    useState<TableSettings>(tableSettings);
  const [savedPageIndex, setSavedPageIndex] = useState<number | null>(null);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [showProgressBars, setShowProgressBars] = useState(false);
  const data = useMemo(() => {
    if (props.checkingState === 'polishing') {
      let polishingData: { [index: string]: any } = {};
      props.data.forEach((task) => {
        if (task.checking_state === props.checkingState)
          polishingData[task.id] = { ...task, subRows: [] };
      });
      Object.values(polishingData).forEach((task) => {
        if (task.ref_id && polishingData[task.ref_id]) {
          polishingData[task.ref_id].subRows.push(task);
          delete polishingData[task.id];
        }
      });
      return Object.values(polishingData);
    }
    let tempData: any[] = [];
    props.data.forEach((task) => {
      if (task.checking_state === props.checkingState) tempData.push(task);
    });
    return tempData;
    // eslint-disable-next-line
  }, [props.data]);
  const isLoading = useMemo(() => props.loading, [props.loading]);
  const [searchParams] = useSearchParams();
  const initFilters = useMemo(() => {
    let filter: ColumnFilter[] = [];
    searchParams.forEach((value, key) => {
      if (tasksFilters.includes(key)) {
        let tempValue = key === 'state' ? value.split(',') : value;
        filter.push({ id: key, value: tempValue });
      }
    });
    return filter;
    // eslint-disable-next-line
  }, [searchParams]);
  const [filter, setFilter] = useState<ColumnFilter[]>(initFilters);
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => {
      return taskColumns;
    },
    // eslint-disable-next-line
    [props],
  );

  const handleTableSettingsChange = async (settings: TableSettings) => {
    setShowProgressBars(true);
    await dispatch(
      updateTableSettings({
        userName: userData!.userName,
        tableName: 'tasks',
        tableSettings: settings,
      }),
    );
    setShowProgressBars(false);
  };

  const resetTableSettings = () => {
    setCurrentTableSettings(tableSettings);
  };

  const showTableSettingsSaveResetButtons =
    currentTableSettings.pagination.pageSize !==
      tableSettings.pagination?.pageSize ||
    JSON.stringify(currentTableSettings.columnSize) !==
      JSON.stringify(tableSettings.columnSize) ||
    JSON.stringify(currentTableSettings.columnVisibility) !==
      JSON.stringify(tableSettings.columnVisibility) ||
    currentTableSettings.density !== tableSettings.density;

  const updateCurrentTableSettings = <K extends keyof TableSettings>(
    key: K,
    value: TableSettings[K] | ((old: TableSettings[K]) => TableSettings[K]),
  ) => {
    setCurrentTableSettings((prev) => ({
      ...prev,
      [key]:
        typeof value === 'function'
          ? (value as (old: TableSettings[K]) => TableSettings[K])(prev[key])
          : value,
    }));
  };

  const handleSaveTableSettingsChanges = () => {
    const updatedSettings: TableSettings = {
      ...tableSettings,
      ...currentTableSettings,
    };
    if (JSON.stringify(updatedSettings) !== JSON.stringify(tableSettings)) {
      setCurrentTableSettings(updatedSettings);
      handleTableSettingsChange(updatedSettings);
    }
  };

  useEffect(() => {
    if (
      (currentTableSettings.pagination.pageIndex + 1) *
        currentTableSettings.pagination.pageSize >
        props.data.length &&
      props.data.length > 0 &&
      props.moreDataExist
    ) {
      setSavedPageIndex(currentTableSettings.pagination.pageIndex);
      props.reloadData(true);
    }
    // eslint-disable-next-line
  }, [currentTableSettings.pagination]);

  useEffect(() => {
    if (savedPageIndex !== null && props.data.length > 0) {
      Promise.resolve().then(() => {
        updateCurrentTableSettings('pagination', (prevPagination) => ({
          ...prevPagination,
          pageIndex: savedPageIndex,
        }));
      });
      setSavedPageIndex(null);
    }
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    setRowSelection({});
  }, [props.data]);

  const openDeleteConfirmModal = async () => {
    setShowProgressBars(true);
    if (window.confirm('Are you sure you want to delete these tasks?')) {
      await props.deleteTasks(Object.keys(rowSelection));
      setShowProgressBars(false);
    } else {
      setShowProgressBars(false);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: props.moreDataExist ? props.data.length + 1 : props.data.length,
    enableRowSelection: (row) =>
      row.original.state !== 'processing' && row.original.state !== 'created',
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    onColumnFiltersChange: setFilter,
    positionToolbarAlertBanner: 'bottom',
    muiTableContainerProps: {
      sx: {
        minHeight: '405px',
      },
    },
    renderTopToolbarCustomActions: () => (
      <TopToolbarContainer>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => props.reloadData()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Delete rows">
          <span>
            <IconButton
              onClick={openDeleteConfirmModal}
              disabled={Object.keys(rowSelection).length === 0}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        {props.checkingState === 'base' && (
          <Tooltip arrow title="Polish">
            <span>
              <IconButton
                disabled={Object.keys(rowSelection).length === 0}
                onClick={async () => {
                  setShowProgressBars(true);
                  await dispatch(
                    setTaskCheckingState({
                      taskIds: Object.keys(rowSelection),
                      checkingState: 'polishing',
                    }),
                  );
                  setRowSelection({});
                  setShowProgressBars(false);
                }}
              >
                <AutoFixHighIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {['polishing', 'base'].includes(props.checkingState) && (
          <Tooltip arrow title="Done">
            <span>
              <IconButton
                disabled={Object.keys(rowSelection).length === 0}
                onClick={async () => {
                  setShowProgressBars(true);
                  await dispatch(
                    setTaskCheckingState({
                      taskIds: Object.keys(rowSelection),
                      checkingState: 'done',
                    }),
                  );
                  setRowSelection({});
                  setShowProgressBars(false);
                }}
              >
                <DoneAllIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
        {showTableSettingsSaveResetButtons && (
          <TableSettingsButtonsContainer>
            <Typography
              variant="body1"
              color={'rgb(1, 67, 97)'}
              style={{
                paddingRight: '25px',
              }}
            >
              Table structure changes detected
            </Typography>
            <Button onClick={handleSaveTableSettingsChanges}>
              Save changes
            </Button>
            <Button onClick={resetTableSettings}>Reset changes</Button>
          </TableSettingsButtonsContainer>
        )}
      </TopToolbarContainer>
    ),
    muiTopToolbarProps: showTableSettingsSaveResetButtons
      ? {
          sx: {
            backgroundColor: 'rgb(229, 246, 253)',
          },
        }
      : undefined,
    state: {
      isLoading,
      pagination: currentTableSettings.pagination,
      columnFilters: filter,
      rowSelection,
      showProgressBars,
      columnVisibility: currentTableSettings.columnVisibility,
      density: currentTableSettings.density,
      columnSizing: currentTableSettings.columnSize,
    },
    onPaginationChange: (updaterOrValue) => {
      updateCurrentTableSettings('pagination', updaterOrValue);
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnVisibility', updaterOrValue);
    },
    onColumnSizingChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnSize', updaterOrValue);
    },
    onDensityChange: (updaterOrValue) => {
      updateCurrentTableSettings('density', updaterOrValue);
    },
    enableColumnResizing: true,
    enableExpandAll: true, //hide expand all double arrow in column header
    enableExpanding: props.checkingState === 'polishing',
    filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
    getSubRows: (row) => row.subRows, //default
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

const TopToolbarContainer = styled.div`
  display: flex;
`;

const TableSettingsButtonsContainer = styled.div`
  display: flex;
  padding-left: 100px;
  align-items: center;
`;

export default TasksTable;
