import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import { deleteTasks, loadTask } from '../../../stores/tasks';
import DialogControl from '../../Controls/DialowWindow';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import MannequinSystemsApi from '../../../api/MannequinSystemsApi';
import { enqueueSnackbar } from 'notistack';
import CachedIcon from '@mui/icons-material/Cached';
import RunsTable from '../../Tables/RunsTable';
import { loadEntities } from '../../../stores/entities';
import LoadingIcon from '../../Controls/LoadingIcon';
import Modal from '@mui/material/Modal';
import CustomSelect from '../../Controls/CustomSelect';

const Runs = () => {
  const dispatch = useAppDispatch();
  const jobs = useAppSelector((state: RootState) => state.tasksReducer);
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const entitiesStore = useAppSelector(
    (state: RootState) => state.entitiesReducer,
  );

  const identities = entitiesStore.identity;
  const photoshoots = jobs.photoshoot;
  const runs = jobs.run;

  const [dialogOpened, setDialogOpened] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);

  const [selectedIdentity, setSelectedIdentity] = useState<any>('');
  const [selectedPoses, setSelectedPoses] = useState<Map<string, boolean>>(
    new Map(),
  );
  const [selectedPhotoshoot, setSelectedPhotoshoot] = useState<any>('');
  const [hairDynamic, setHairDynamic] = useState(false);
  const [manualHeadFit, setManualHeadFit] = useState(false);
  const [manualRender, setManualRender] = useState(false);

  const loadJobs = (extend?: boolean) => {
    dispatch(
      loadTask({
        lastKey: extend ? runs?.lastKey : null,
        type: 'run',
      }),
    );
  };

  const removeTasks = async (taskIds: string[]) => {
    await dispatch(deleteTasks({ taskIds: taskIds, type: 'run' }));
  };

  useEffect(() => {
    if (runs === null) loadJobs();
    if (photoshoots === null)
      dispatch(
        loadTask({
          lastKey: null,
          type: 'photoshoot',
        }),
      );
    if (identities === null) {
      dispatch(loadEntities('identity'));
    }
    // eslint-disable-next-line
  }, []);

  const scheduleRuns = async () => {
    const listPoses = Array.from(selectedPoses.entries())
      .filter(([_, isSelected]) => isSelected)
      .map(([poseKey]) => poseKey);
    if (
      selectedIdentity === '' ||
      selectedPhotoshoot === '' ||
      listPoses.length === 0
    ) {
      enqueueSnackbar(`Photoshoot, identity, and pose should be selected..`, {
        variant: 'warning',
      });
      return;
    }
    setIsProcessing(true);
    await MannequinSystemsApi.scheduleRuns(
      userData!.userName,
      selectedPhotoshoot.id,
      selectedIdentity.id,
      listPoses,
      hairDynamic,
      manualHeadFit,
      manualRender,
    )
      .then((response) => {
        if (response.status !== 200)
          enqueueSnackbar(
            `Failed to schedule runs. Error: ${response.statusText}`,
            { variant: 'error' },
          );
        else if (response.data.errorMessage)
          enqueueSnackbar(
            `Failed to schedule runs. Error: ${response.data.errorMessage}`,
            { variant: 'error' },
          );
        else
          enqueueSnackbar(`Runs scheduled.`, {
            variant: 'success',
          });
        setIsProcessing(false);
        setIsScheduled(true);
      })
      .catch((error) => {
        enqueueSnackbar(`Failed to schedule runs. Error: ${error}`, {
          variant: 'error',
        });
      });
    setIsProcessing(false);
  };

  const onCloseAction = () => {
    if (isScheduled) {
      loadJobs();
      setIsScheduled(false);
    }
  };

  const handlePoseChange = (poseKey: string) => {
    setSelectedPoses((prevPoses) => {
      const newPoses = new Map(prevPoses);
      newPoses.set(poseKey, !prevPoses.get(poseKey));
      return newPoses;
    });
  };

  return (
    <Container>
      <Modal open={isProcessing}>
        <LoadingIcon />
      </Modal>
      {photoshoots?.tasks && identities && (
        <DialogControl
          isOpen={dialogOpened}
          title={`Schedule runs`}
          description={''}
          setIsOpen={setDialogOpened}
          approveAction={scheduleRuns}
          approveButtonDisabled={photoshoots.isLoading || identities.isLoading}
          onCloseAction={onCloseAction}
        >
          <ModalWrapper>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <CustomSelect
                  description="Photoshoots"
                  value={selectedPhotoshoot}
                  onChange={(event) => {
                    setSelectedPhotoshoot(event.target.value);
                  }}
                  disabled={photoshoots.isLoading}
                  data={photoshoots.tasks!}
                  renderValue={(value) => value.name || 'None'}
                  displayText={(item) => `${item.name}`}
                />
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  paddingTop: '28px',
                }}
              >
                {photoshoots.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    onClick={() => {
                      dispatch(
                        loadTask({
                          lastKey: null,
                          type: 'photoshoot',
                        }),
                      );
                    }}
                  >
                    <CachedIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={10}>
                <CustomSelect
                  description="Identity"
                  value={selectedIdentity}
                  onChange={(event) => {
                    const newIdentity = event.target.value;
                    setSelectedIdentity(newIdentity);
                    if (newIdentity && newIdentity.poses) {
                      setSelectedPoses(
                        new Map(
                          Object.keys(newIdentity.poses).map((pose: string) => {
                            return [pose, true];
                          }),
                        ),
                      );
                    }
                  }}
                  disabled={identities!.isLoading}
                  data={identities!.entities}
                  renderValue={(value) =>
                    value && value.name
                      ? `${value.name}_${value.version}`
                      : 'None'
                  }
                  displayText={(item) => `${item.name}_${item.version}`}
                />
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  paddingTop: '28px',
                }}
              >
                {identities.isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <IconButton
                    onClick={() => {
                      dispatch(loadEntities('identity'));
                    }}
                  >
                    <CachedIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid
                container
                sx={{
                  paddingLeft: '6px',
                  paddingTop: '6px',
                }}
              >
                <Grid item xs={5}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControlLabel
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }}
                        control={
                          <Checkbox checked={hairDynamic} color="default" />
                        }
                        label={'Hair dynamic'}
                        onChange={() => setHairDynamic(!hairDynamic)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }}
                        control={
                          <Checkbox checked={manualHeadFit} color="default" />
                        }
                        label={'Manual head fit'}
                        onChange={(event) => {
                          // @ts-ignore
                          if (event.target.checked) {
                            setManualRender(false);
                          }
                          setManualHeadFit(!manualHeadFit);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }}
                        control={
                          <Checkbox checked={manualRender} color="default" />
                        }
                        label={'Manual render'}
                        onChange={(event) => {
                          // @ts-ignore
                          if (event.target.checked) {
                            setManualHeadFit(false);
                            setHairDynamic(false);
                          }
                          setManualRender(!manualRender);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {selectedIdentity && (
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        paddingTop: '15px',
                      }}
                    >
                      Poses for run:
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={5}>
                  <Grid container>
                    {selectedIdentity &&
                      Object.keys(selectedIdentity.poses).map((poseKey) => (
                        <Grid item xs={12} key={poseKey}>
                          <FormControlLabel
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 38 } }}
                            control={
                              <Checkbox
                                checked={selectedPoses.get(poseKey) || false}
                                color="default"
                                onChange={() => handlePoseChange(poseKey)}
                              />
                            }
                            label={poseKey}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ModalWrapper>
        </DialogControl>
      )}

      <Header name={'Runs'} />
      <RunsContainer>
        <RunsTable
          key={'run'}
          data={runs ? Object.values(runs.tasks) : []}
          reloadData={loadJobs}
          deleteTasks={removeTasks}
          scheduleRuns={() => {
            setDialogOpened(true);
            setSelectedIdentity('');
            setSelectedPhotoshoot('');
            setHairDynamic(false);
            setManualHeadFit(false);
            setManualRender(false);
          }}
          loading={runs?.isLoading || false}
          moreDataExist={!!runs?.lastKey}
        />
      </RunsContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const ModalWrapper = styled.div`
  width: 650px;
  min-height: 335px;
`;

const RunsContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

export default Runs;
