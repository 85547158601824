import { Button } from '@mui/material';
import React from 'react';

interface ButtonProps {
  onClick: () => void;
  description: string;
  size?: 'small' | 'large' | 'medium' | undefined;
}

const ButtonControl = (props: ButtonProps) => {
  return (
    <Button
      variant="outlined"
      color="inherit"
      onClick={props.onClick}
      size={props.size}
      sx={{
        width: '100%',
        minWidth: '100px',
      }}
    >
      {props.description}
    </Button>
  );
};

export default ButtonControl;
