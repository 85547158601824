import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import SelectControl from '../../Controls/SelectControl';
import {
  BottomNavigation,
  BottomNavigationAction,
  CircularProgress,
  Grid,
  IconButton,
} from '@mui/material';
import AccordionControl from '../../Controls/AccordionControl';
import AddIcon from '@mui/icons-material/Add';
import LoadingIcon from '../../Controls/LoadingIcon';
import Modal from '@mui/material/Modal';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import MlInputs from './mlInputs';
import RenderInputs from './renderInputs';
import FitInputs from './fitInputs';
import TextFieldControl from '../../Controls/TextFieldControl';
import InpaintInputs from './inpaintInputs';
import SegmentationInputs from './segmentationInputs';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { enqueueSnackbar } from 'notistack';
import { addTask, loadTask } from '../../../stores/tasks';
import { RootState } from '../../../stores/types';
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { loadConfiguration } from '../../../stores/configuration';
import { loadEntities } from '../../../stores/entities';
import CustomSelect from '../../Controls/CustomSelect';
import CachedIcon from '@mui/icons-material/Cached';

const CreateTask = () => {
  const navigate = useNavigate();
  const inputRefs: Record<string, React.MutableRefObject<any>> = {
    fit: useRef<any>(),
    inpaint: useRef<any>(),
    segmentation: useRef<any>(),
    render: useRef<any>(),
    ml: useRef<any>(),
  };

  const dispatch = useAppDispatch();
  const { data: configuration, isLoading: configurationLoading } =
    useAppSelector((state: RootState) => state.configurationReducer);
  const entitiesStore = useAppSelector(
    (state: RootState) => state.entitiesReducer,
  );
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const jobs = useAppSelector((state: RootState) => state.tasksReducer);
  const identities = entitiesStore.identity;
  const studios = entitiesStore.studio;
  const [isLoading, setIsLoading] = useState(false);
  const [stage, setStage] = useState('');
  const [refId, setRefId] = useState('');
  const [tag, setTag] = useState('');
  const [requires, setRequires] = useState<string[]>([]);
  const [inputsAccordionState, setInputsAccordionState] = useState<
    Map<string, boolean>
  >(new Map());
  const [stageIsManual, setStageIsManual] = useState<Map<string, boolean>>(
    new Map(),
  );
  const [sourceLink, setSourceLink] = useState('');
  const [selectedIdentity, setSelectedIdentity] = useState<any>('');
  const [selectedStudio, setSelectedStudio] = useState<any>('');
  const [selectedPose, setSelectedPose] = useState<any>('');

  useEffect(() => {
    if (selectedIdentity) {
      setSelectedPose('');
    }
  }, [selectedIdentity]);

  useEffect(() => {
    if (configuration === null) dispatch(loadConfiguration());
    if (identities === null) dispatch(loadEntities('identity'));
    if (studios === null) dispatch(loadEntities('studio'));
    if (jobs.photoshoot === null)
      dispatch(loadTask({ lastKey: null, type: 'photoshoot' }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (configuration && inputsAccordionState.size === 0) {
      setInputsAccordionState(
        new Map(
          Object.keys(configuration.services).map((serviceName) => [
            serviceName,
            ['ml'].includes(serviceName),
          ]),
        ),
      );
      setStageIsManual(
        new Map(
          Object.keys(configuration.services).map((serviceName) => [
            serviceName,
            configuration!.services[serviceName].is_manual,
          ]),
        ),
      );
      setStage(
        Object.keys(configuration!.services)[
          Object.keys(configuration!.services).length - 1
        ],
      );
    }
    // eslint-disable-next-line
  }, [configuration]);

  useEffect(() => {
    if (stage.length > 0 && configuration && stageIsManual.size > 0) {
      setRequires(Array.from(getRequiredStages(stage)));
    }
    // eslint-disable-next-line
  }, [stage, stageIsManual]);

  const getRequiredStages = (stage: string): Set<string> => {
    let tempRequired: Set<string> = new Set();
    tempRequired.add(stage);
    if (!stageIsManual!.get(stage)) {
      configuration!.services[stage].requires.forEach((requiredStage) => {
        getRequiredStages(requiredStage).forEach((newStage) => {
          tempRequired.add(newStage);
        });
      });
    }
    return tempRequired;
  };

  const reset = () => {
    Object.values(inputRefs).forEach((ref) => {
      if (ref.current && ref.current.resetModal) {
        ref.current.resetModal();
      }
      setSelectedIdentity('');
      setSelectedStudio('');
      setSelectedPose('');
      setTag('');
      setRefId('');
      setSourceLink('');
    });
  };

  const createTask = async () => {
    setIsLoading(true);
    let taskData = new Map();

    if (sourceLink.length === 0) {
      enqueueSnackbar('Input source path not found!', { variant: 'warning' });
      setIsLoading(false);
      return;
    } else {
      taskData.set('input_data', {
        data: {
          inputs: [
            {
              gdrive_link: sourceLink,
              orig_no_head: sourceLink + '/orig_no_head.png',
            },
          ],
        },
      });
      taskData.set('gdrive_link', sourceLink);
    }

    if (selectedIdentity === '' || selectedPose === '') {
      enqueueSnackbar('Identity or pose not selected!', { variant: 'warning' });
      setIsLoading(false);
      return;
    } else {
      taskData.set('identity', identities?.entities.get(selectedIdentity));
      taskData.set('pose', selectedPose);
    }

    if (selectedStudio === '') {
      enqueueSnackbar('Studio not selected!', { variant: 'warning' });
      setIsLoading(false);
      return;
    } else taskData.set('studio', studios?.entities.get(selectedStudio));

    for (const key in inputRefs) {
      if (inputRefs.hasOwnProperty(key)) {
        const ref = inputRefs[key];
        if (ref.current) {
          const inputs = await ref.current.getInputs();
          if (inputs) {
            taskData.set(`${key}_data`, inputs);
          } else {
            setIsLoading(false);
            return;
          }
        }
      }
    }

    if (refId.length > 0) taskData.set('ref_id', refId);
    if (tag.length > 0) taskData.set('tag', tag);

    dispatch(
      addTask({
        taskData: Object.fromEntries(taskData.entries()),
        stage: stage,
        userName: userData!.userName,
      }),
    )
      .then((action) => {
        if (action.payload.statusCode === 200)
          enqueueSnackbar('Task created. Id:' + action.payload.task.id, {
            variant: 'success',
            action: () => (
              <IconButton
                onClick={() => {
                  const params: Record<string, string> = {
                    id: action.payload.task.id,
                  };
                  if (refId.length > 0) params.refId = refId;
                  const newSearchParams = new URLSearchParams(
                    params,
                  ).toString();
                  navigate(`/tasks?${newSearchParams}`);
                }}
              >
                <OpenInNewIcon />
              </IconButton>
            ),
          });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateManualState = (stage: string, state: boolean) => {
    setStageIsManual((prevState) => new Map(prevState).set(stage, state));
  };

  const getAccordionState = (key: string): boolean => {
    return inputsAccordionState.get(key) || false;
  };

  const changeAccordionState = (key: string) => {
    setInputsAccordionState(
      new Map(inputsAccordionState).set(key, !inputsAccordionState.get(key)),
    );
  };

  return !configuration ||
    configurationLoading ||
    !identities ||
    !studios ||
    !jobs.photoshoot ? (
    <LoadingContainer>
      <LoadingIcon />
    </LoadingContainer>
  ) : (
    <Container>
      <Modal open={isLoading}>
        <LoadingIcon />
      </Modal>
      <Wrapper>
        <Header name={'Create Task'} />
        <RegisterContainer>
          <Grid container spacing={1}>
            <Grid item xs={2}>
              <SelectControl
                id={'stage'}
                value={stage}
                items={configuration ? Object.keys(configuration.services) : []}
                description={'Stage'}
                disabled={false}
                canBeEmpty={false}
                onChange={(field) => {
                  setStage(field.value);
                  reset();
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                value={tag}
                onChange={(field) => {
                  setTag(field.value);
                }}
                id={'tag'}
                description={'TAG'}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldControl
                value={refId}
                onChange={(field) => {
                  setRefId(field.value);
                }}
                id={'refId'}
                description={'Reference task id'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldControl
                value={sourceLink}
                onChange={(field) => {
                  setSourceLink(field.value);
                }}
                id={'gdrive_link'}
                description={'Gdrive link'}
              />
            </Grid>
            <Grid item xs={3}>
              <CustomSelect
                description="Identity"
                value={selectedIdentity}
                onChange={(event) => {
                  setSelectedIdentity(event.target.value);
                }}
                disabled={identities!.isLoading}
                data={identities!.entities}
                renderValue={(value) =>
                  value && value.name
                    ? `${value.name}_${value.version}`
                    : 'None'
                }
                displayText={(item) => `${item.name}_${item.version}`}
              />
            </Grid>
            <Grid
              item
              xs={0.4}
              style={{
                paddingTop: '28px',
              }}
            >
              {identities?.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <IconButton
                  onClick={() => {
                    dispatch(loadEntities('identity'));
                  }}
                >
                  <CachedIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={3}>
              <CustomSelect
                data={studios!.entities}
                value={selectedStudio}
                onChange={(event) => {
                  setSelectedStudio(event.target.value);
                }}
                disabled={studios!.isLoading}
                description="Studio"
                renderValue={(value) =>
                  value && value.name ? `${value.name}` : 'None'
                }
                displayText={(item) => `${item.name}`}
              />
            </Grid>
            <Grid
              item
              xs={0.4}
              style={{
                paddingTop: '28px',
              }}
            >
              {studios?.isLoading ? (
                <CircularProgress size={24} />
              ) : (
                <IconButton
                  onClick={() => {
                    dispatch(loadEntities('studio'));
                  }}
                >
                  <CachedIcon />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={3}>
              <SelectControl
                id={'pose'}
                value={selectedPose}
                items={
                  selectedIdentity !== ''
                    ? Object.keys(selectedIdentity.poses)
                    : []
                }
                description={'Pose'}
                disabled={false}
                canBeEmpty={true}
                onChange={(field) => {
                  setSelectedPose(field.value);
                }}
              />
            </Grid>
            {Object.keys(configuration!.services).map((serviceName) => {
              let display = ['inpaint'].includes(serviceName) ? 'none' : '';
              if (requires.includes(serviceName))
                return (
                  <Grid
                    item
                    xs={12}
                    key={serviceName}
                    style={{ display: display }}
                  >
                    <AccordionControl
                      id={`${serviceName}Inputs`}
                      expanded={getAccordionState(serviceName)}
                      name={`${serviceName.toUpperCase()} inputs`}
                      description={''}
                      onChange={() => {
                        changeAccordionState(serviceName);
                      }}
                    >
                      {serviceName === 'fit' && (
                        <FitInputs
                          ref={inputRefs.fit}
                          setManual={updateManualState}
                        />
                      )}
                      {serviceName === 'segmentation' && (
                        <SegmentationInputs
                          ref={inputRefs.segmentation}
                          setManual={updateManualState}
                        />
                      )}
                      {serviceName === 'render' && (
                        <RenderInputs
                          ref={inputRefs.render}
                          setManual={updateManualState}
                        />
                      )}
                      {serviceName === 'ml' && (
                        <MlInputs
                          ref={inputRefs.ml}
                          setManual={updateManualState}
                          sourceLink={sourceLink}
                        />
                      )}
                      {serviceName === 'inpaint' && (
                        <InpaintInputs
                          ref={inputRefs.inpaint}
                          setManual={updateManualState}
                        />
                      )}
                    </AccordionControl>
                  </Grid>
                );
              else return <div key={serviceName}></div>;
            })}
            <Grid item xs={12}>
              <BottomNavigation showLabels>
                <BottomNavigationAction
                  label="Reset"
                  icon={<RotateLeftIcon />}
                  onClick={reset}
                />
                <BottomNavigationAction
                  label="Create"
                  icon={<AddIcon />}
                  onClick={createTask}
                  disabled={identities?.isLoading || studios?.isLoading}
                />
              </BottomNavigation>
            </Grid>
          </Grid>
        </RegisterContainer>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const Wrapper = styled.div``;

const RegisterContainer = styled.div`
  padding: 1%;
  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const LoadingContainer = styled.div`
  min-height: 775px;
  display: grid;
`;

export default CreateTask;
