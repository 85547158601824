import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import TasksTable from '../../Tables/TasksTable';
import SelectControl from '../../Controls/SelectControl';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { deleteTasks, loadTask } from '../../../stores/tasks';
import { RootState } from '../../../stores/types';
import { useSearchParams } from 'react-router-dom';

const checkingStates: string[] = ['base', 'polishing', 'done'];

const Tasks = () => {
  const [searchParams] = useSearchParams();
  const [checkingState, setCheckingState] = useState(
    searchParams.get('refId') ? 'polishing' : 'base',
  );
  const dispatch = useAppDispatch();
  const tasksStore = useAppSelector((state: RootState) => state.tasksReducer);
  const tasks = tasksStore.task;

  const reloadTasks = (extend?: boolean) => {
    dispatch(
      loadTask({
        lastKey: extend ? tasks?.lastKey : null,
        type: 'task',
      }),
    );
  };

  const removeTasks = async (taskIds: string[]) => {
    await dispatch(deleteTasks({ taskIds: taskIds, type: 'task' }));
  };

  useEffect(() => {
    if (tasks === null) reloadTasks();
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header name={'Tasks'} />
      <TasksContainer>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <SelectControl
              id={'checkingState'}
              value={checkingState}
              items={checkingStates}
              description={'Checking state'}
              disabled={false}
              canBeEmpty={false}
              onChange={(field) => {
                setCheckingState(field.value);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TasksTable
              data={tasks ? Object.values(tasks.tasks) : []}
              reloadData={(extend?: boolean) => reloadTasks(extend)}
              loading={tasks?.isLoading || false}
              deleteTasks={removeTasks}
              moreDataExist={!!tasks?.lastKey}
              checkingState={checkingState}
            />
          </Grid>
        </Grid>
      </TasksContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const TasksContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

export default Tasks;
