import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import { Alert, Grid, IconButton, Typography } from '@mui/material';
import SelectControl from '../../Controls/SelectControl';
import EntityTable from '../../Tables/EntityTable';
import { EntityType } from '../../../configs';
import {
  addNewEntity,
  delEntity,
  loadEntities,
  updatedEntity,
} from '../../../stores/entities';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import axios from 'axios';
import MannequinSystemsApi from '../../../api/MannequinSystemsApi';
import { enqueueSnackbar } from 'notistack';
import Modal from '@mui/material/Modal';
import LoadingIcon from '../../Controls/LoadingIcon';
import DialogControl from '../../Controls/DialowWindow';
import { UploadFile } from '../../Controls/SelectFiles';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldControl from '../../Controls/TextFieldControl';

const Entities = () => {
  const [selectedEntityType, setSelectedEntityType] = useState(
    EntityType.IDENTITY,
  );
  const dispatch = useAppDispatch();
  const entitiesStore = useAppSelector(
    (state: RootState) => state.entitiesReducer,
  );
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const entities = entitiesStore[selectedEntityType.key];

  const [archiveToUpload, setArchiveToUpload] = useState<File | null>(null);
  const [newIdentity, setNewIdentity] = useState<any>({});
  const [dialogOpened, setDialogOpened] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (selectedEntityType && entities === null)
      dispatch(loadEntities(selectedEntityType.key));
    // eslint-disable-next-line
  }, [selectedEntityType]);

  const deleteEntity = async (entitiesIds: string[]) => {
    await dispatch(
      delEntity({
        key: selectedEntityType.key,
        entitiesIds: entitiesIds,
      }),
    );
  };

  const createNewEntity = async (newEntityData: any) => {
    newEntityData['type'] = selectedEntityType.displayName;
    await dispatch(
      addNewEntity({
        key: selectedEntityType.key,
        newEntityData: newEntityData,
        userName: userData!.userName,
      }),
    );
  };

  const editEntity = async (editData: any) => {
    await dispatch(
      updatedEntity({
        key: selectedEntityType.key,
        entityData: editData,
        userName: userData!.userName,
      }),
    );
  };

  const processArchive = () => {
    if (archiveToUpload) {
      if (newIdentity.name.length === 0 || newIdentity.version.length === 0) {
        enqueueSnackbar(`Name and version should be filled.`, {
          variant: 'warning',
        });
        return;
      }
      let fileName = archiveToUpload.name.replaceAll(' ', '_');
      setIsProcessing(true);
      getUploadUrl(fileName).then((uploadUrlData) => {
        axios
          .put(uploadUrlData.presignedUrl, archiveToUpload)
          .then(async () => {
            await MannequinSystemsApi.registerIdentity(
              userData!.userName,
              uploadUrlData.s3_path,
              newIdentity.name,
              newIdentity.gender,
              newIdentity.version,
            )
              .then((response) => {
                if (response.status !== 200) {
                  setArchiveToUpload(null);
                  setIsProcessing(false);
                  setErrorMessage(
                    `Failed to register identity. Error: ${response.statusText}`,
                  );
                } else if (response.data.errorMessage) {
                  setArchiveToUpload(null);
                  setIsProcessing(false);
                  setErrorMessage(
                    `Failed to register identity. Error: ${response.data.errorMessage}`,
                  );
                } else {
                  setIsProcessing(false);
                  setDialogOpened(false);
                  dispatch(loadEntities(EntityType.IDENTITY.key));
                  enqueueSnackbar(
                    `${newIdentity.name}_${newIdentity.version} registered.`,
                    {
                      variant: 'success',
                    },
                  );
                }
              })
              .catch((error) => {
                setArchiveToUpload(null);
                setIsProcessing(false);
                setErrorMessage(`Failed to register identity. Error: ${error}`);
              });
          })
          .catch((error) => {
            setErrorMessage(`Failed to register identity. Error: ${error}`);
            setIsProcessing(false);
            setArchiveToUpload(null);
          });
      });
    }
  };

  const updateIdentity = (paramKey: string, value: string) => {
    setNewIdentity((prevState: any) => ({ ...prevState, [paramKey]: value }));
  };

  const getUploadUrl = async (fileName: string) => {
    const response = await MannequinSystemsApi.getUploadUrl(
      fileName,
      'identity',
    );
    if (response.status === 200 && response.data) {
      return response.data;
    }
  };

  return (
    <Container>
      <Modal open={isProcessing}>
        <LoadingIcon />
      </Modal>
      <DialogControl
        isOpen={dialogOpened}
        title={`Upload new head design`}
        description={''}
        setIsOpen={setDialogOpened}
        approveAction={processArchive}
        onCloseAction={() => {
          setErrorMessage('');
        }}
      >
        <ArchiveWrapper style={{ width: '700px', height: '400px' }}>
          {errorMessage.length > 0 && (
            <ErrorWrapper>
              <Alert severity="error">{errorMessage}</Alert>
            </ErrorWrapper>
          )}
          <FileSelectWrapper>
            <UploadFile
              setFilesToUpload={(files: File[]) => {
                setErrorMessage('');
                updateIdentity(
                  'name',
                  files[0].name
                    .replaceAll(' ', '_')
                    .split('.')
                    .slice(0, -1)
                    .join('.'),
                );
                setArchiveToUpload(files[0]);
              }}
              accept={{
                'application/zip': ['.zip'],
              }}
              multiple={false}
            />
          </FileSelectWrapper>
          {archiveToUpload && (
            <ZipContainer>
              <Grid container spacing={1}>
                <Grid item xs={11}>
                  <ArchiveDetails>
                    <Typography fontWeight={700}>
                      {archiveToUpload.name}
                    </Typography>
                    <Typography fontWeight={300}>
                      {(archiveToUpload.size / 1048576).toFixed(2)} MB
                    </Typography>
                  </ArchiveDetails>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={() => setArchiveToUpload(null)}>
                    <DeleteIcon fontSize={'medium'} />
                  </IconButton>
                </Grid>
                <Grid item xs={1} />
                <Grid
                  container
                  item
                  spacing={1}
                  xs={11}
                  sx={{ alignItems: 'center' }}
                >
                  <Grid item xs={2}>
                    <Typography>Name</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldControl
                      value={newIdentity.name}
                      onChange={(field) => {
                        updateIdentity('name', field.value);
                      }}
                      id={''}
                      description={''}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={2}>
                    <Typography>Version</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldControl
                      value={newIdentity.version}
                      onChange={(field) => {
                        updateIdentity('version', field.value);
                      }}
                      id={''}
                      description={''}
                    />
                  </Grid>
                  <Grid item xs={4} />
                  <Grid item xs={2}>
                    <Typography>Gender</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <SelectControl
                      items={['woman', 'man']}
                      id={''}
                      value={newIdentity.gender}
                      description={''}
                      onChange={(field) => {
                        updateIdentity('gender', field.value);
                      }}
                      canBeEmpty={false}
                    />
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </Grid>
            </ZipContainer>
          )}
        </ArchiveWrapper>
      </DialogControl>
      <Header name={'Entities'} />
      <RendersContainer>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <SelectControl
              id={'entityType'}
              value={selectedEntityType.displayName}
              items={Object.values(EntityType).map(
                (entity) => entity.displayName,
              )}
              description={'Entity Type'}
              disabled={false}
              canBeEmpty={false}
              onChange={(field) => {
                for (const key of Object.keys(
                  EntityType,
                ) as (keyof typeof EntityType)[]) {
                  const entity = EntityType[key];
                  if (entity.displayName === field.value) {
                    setSelectedEntityType(entity);
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <EntityTable
              key={selectedEntityType.key}
              data={entities ? Object.values(entities.entities) : []}
              reloadData={() => dispatch(loadEntities(selectedEntityType.key))}
              loading={entities?.isLoading || false}
              addNewEntity={createNewEntity}
              editEntity={editEntity}
              deleteEntities={deleteEntity}
              entityType={selectedEntityType}
              createEntity={
                selectedEntityType === EntityType.IDENTITY
                  ? () => {
                      setNewIdentity({
                        gender: 'woman',
                        name: '',
                        version: 'v1',
                      });
                      setArchiveToUpload(null);
                      setDialogOpened(true);
                    }
                  : null
              }
            />
          </Grid>
        </Grid>
      </RendersContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const RendersContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const ArchiveWrapper = styled.div`
  width: 550px;
  height: 250px;
`;

const FileSelectWrapper = styled.div`
  height: 150px;
  border: 2px dashed rgba(55, 55, 55, 0.58);
  border-radius: 10px;
  text-align: center;
`;

const ZipContainer = styled.div`
  margin-top: 2%;
  border-radius: 10px;
  border: 2px solid rgba(55, 55, 55, 0.58);
  align-items: center;
  padding: 2%;
`;

const ArchiveDetails = styled.div`
  padding-left: 2%;
`;

const ErrorWrapper = styled.div`
  padding-bottom: 1%;
`;

export default Entities;
