import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  ListItemText,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import ButtonControl from '../Controls/ButtonControl';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SelectControl from '../Controls/SelectControl';
import ControlPanel from '../Settings/controlPanel';
import TextFieldControl from '../Controls/TextFieldControl';
import DialogControl from '../Controls/DialowWindow';
import MannequinSystemsApi from '../../api/MannequinSystemsApi';
import {
  SecurityDataDto,
  TabLabel,
} from '../../api/types/GetSecurityDataResponseDto';
import { enqueueSnackbar } from 'notistack';
import Modal from '@mui/material/Modal';
import LoadingIcon from '../Controls/LoadingIcon';
import { ContentCopy } from '@mui/icons-material';
import { Change } from '../../configs';
import ConfirmChangesModal from '../Settings/confirmChangesModal';

export interface SecurityChanges {
  created: Change[];
  deleted: Change[];
  changed: Change[];
  defaults: Change[];
  reset_password: string[];
}

const securityTabLabels: Record<TabLabel, number> = {
  Default: 0,
  Users: 1,
  Groups: 2,
};

const Security = () => {
  const [currentTab, setCurrentTab] = useState<TabLabel>('Default');
  const [securityData, setSecurityData] = useState<SecurityDataDto>();
  const [groups, setGroups] = useState(securityData?.groups || {});
  const [users, setUsers] = useState(securityData?.users || {});
  const [permissions, setPermissions] = useState(
    securityData?.permissions || [],
  );
  const [password, setPassword] = useState(securityData?.defaultUserPassword);
  const [selectedGroup, setSelectedGroup] = useState(
    Object.keys(groups || '')[0],
  );
  const [selectedUser, setSelectedUser] = useState(Object.keys(users)[0]);
  const [usersForResetPassword, setUsersForResetPassword] = useState<string[]>(
    [],
  );

  const [dialogOpened, setDialogOpened] = useState(false);
  const [newObjectName, setNewObjectName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const changeSections = {
    created: [],
    deleted: [],
    changed: [],
    defaults: [],
    reset_password: [],
  };
  const [changes, setChanges] = useState<SecurityChanges>(changeSections);

  const getSecurityData = async () => {
    setIsLoading(true);
    const response = await MannequinSystemsApi.getSecurityData();
    if (response.status === 200 && response.data) {
      resetModal(response.data.securityData);
      setSecurityData(response.data.securityData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!securityData) getSecurityData();
    // eslint-disable-next-line
  }, []);

  const handleSave = async () => {
    setIsModalOpen(false);
    setIsLoading(true);
    const response = await MannequinSystemsApi.updateSecurityData(changes);
    if (response.status === 200) {
      enqueueSnackbar('Security data saved successfully', {
        variant: 'success',
      });
      generateCopyMessages();
      setChanges(changeSections);
      await getSecurityData();
      setUsersForResetPassword([]);
      setIsModalOpen(false);
      setIsLoading(false);
    }
  };

  const messageText = (username: Change | string, password: any) => {
    enqueueSnackbar(`Username: ${username} - Password: ${password}`, {
      variant: 'info',
      action: (
        <Tooltip title="Copy to clipboard">
          <IconButton
            color="inherit"
            onClick={() =>
              navigator.clipboard.writeText(
                `Username: ${username}, Password: ${password}`,
              )
            }
          >
            <ContentCopy />
          </IconButton>
        </Tooltip>
      ),
    });
  };

  const generateCopyMessages = () => {
    (['created', 'reset_password'] as Array<keyof SecurityChanges>).forEach(
      (changeType) => {
        const changesByType = changes[changeType];
        if (changeType === 'reset_password') {
          changesByType.forEach((username) => {
            messageText(username, password!);
          });
        } else {
          Object.values(changesByType).forEach((user) => {
            if (user.objectSection === 'user') {
              messageText(user.objectName, password!);
            }
          });
        }
      },
    );
  };

  const resetModal = (data: SecurityDataDto) => {
    setGroups(data.groups || {});
    setUsers(data.users || {});
    setPermissions(data.permissions || []);
    setPassword(data.defaultUserPassword || '');
    setSelectedGroup(Object.keys(data.groups)[0]);
    setSelectedUser(Object.keys(data.users)[0]);
    setUsersForResetPassword([]);
    setChanges(changeSections);
  };

  const updateData = (setter: any, objectKey: string, params: any) => {
    setter((prev: any) => {
      const newObjects = { ...prev };
      const newObject = {
        ...newObjects[objectKey],
      };
      Object.keys(params).forEach((paramKey) => {
        newObject[paramKey] = params[paramKey];
      });
      newObjects[objectKey] = newObject;
      return newObjects;
    });
  };

  const handleDeleteObject = (deleteObject: any) => {
    if (currentTab === 'Users') {
      setUsers((prevUsers) => {
        const newUsers = { ...prevUsers };
        delete newUsers[deleteObject.name];
        return newUsers;
      });
      const newSelectedItem =
        Object.keys(users).find((i) => i !== deleteObject.name) || '';
      setSelectedUser(newSelectedItem);
    } else if (currentTab === 'Groups') {
      const usedIn = Object.keys(users).filter((key) =>
        users[key].groups.includes(deleteObject.name),
      );
      usedIn.forEach((userName) => {
        const updatedGroups = users[userName].groups.filter(
          (group: string) => group !== deleteObject.name,
        );
        updateData(setUsers, userName, { groups: updatedGroups });
      });
      setGroups((prevGroups) => {
        const newGroups = { ...prevGroups };
        delete newGroups[deleteObject.name];
        return newGroups;
      });
      const newSelectedItem =
        Object.keys(groups).find((i) => i !== deleteObject.name) || '';
      setSelectedGroup(newSelectedItem);
    } else {
      const usedIn = Object.keys(groups).filter((key) =>
        groups[key].permissions.includes(deleteObject),
      );
      usedIn.forEach((groupName) => {
        const updatedPermissions = groups[groupName].permissions.filter(
          (permission: string) => permission !== deleteObject,
        );
        updateData(setGroups, groupName, { permissions: updatedPermissions });
      });
      setPermissions(
        permissions.filter((permission) => permission !== deleteObject),
      );
    }
  };

  const handleApproveAction = () => {
    if (currentTab === 'Users') {
      if (Object.keys(users).includes(newObjectName.trim())) {
        enqueueSnackbar('User with this name already exists.', {
          variant: 'warning',
        });
      } else {
        setUsers((pervUsers: any) => {
          return {
            ...pervUsers,
            [newObjectName]: {
              name: newObjectName,
              groups: [],
            },
          };
        });
        setSelectedUser(newObjectName);
        setNewObjectName('');
        setDialogOpened(false);
      }
    } else if (currentTab === 'Groups') {
      if (Object.keys(groups).includes(newObjectName.trim())) {
        enqueueSnackbar('Group with this name already exists.', {
          variant: 'warning',
        });
      } else {
        setGroups((pervGroups: any) => {
          return {
            ...pervGroups,
            [newObjectName]: {
              name: newObjectName,
              permissions: [],
            },
          };
        });
        setSelectedGroup(newObjectName);
        setNewObjectName('');
        setDialogOpened(false);
      }
    } else {
      if (permissions.includes(newObjectName.trim())) {
        enqueueSnackbar('Permission with this name already exists.', {
          variant: 'warning',
        });
      } else {
        setPermissions([...permissions, newObjectName]);
        setNewObjectName('');
        setDialogOpened(false);
      }
    }
  };

  const handleSaveButtonClick = () => {
    const changesDetected = detectChanges();
    if (
      changesDetected.created.length === 0 &&
      changesDetected.deleted.length === 0 &&
      changesDetected.changed.length === 0 &&
      changesDetected.defaults.length === 0 &&
      changesDetected.reset_password.length === 0
    ) {
      enqueueSnackbar('No changes to save', { variant: 'info' });
    } else {
      setChanges(changesDetected);
      setIsModalOpen(true);
    }
  };

  const detectChanges = () => {
    const newChanges: SecurityChanges = changeSections;

    let objectTypes: any = {
      user: {
        oldValues: securityData!.users,
        newValues: users,
      },
      group: {
        oldValues: securityData!.groups,
        newValues: groups,
      },
    };

    if (password !== securityData!.defaultUserPassword) {
      newChanges.defaults.push({
        objectSection: 'security',
        objectName: 'Default user password',
        oldValue: { default_user_password: securityData!.defaultUserPassword },
        newValue: { default_user_password: password },
      });
    }

    usersForResetPassword.forEach((userName) => {
      newChanges.reset_password.push(userName);
    });

    if (permissions !== securityData!.permissions) {
      newChanges.defaults.push({
        objectSection: 'security',
        objectName: 'Permissions',
        oldValue: { permissions: securityData!.permissions },
        newValue: { permissions: permissions },
      });
    }

    Object.keys(objectTypes).forEach((type) => {
      let typeData = objectTypes[type];
      let oldKeys = Object.keys(typeData.oldValues);
      let newKeys = Object.keys(typeData.newValues);
      newKeys.forEach((objectKey) => {
        if (!oldKeys.includes(objectKey))
          newChanges.created.push({
            objectSection: type,
            objectName: objectKey,
            newValue: typeData.newValues[objectKey],
          });
        else {
          let newObject: any = typeData.newValues[objectKey];
          let oldObject: any = typeData.oldValues[objectKey];
          if (JSON.stringify(newObject) !== JSON.stringify(oldObject)) {
            const originals: Partial<Record<string, any>> = {};
            const changes: Partial<Record<string, any>> = {};
            for (const key in oldObject) {
              if (
                JSON.stringify(oldObject[key]) !==
                JSON.stringify(newObject[key])
              ) {
                changes[key] = newObject[key];
                originals[key] = oldObject[key];
              }
            }
            newChanges.changed.push({
              objectSection: type,
              objectName: objectKey,
              oldValue: originals,
              newValue: changes,
            });
          }
        }
      });

      oldKeys.forEach((objectKey) => {
        if (!newKeys.includes(objectKey))
          newChanges.deleted.push({
            objectSection: type,
            objectName: objectKey,
          });
      });
    });
    return newChanges;
  };

  return (
    <Container>
      <Modal open={isLoading}>
        <LoadingIcon />
      </Modal>
      {isModalOpen && (
        <ConfirmChangesModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onApprove={handleSave}
          changes={changes}
        />
      )}
      <DialogControl
        isOpen={dialogOpened}
        title={``}
        description={'Please enter name'}
        setIsOpen={setDialogOpened}
        approveAction={handleApproveAction}
        onCloseAction={() => {
          setNewObjectName('');
        }}
        approveButtonDisabled={newObjectName.trim().length === 0}
      >
        <NewObjectName>
          <TextFieldControl
            value={newObjectName}
            onChange={(field) => {
              setNewObjectName(field.value.toLowerCase());
            }}
            id={''}
            description={''}
          />
        </NewObjectName>
      </DialogControl>
      <Header name={'Security'} />
      <SecurityContainer>
        <TabsContainer>
          <SecurityTabsContainer
            orientation="vertical"
            variant="scrollable"
            value={securityTabLabels[currentTab]}
            onChange={(_, newValue: number) => {
              const newTab = Object.keys(securityTabLabels).find(
                (key) => securityTabLabels[key as TabLabel] === newValue,
              ) as TabLabel | undefined;

              if (newTab) {
                setCurrentTab(newTab);
              }
            }}
            aria-label="Vertical tabs example"
            textColor="inherit"
          >
            <Tab label="Default" />
            <Tab label="Users" />
            <Tab label="Groups" />
          </SecurityTabsContainer>
          <div>
            <TabsChildrenContainer
              style={{ display: currentTab === 'Default' ? '' : 'none' }}
            >
              <Wrapper>
                <Grid container display="flex">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontSize: 'x-large', textAlign: 'center' }}
                      >
                        Default
                      </Typography>
                    </Grid>
                    <Grid item xs={1.75}>
                      <FormHelperText sx={{ paddingTop: '30px' }}>
                        Default password
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldControl
                        value={password!}
                        onChange={(field) => {
                          setPassword(field.value);
                        }}
                        id={''}
                        description={' '}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={1.75}>
                      <FormHelperText sx={{ paddingTop: '12px' }}>
                        Permissions
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={10.25}
                      sx={{ paddingBottom: '12px', paddingTop: '5px' }}
                    >
                      <IconButton
                        disabled={false}
                        onClick={() => setDialogOpened(true)}
                      >
                        <AddCircleIcon fontSize="inherit" />
                      </IconButton>
                    </Grid>
                    {Object.values(permissions).map((permission, index) => (
                      <Grid
                        container
                        key={permission}
                        sx={{
                          paddingBottom: '5px',
                        }}
                      >
                        <Grid item xs={1.75} />
                        <Grid
                          item
                          xs={4}
                          sx={{
                            paddingTop: '10px',
                          }}
                        >
                          <Typography key={index}>{permission}</Typography>
                        </Grid>
                        <Grid item xs={0.75} style={{ textAlign: 'center' }}>
                          <IconButton
                            disabled={false}
                            onClick={() => {
                              handleDeleteObject(permission);
                            }}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Wrapper>
            </TabsChildrenContainer>
            <TabsChildrenContainer
              style={{ display: currentTab === 'Users' ? '' : 'none' }}
            >
              <Wrapper>
                <Grid container display="flex">
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        sx={{ fontSize: 'x-large', textAlign: 'center' }}
                      >
                        Users
                      </Typography>
                    </Grid>
                    <Grid item xs={1.75}>
                      <FormHelperText sx={{ paddingTop: '30px' }}>
                        User
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={4}>
                      {Object.keys(users).length > 0 && (
                        <SelectControl
                          items={Object.keys(users)}
                          id={''}
                          value={selectedUser || ''}
                          description={' '}
                          onChange={(field) => setSelectedUser(field.value)}
                          canBeEmpty={false}
                        />
                      )}
                    </Grid>
                    <Grid item xs={0.25} />
                    <Grid item xs={1.25}>
                      <FormControl>
                        <FormHelperText> </FormHelperText>
                        <ButtonControl
                          description="Create"
                          onClick={() => setDialogOpened(true)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1.25}>
                      <FormControl>
                        <FormHelperText> </FormHelperText>
                        <ButtonControl
                          description="Delete"
                          onClick={() =>
                            handleDeleteObject(users[selectedUser])
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <FormHelperText> </FormHelperText>
                        <ButtonControl
                          description="Reset password"
                          onClick={() => {
                            setUsersForResetPassword((prev) => {
                              const userName = users[selectedUser].name;
                              if (prev.includes(userName)) {
                                return prev;
                              }
                              return [...prev, userName];
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={1.75}>
                      <FormHelperText sx={{ paddingTop: '12px' }}>
                        Last activity
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      <Typography>
                        {users[selectedUser]?.last_activity ?? `-`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={1.75}>
                      <FormHelperText sx={{ paddingTop: '12px' }}>
                        Groups
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={4.1}
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      {users[selectedUser] ? (
                        Object.keys(groups).map((group) => (
                          <Grid item xs={12} key={group}>
                            <Grid
                              item
                              xs={12}
                              sx={{ display: 'flex' }}
                              key={group}
                            >
                              <Switch
                                id={'groups'}
                                onChange={(event) => {
                                  const userGroups = event.target.checked
                                    ? [...users[selectedUser].groups, group]
                                    : users[selectedUser].groups.filter(
                                        (groUser) => groUser !== group,
                                      );
                                  updateData(setUsers, selectedUser, {
                                    groups: userGroups,
                                  });
                                }}
                                checked={
                                  Array.isArray(users[selectedUser].groups) &&
                                  users[selectedUser].groups.includes(group)
                                }
                              />
                              <ListItemText primary={group} />
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Wrapper>
            </TabsChildrenContainer>
            <TabsChildrenContainer
              style={{ display: currentTab === 'Groups' ? '' : 'none' }}
            >
              <Wrapper>
                <Grid container display="flex">
                  {Object.keys(groups).length === 0 ? (
                    <Grid item xs={1}>
                      <FormControl>
                        <FormHelperText> </FormHelperText>
                        <ButtonControl
                          description="Create"
                          onClick={() => setDialogOpened(true)}
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <ControlPanel
                      value={selectedGroup || ''}
                      items={Object.keys(groups)}
                      onChange={(field) => {
                        setSelectedGroup(field.value);
                      }}
                      onCreate={() => setDialogOpened(true)}
                      onDelete={() => handleDeleteObject(groups[selectedGroup])}
                      name="Groups"
                      description="Groups"
                    />
                  )}
                  <Grid container>
                    <Grid item xs={1.75}>
                      <FormHelperText sx={{ paddingTop: '12px' }}>
                        Permissions
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      xs={4.1}
                      sx={{
                        paddingTop: '10px',
                      }}
                    >
                      {groups[selectedGroup] ? (
                        Object.values(permissions).map((permission) => (
                          <Grid item xs={12} key={permission}>
                            <Grid
                              item
                              xs={12}
                              sx={{ display: 'flex' }}
                              key={permission}
                            >
                              <Switch
                                id={'permissions'}
                                onChange={(event) => {
                                  const groupPermissions = event.target.checked
                                    ? [
                                        ...groups[selectedGroup].permissions,
                                        permission,
                                      ]
                                    : groups[selectedGroup].permissions.filter(
                                        (perGroup) => perGroup !== permission,
                                      );
                                  updateData(setGroups, selectedGroup, {
                                    permissions: groupPermissions,
                                  });
                                }}
                                checked={
                                  Array.isArray(
                                    groups[selectedGroup].permissions,
                                  ) &&
                                  groups[selectedGroup].permissions.includes(
                                    permission,
                                  )
                                }
                              />
                              <ListItemText primary={permission} />
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Wrapper>
            </TabsChildrenContainer>
            <div>
              <Grid container>
                <Grid item xs={5} />
                <Grid item xs={1}>
                  <ButtonControl
                    onClick={handleSaveButtonClick}
                    description="Save"
                    size="large"
                  />
                </Grid>
                <Grid item xs={0.25} />
                <Grid item xs={1}>
                  <ButtonControl
                    description="Reset"
                    onClick={() => resetModal(securityData!)}
                    size="large"
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </TabsContainer>
      </SecurityContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const SecurityContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

const TabsContainer = styled.div`
  display: flex;
  min-height: 500px;
  max-height: max-content;
  width: 100%;
`;

const SecurityTabsContainer = styled(Tabs)`
  padding-right: 2px;
  border-right: 1px solid rgba(71, 71, 71, 0.46);
  width: 20%;
`;

const TabsChildrenContainer = styled.div`
  width: 1100px;
  margin-left: 1%;
`;

const Wrapper = styled.div`
  min-height: 500px;
  margin-bottom: 10px;
`;

const NewObjectName = styled.div`
  width: 400px;
`;

export default Security;
