import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MannequinSystemsApi from '../../api/MannequinSystemsApi';
import { enqueueSnackbar } from 'notistack';

interface EntitiesData {
  entities: { [index: string]: any };
  isLoading: boolean;
}

const initialState: Record<string, EntitiesData | null> = {
  identity: null,
  studio: null,
};

export const loadEntities = createAsyncThunk(
  'loadEntities',
  async (key: string) => {
    const response = await MannequinSystemsApi.getEntities(key);
    return response.data;
  },
);

export const addNewEntity = createAsyncThunk(
  'addNewEntity',
  async ({
    key,
    newEntityData,
    userName,
  }: {
    key: string;
    newEntityData: any;
    userName: string;
  }) => {
    const response = await MannequinSystemsApi.createEntity(
      key,
      newEntityData,
      userName,
    );
    return response.data;
  },
);

export const updatedEntity = createAsyncThunk(
  'updatedEntity',
  async ({
    key,
    entityData,
    userName,
  }: {
    key: string;
    entityData: any;
    userName: string;
  }) => {
    const responseEdite = await MannequinSystemsApi.editEntity(
      key,
      entityData,
      userName,
    );
    return responseEdite.data;
  },
);

export const delEntity = createAsyncThunk(
  'delEntity',
  async ({ key, entitiesIds }: { key: string; entitiesIds: string[] }) => {
    const responseDel = await MannequinSystemsApi.deleteEntity(
      key,
      entitiesIds,
    );
    return responseDel.data;
  },
);

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(loadEntities.pending, (state, action) => {
        const key = action.meta.arg;
        if (!state[key]) {
          state[key] = {
            entities: {},
            isLoading: false,
          };
        }
        state[key]!.isLoading = true;
      })
      .addCase(loadEntities.fulfilled, (state, action) => {
        const key = action.meta.arg;
        state[key]!.isLoading = false;
        if (action.payload.statusCode === 200) {
          state[key]!.entities = Object.fromEntries(
            action.payload.entities.map((obj: any) => [obj.id, obj]),
          );
        } else {
          enqueueSnackbar(
            'Failed to load entities. Error:' + action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(loadEntities.rejected, (state, action) => {
        const key = action.meta.arg;
        state[key]!.isLoading = false;
        enqueueSnackbar(
          'Failed to load entities. Error:' + action.error.message || 'null',
          { variant: 'error' },
        );
      })
      .addCase(addNewEntity.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          const { key } = action.meta.arg;
          const entity = action.payload.entity;
          state[key]!.entities = {
            [entity.id]: entity,
            ...state[key],
          };
        } else {
          enqueueSnackbar(
            'Failed to create new entity.' + action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(addNewEntity.rejected, (state, action) => {
        const { key } = action.meta.arg;
        state[key]!.isLoading = false;
        enqueueSnackbar(
          'Failed to create new entity.' + action.error.message || 'null',
          { variant: 'error' },
        );
      })
      .addCase(updatedEntity.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          const { key } = action.meta.arg;
          const entity = action.payload.entity;
          state[key]!.entities![entity.id] = entity;
        } else {
          enqueueSnackbar(
            'Failed to update entity.' + action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(updatedEntity.rejected, (state, action) => {
        const { key } = action.meta.arg;
        state[key]!.isLoading = false;
        enqueueSnackbar(
          'Failed to update entity.' + action.error.message || 'null',
          { variant: 'error' },
        );
      })
      .addCase(delEntity.fulfilled, (state, action) => {
        if (action.payload.statusCode === 200) {
          const { key } = action.meta.arg;
          action.payload.ids.forEach((entityId: string) => {
            delete state[key]!.entities[entityId];
          });
        } else {
          enqueueSnackbar(
            'Failed to delete entities.' + action.payload.errorMessage,
            { variant: 'error' },
          );
        }
      })
      .addCase(delEntity.rejected, (state, action) => {
        const { key } = action.meta.arg;
        state[key]!.isLoading = false;
        enqueueSnackbar(
          'Failed to delete entities.' + action.error.message || 'null',
          { variant: 'error' },
        );
      });
  },
});

export default entitiesSlice.reducer;
