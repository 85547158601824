import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../../Header';
import { TaskType } from '../../../configs';
import SelectControl from '../../Controls/SelectControl';
import { Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { loadTask } from '../../../stores/tasks';
import { RootState } from '../../../stores/types';
import JobsTable from '../../Tables/JobsTable';

const Tasks = () => {
  const [selectedTasksType, setSelectedTasksType] = useState(TaskType.INPAINT);
  const dispatch = useAppDispatch();
  const tasksStore = useAppSelector((state: RootState) => state.tasksReducer);
  const tasks = tasksStore[selectedTasksType.key];

  const loadJobs = (extend?: boolean) => {
    dispatch(
      loadTask({
        lastKey: extend ? tasks?.lastKey : null,
        type: selectedTasksType.key,
      }),
    );
  };

  useEffect(() => {
    if (selectedTasksType && tasks === null)
      dispatch(
        loadTask({
          lastKey: null,
          type: selectedTasksType.key,
        }),
      );
    // eslint-disable-next-line
  }, [selectedTasksType]);

  return (
    <Container>
      <Header name={'Jobs'} />
      <TasksContainer>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            <SelectControl
              id={'tasksType'}
              value={selectedTasksType.displayName}
              items={Object.values(TaskType).map((tasks) => tasks.displayName)}
              description={'Jobs type'}
              disabled={false}
              canBeEmpty={false}
              onChange={(field) => {
                for (const key of Object.keys(
                  TaskType,
                ) as (keyof typeof TaskType)[]) {
                  const tasks = TaskType[key];
                  if (tasks.displayName === field.value) {
                    setSelectedTasksType(tasks);
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <JobsTable
              key={selectedTasksType.key}
              data={tasks ? Object.values(tasks.tasks) : []}
              reloadData={loadJobs}
              loading={tasks?.isLoading || false}
              tasksType={selectedTasksType}
              moreDataExist={!!tasks?.lastKey}
            />
          </Grid>
        </Grid>
      </TasksContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 1440px;
`;

const TasksContainer = styled.div`
  padding: 1%;

  border-bottom: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: 10px;
`;

export default Tasks;
