import SelectControl from '../components/Controls/SelectControl';
import TextFieldControl from '../components/Controls/TextFieldControl';
import AccordionControl from '../components/Controls/AccordionControl';
import SkinColorControl from '../components/Controls/SkinColorControl';
import CheckBoxControl from '../components/Controls/CheckBoxControl';
import SliderControl from '../components/Controls/SliderControl';

export const COGNITO = {
  REGION: 'us-west-2',
  USER_POOL_ID: 'us-west-2_R4ebY1lSA',
  APP_CLIENT_ID: '7idrde9qb6t66asbhc3cpnp1ja',
};

export type AnyObject = {
  [key: string]: any;
};

export const Components: AnyObject = {
  select: SelectControl,
  textField: TextFieldControl,
  accordion: AccordionControl,
  skinColor: SkinColorControl,
  checkBox: CheckBoxControl,
  slider: SliderControl,
};

export interface HandleType {
  name: string;
  value: any;
}

export type DynamicComponentProps = {
  type: string;
  data: AnyObject;
  gridWrap?: boolean;
  sx?: number;
  children?: DynamicComponentProps[];
};

export const EntityType = Object.freeze({
  IDENTITY: { key: 'identity', displayName: 'Identity' },
  STUDIO: { key: 'studio', displayName: 'Studio' },
});

// eslint-disable-next-line
export type EntityType = typeof EntityType[keyof typeof EntityType];

export const TaskType = Object.freeze({
  FIT: { key: 'fit', displayName: 'Fit' },
  SEGMENTATION: { key: 'segmentation', displayName: 'Segmentation' },
  RENDER: { key: 'render', displayName: 'Render' },
  ML: { key: 'ml', displayName: 'ML' },
  INPAINT: { key: 'inpaint', displayName: 'Inpaint' },
  RECONSTRUCTION: { key: 'reconstruction', displayName: 'Reconstruction' },
});

// eslint-disable-next-line
export type TaskType = typeof TaskType[keyof typeof TaskType];

export interface Change {
  objectSection: string;
  objectName: string;
  oldValue?: any;
  newValue?: any;
}
