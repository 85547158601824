import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { ContentCopy } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { updateTableSettings } from '../../../stores/users';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import styled from 'styled-components';
import { TableSettings } from '../../../api/types/GetUserDataResponseDto';

interface PhotoshootsTableTableProps {
  data: any[];
  studios: Map<string, string>;
  moreDataExist: boolean;
  reloadData: (fetchMore?: boolean) => void;
  uploadPhotoshoot: () => void;
  loading: boolean;
  deleteTasks: (taskIds: string[]) => void;
}

const defaultTableSettings: TableSettings = {
  columnVisibility: {
    id: false,
    created_by: false,
    created_at: false,
  },
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  density: 'compact',
  columnSize: {},
};

const PhotoshootsTable = (props: PhotoshootsTableTableProps) => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const tableSettings = useAppSelector(
    (state: RootState) =>
      state.usersReducer.tableSettings?.['photoshoots'] ?? defaultTableSettings,
  );
  const [currentTableSettings, setCurrentTableSettings] =
    useState<TableSettings>(tableSettings);
  const [savedPageIndex, setSavedPageIndex] = useState<number | null>(null);
  const data = useMemo(() => props.data, [props.data]);
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [showProgressBars, setShowProgressBars] = useState(false);
  const isLoading = useMemo(() => props.loading, [props.loading]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => {
      const baseColumns: MRT_ColumnDef<any>[] = [
        {
          accessorKey: 'id', //access nested data with dot notation
          header: 'ID',
          size: 280,
        },
        {
          accessorKey: 'name',
          header: 'Name',
          size: 200,
        },
        {
          accessorKey: 'source_link', //normal accessorKey
          header: 'Source link',
          size: 400,
          enableClickToCopy: true,
        },
        {
          accessorKey: 'studio', //normal accessorKey
          header: 'Studio',
          size: 180,
          Cell: ({ cell }) => (
            <div style={{ height: '100%' }}>
              {props.studios.get(cell.getValue<string>())}
            </div>
          ),
        },
        {
          accessorKey: 'created_at',
          size: 220,
          accessorFn: (originalRow) => new Date(originalRow.created_at),
          header: 'Created at',
          filterVariant: 'date',
          filterFn: 'equals',
          muiFilterDatePickerProps: {
            format: 'DD/MM/YYYY',
          },
          enableGlobalFilter: false,
          Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
        },
        {
          accessorKey: 'created_by', //normal accessorKey
          header: 'Created by',
          size: 180,
        },
        {
          accessorKey: 'state',
          header: 'State',
          size: 170,
          filterVariant: 'multi-select',
          filterSelectOptions: ['created', 'processing', 'finished', 'failed'],
        },
        {
          accessorKey: 'result_link',
          header: 'Result',
          size: 120,
          enableColumnFilter: false,
          Cell: ({ cell }) => (
            <Box>
              {cell.getValue<string>() && (
                <Link
                  href={cell.getValue<string>()}
                  style={{ cursor: 'pointer', color: 'black' }}
                  target="_blank"
                >
                  Browse
                </Link>
              )}
            </Box>
          ),
          enableClickToCopy: true,
          muiCopyButtonProps: {
            fullWidth: true,
            startIcon: <ContentCopy />,
            sx: { justifyContent: 'flex-start' },
          },
          enableGlobalFilter: false,
        },
        {
          accessorKey: 'aruco_link',
          header: 'Aruco link',
          size: 150,
          enableColumnFilter: false,
          Cell: ({ cell }) => (
            <Box>
              {cell.getValue<string>() && (
                <Link
                  href={cell.getValue<string>()}
                  style={{ cursor: 'pointer', color: 'black' }}
                >
                  download aruco data
                </Link>
              )}
            </Box>
          ),
          enableGlobalFilter: false,
        },
        {
          accessorKey: 'error',
          header: 'Error',
          size: 200,
          enableClickToCopy: true,
          Cell: ({ cell }) => (
            <Box sx={{ textWrap: 'nowrap' }} title={cell.getValue<string>()}>
              {cell.getValue<string>()}
            </Box>
          ),
        },
      ];
      return baseColumns;
    },
    // eslint-disable-next-line
    [props],
  );

  const updateCurrentTableSettings = <K extends keyof TableSettings>(
    key: K,
    value: TableSettings[K] | ((old: TableSettings[K]) => TableSettings[K]),
  ) => {
    setCurrentTableSettings((prev) => ({
      ...prev,
      [key]:
        typeof value === 'function'
          ? (value as (old: TableSettings[K]) => TableSettings[K])(prev[key])
          : value,
    }));
  };

  const handleTableSettingsChange = async (settings: TableSettings) => {
    setShowProgressBars(true);
    await dispatch(
      updateTableSettings({
        userName: userData!.userName,
        tableName: 'photoshoots',
        tableSettings: settings,
      }),
    );
    setShowProgressBars(false);
  };

  const resetTableSettings = () => {
    setCurrentTableSettings(tableSettings);
  };

  const showTableSettingsSaveResetButtons =
    currentTableSettings.pagination.pageSize !==
      tableSettings.pagination?.pageSize ||
    JSON.stringify(currentTableSettings.columnSize) !==
      JSON.stringify(tableSettings.columnSize) ||
    JSON.stringify(currentTableSettings.columnVisibility) !==
      JSON.stringify(tableSettings.columnVisibility) ||
    currentTableSettings.density !== tableSettings.density;

  const handleSaveTableSettingsChanges = () => {
    const updatedSettings: TableSettings = {
      ...tableSettings,
      ...currentTableSettings,
    };
    if (JSON.stringify(updatedSettings) !== JSON.stringify(tableSettings)) {
      setCurrentTableSettings(updatedSettings);
      handleTableSettingsChange(updatedSettings);
    }
  };

  useEffect(() => {
    if (
      (currentTableSettings.pagination.pageIndex + 1) *
        currentTableSettings.pagination.pageSize >
        props.data.length &&
      props.data.length > 0 &&
      props.moreDataExist
    ) {
      setSavedPageIndex(currentTableSettings.pagination.pageIndex);
      props.reloadData(true);
    }
    // eslint-disable-next-line
  }, [currentTableSettings.pagination]);

  useEffect(() => {
    if (savedPageIndex !== null && props.data.length > 0) {
      Promise.resolve().then(() => {
        updateCurrentTableSettings('pagination', (prevPagination) => ({
          ...prevPagination,
          pageIndex: savedPageIndex,
        }));
      });
      setSavedPageIndex(null);
    }
    // eslint-disable-next-line
  }, [props.data]);

  const openDeleteConfirmModal = async () => {
    setShowProgressBars(true);
    if (window.confirm('Are you sure you want to delete these photoshoots?')) {
      await props.deleteTasks(Object.keys(rowSelection));
      setShowProgressBars(false);
    } else {
      setShowProgressBars(false);
    }
  };

  useEffect(() => {
    setRowSelection({});
  }, [props.data]);

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: props.moreDataExist ? props.data.length + 1 : props.data.length,
    getRowId: (row) => row.id,
    positionToolbarAlertBanner: 'bottom',
    muiTableContainerProps: {
      sx: {
        minHeight: '405px',
      },
    },
    renderTopToolbarCustomActions: () => (
      <TopToolbarContainer>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => props.reloadData()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Delete rows">
          <span>
            <IconButton
              onClick={openDeleteConfirmModal}
              disabled={Object.keys(rowSelection).length === 0}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip arrow title="Upload photoshoot">
          <span>
            <IconButton
              onClick={() => props.uploadPhotoshoot()}
              disabled={props.studios.size === 0}
            >
              <AddIcon />
            </IconButton>
          </span>
        </Tooltip>
        {showTableSettingsSaveResetButtons && (
          <TableSettingsButtonsContainer>
            <Typography
              variant="body1"
              color={'rgb(1, 67, 97)'}
              style={{
                paddingRight: '25px',
              }}
            >
              Table structure changes detected
            </Typography>
            <Button onClick={handleSaveTableSettingsChanges}>
              Save changes
            </Button>
            <Button onClick={resetTableSettings}>Reset changes</Button>
          </TableSettingsButtonsContainer>
        )}
      </TopToolbarContainer>
    ),
    muiTopToolbarProps: showTableSettingsSaveResetButtons
      ? {
          sx: {
            backgroundColor: 'rgb(229, 246, 253)',
          },
        }
      : undefined,
    state: {
      isLoading,
      pagination: currentTableSettings.pagination,
      showProgressBars,
      rowSelection,
      columnVisibility: currentTableSettings.columnVisibility,
      density: currentTableSettings.density,
      columnSizing: currentTableSettings.columnSize,
    },
    onPaginationChange: (updaterOrValue) => {
      updateCurrentTableSettings('pagination', updaterOrValue);
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnVisibility', updaterOrValue);
    },
    onColumnSizingChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnSize', updaterOrValue);
    },
    onDensityChange: (updaterOrValue) => {
      updateCurrentTableSettings('density', updaterOrValue);
    },
    enableColumnResizing: true,
    enableRowSelection: (row) =>
      row.original.state !== 'processing' && row.original.state !== 'created',
    onRowSelectionChange: setRowSelection,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} />
    </LocalizationProvider>
  );
};

const TopToolbarContainer = styled.div`
  display: flex;
`;

const TableSettingsButtonsContainer = styled.div`
  display: flex;
  padding-left: 100px;
  align-items: center;
`;

export default PhotoshootsTable;
