import createApiClient from './ApiClient';
import { AxiosResponse } from 'axios';
import { GetConfigurationResponseDto } from './types/GetConfigurationResponseDto';
import { GetSecurityDataResponseDto } from './types/GetSecurityDataResponseDto';
import { GetWorkersDataResponseDto } from './types/GetWorkersDataResponseDto';
import { GetUserDataResponseDto } from './types/GetUserDataResponseDto';

const routes = {
  getUploadUrl: () => 'url/msGetUploadUrl',

  getConfiguration: () => '/gc/msGetConfiguration',
  updateConfiguration: () => '/uc/msUpdateConfiguration',

  savePreset: () => '/sp/msSavePreset',
  removePreset: () => '/rp/msRemovePreset',

  createTask: () => '/ct/msCreateTask',
  setTaskCheckingState: () => '/scs/msSetTaskCheckingState',
  getTasks: () => `/gt/msGetTasks`,
  deleteTasks: () => `/dt/msDeleteTask`,

  getEntities: () => `/ge/msGetEntities`,
  createEntity: () => `/ce/msCreateEntity`,
  editEntity: () => `/ee/msEditEntity`,
  deleteEntity: () => `/de/msDeleteEntities`,

  scheduleHeadDesign: () => `/shd/msScheduleHeadDesign`,

  schedulePhotoshoot: () => `/sph/msSchedulePhotoshoot`,

  registerIdentity: () => `/ri/msRegisterIdentity`,

  scheduleRuns: () => `/sr/msScheduleRuns`,

  getUserData: () => `/gud/msGetUserData`,

  updateTableSettings: () => `/uts/msUpdateTableSettings`,
  getSecurityData: () => `/gsd/msGetSecurityData`,
  updateSecurityData: () => `/usd/msUpdateSecurityData`,

  callWorkerApi: () => '/cwa/msCallWorkersApi',
};

export default class MannequinSystemsApi {
  private static client = createApiClient();

  public static getUploadUrl(
    fileName: string | null = null,
    type: string | null = null,
  ) {
    let request = {
      file_name: fileName,
      type: type,
    };
    return this.client.post(routes.getUploadUrl(), request);
  }

  public static getConfiguration(): Promise<
    AxiosResponse<GetConfigurationResponseDto>
  > {
    return this.client.get(routes.getConfiguration());
  }

  public static updateConfiguration(changes: {}) {
    const request = {
      changes: changes,
    };
    return this.client.post(routes.updateConfiguration(), request);
  }

  public static savePreset(name: string, data: {}, pipelineVersion: string) {
    const request = {
      name: name,
      data: data,
      pipeline_version: pipelineVersion,
    };
    return this.client.post(routes.savePreset(), request);
  }

  public static removePreset(presetName: string) {
    const request = {
      preset_name: presetName,
    };
    return this.client.post(routes.removePreset(), request);
  }

  public static async createTask(
    taskData: Object,
    stage: string,
    userName: string,
  ) {
    const request = {
      ...taskData,
      ...{
        stage: stage,
        created_by: userName,
        type: 'task',
      },
    };
    return this.client.post(routes.createTask(), request);
  }

  public static setTaskCheckingState(taskIds: string[], checkingState: string) {
    const request = {
      task_ids: taskIds,
      checking_state: checkingState,
    };
    return this.client.post(routes.setTaskCheckingState(), request);
  }

  public static getTasks(lastKey: any | null, taskType: string) {
    const request = {
      start_from: lastKey,
      task_type: taskType,
    };
    return this.client.post(routes.getTasks(), request);
  }
  public static deleteTasks(taskIds: string[], taskType: string) {
    const request = {
      task_ids: taskIds,
      task_type: taskType,
    };
    return this.client.post(routes.deleteTasks(), request);
  }

  public static getEntities(entityType: string) {
    const request = {
      entity_type: entityType,
    };
    return this.client.post(routes.getEntities(), request);
  }

  public static createEntity(entityType: string, data: any, userName: string) {
    const request = {
      entity_type: entityType,
      created_by: userName,
      entity: data,
    };
    return this.client.post(routes.createEntity(), request);
  }

  public static editEntity(
    entityType: string,
    entityData: any,
    userName: string,
  ) {
    const request = {
      entity_type: entityType,
      entity_data: entityData,
      modified_dy: userName,
    };
    return this.client.post(routes.editEntity(), request);
  }

  public static deleteEntity(entityType: string, entitiesIds: string[]) {
    const request = {
      entity_type: entityType,
      entity_ids: entitiesIds,
    };
    return this.client.post(routes.deleteEntity(), request);
  }

  public static async scheduleHeadDesign(
    userName: string,
    s3Path: string,
    fileName: string,
    gender: string,
    sd_prompt: string,
  ) {
    const request = {
      created_by: userName,
      s3_path: s3Path,
      name: fileName,
      gender: gender,
      sd_prompt: sd_prompt,
    };
    return this.client.post(routes.scheduleHeadDesign(), request);
  }

  public static async schedulePhotoshoot(
    userName: string,
    s3Path: string,
    fileName: string,
    studio: string,
  ) {
    const request = {
      created_by: userName,
      s3_path: s3Path,
      name: fileName,
      studio: studio,
    };
    return this.client.post(routes.schedulePhotoshoot(), request);
  }

  public static async registerIdentity(
    userName: string,
    s3Path: string,
    name: string,
    gender: string,
    version: string,
  ) {
    const request = {
      created_by: userName,
      s3_path: s3Path,
      name: name,
      gender: gender,
      version: version,
    };
    return this.client.post(routes.registerIdentity(), request);
  }

  public static async scheduleRuns(
    userName: string,
    photoshootId: string,
    identityId: string,
    poses: string[],
    hairDynamic: boolean,
    manualHeadFit: boolean,
    manualRender: boolean,
  ) {
    const request = {
      created_by: userName,
      photoshoot_id: photoshootId,
      identity_id: identityId,
      poses: poses,
      hair_dynamic: hairDynamic,
      manual_head_fit: manualHeadFit,
      manual_render: manualRender,
    };
    return this.client.post(routes.scheduleRuns(), request);
  }

  public static async getUserData(
    userName: string,
  ): Promise<AxiosResponse<GetUserDataResponseDto>> {
    const request = {
      userName: userName,
    };
    return this.client.post(routes.getUserData(), request);
  }

  public static async getSecurityData(): Promise<
    AxiosResponse<GetSecurityDataResponseDto>
  > {
    return this.client.post(routes.getSecurityData());
  }

  public static updateSecurityData(changes: {}) {
    const request = {
      changes: changes,
    };
    return this.client.post(routes.updateSecurityData(), request);
  }

  public static async callWorkerApi(
    serverAddress: string,
    apiMethod: string,
    method: string = 'GET',
    params: any = {},
  ): Promise<AxiosResponse<GetWorkersDataResponseDto>> {
    const request = {
      server_address: serverAddress,
      api_method: apiMethod,
      http_method: method,
      ...params,
    };
    return this.client.post(routes.callWorkerApi(), request);
  }

  public static async updateTableSettings(
    userName: string,
    tableName: string,
    tableSettings: {},
  ) {
    const request = {
      userName: userName,
      tableName: tableName,
      tableSettings: tableSettings,
    };
    return this.client.post(routes.updateTableSettings(), request);
  }
}
