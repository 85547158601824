import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import SettingsIcon from '@mui/icons-material/Settings';
import { useAppSelector } from '../../stores/hooks';
import { RootState } from '../../stores/types';
import HttpsIcon from '@mui/icons-material/Https';
import StorageIcon from '@mui/icons-material/Storage';

interface HeaderProps {
  name: string;
}

const Header = (props: HeaderProps) => {
  const { userData, isMaintenanceModeOn } = useAppSelector(
    (state: RootState) => state.usersReducer,
  );
  const [anchorElAccount, setAnchorElAccount] =
    React.useState<null | HTMLElement>(null);
  const [anchorElNavigation, setAnchorElNavigation] =
    React.useState<null | HTMLElement>(null);
  const openAccount = Boolean(anchorElAccount);
  const openNavigation = Boolean(anchorElNavigation);
  const listNavigationPages = [
    'photoshoots',
    'runs',
    'tasks',
    'create_task',
    'head_designer',
    'jobs',
    'entities',
  ];

  const handleClickAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAccount(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAnchorElAccount(null);
  };
  const handleClickNavigation = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorElNavigation(event.currentTarget);
  };
  const handleCloseNavigation = () => {
    setAnchorElNavigation(null);
  };

  const showNavigation = () => {
    if (userData === null) return false;
    else if (
      !listNavigationPages.some((page) => userData.permissions.includes(page))
    )
      return false;
    else if (
      isMaintenanceModeOn &&
      !userData.permissions.includes('maintenance_mode')
    )
      return false;
    return true;
  };

  const showSettings = () => {
    if (userData === null) return false;
    else if (!userData.permissions.includes('settings')) return false;
    else if (
      isMaintenanceModeOn &&
      !userData.permissions.includes('maintenance_mode') &&
      userData.permissions.includes('settings')
    )
      return false;
    return true;
  };

  const signOut = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    Auth.signOut().then(() => window.location.reload());
  };
  return (
    <Container>
      <HeaderWrapper>
        {showNavigation() ? (
          <Navigation>
            <Button
              id="basic-button"
              color={'inherit'}
              aria-controls={openNavigation ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openNavigation ? 'true' : undefined}
              onClick={handleClickNavigation}
            >
              {props.name}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElNavigation}
              open={openNavigation}
              onClose={handleCloseNavigation}
              onClick={handleCloseNavigation}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem component={Link} to="/">
                Home
              </MenuItem>
              {userData!.permissions.includes('photoshoots') && (
                <MenuItem component={Link} to="/photoshoots">
                  Photoshoots
                </MenuItem>
              )}
              {userData!.permissions.includes('runs') && (
                <div>
                  <MenuItem component={Link} to="/runs">
                    Runs
                  </MenuItem>
                  <Divider />
                </div>
              )}
              {userData!.permissions.includes('create_task') && (
                <MenuItem component={Link} to="/createTask">
                  Create task
                </MenuItem>
              )}
              {userData!.permissions.includes('head_designer') && (
                <div>
                  <MenuItem component={Link} to="/headDesigner">
                    Head Designer
                  </MenuItem>
                  <Divider />
                </div>
              )}
              {userData!.permissions.includes('tasks') && (
                <MenuItem component={Link} to="/tasks">
                  Tasks
                </MenuItem>
              )}
              {userData!.permissions.includes('jobs') && (
                <div>
                  <MenuItem component={Link} to="/jobs">
                    Jobs
                  </MenuItem>
                  <Divider />
                </div>
              )}
              {userData!.permissions.includes('entities') && (
                <MenuItem component={Link} to="/entities">
                  Entities
                </MenuItem>
              )}
            </Menu>
          </Navigation>
        ) : (
          <Navigation />
        )}

        <Typography
          variant="h4"
          gutterBottom
          component={Link}
          to="/"
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          Mannequin Systems
        </Typography>
        <Account>
          <Box>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClickAccount}
                size="large"
                aria-controls={openAccount ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openAccount ? 'true' : undefined}
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {userData!.userName[0].toUpperCase()}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorElAccount}
            id="account-menu"
            open={openAccount}
            onClose={handleCloseAccount}
            onClick={handleCloseAccount}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={signOut}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
            {userData!.permissions.includes('workers') && (
              <MenuItem component={Link} to="/workers">
                <ListItemIcon>
                  <StorageIcon fontSize={'small'} />
                </ListItemIcon>
                Workers
              </MenuItem>
            )}
            {showSettings() && (
              <MenuItem component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon fontSize={'small'} />
                </ListItemIcon>
                Settings
              </MenuItem>
            )}
            {userData!.permissions.includes('security') && (
              <MenuItem component={Link} to="/security">
                <ListItemIcon>
                  <HttpsIcon fontSize={'small'} />
                </ListItemIcon>
                Security
              </MenuItem>
            )}
          </Menu>
        </Account>
      </HeaderWrapper>
      <LineBreaker />
    </Container>
  );
};

const Container = styled.div``;

const Navigation = styled.div`
  flex: 1;
`;

const HeaderWrapper = styled.div`
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Account = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const LineBreaker = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  border-bottom: 1px solid black;
`;

export default Header;
