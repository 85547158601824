import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  useMaterialReactTable,
} from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Button, IconButton, Tooltip, Typography } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { TaskType } from '../../../configs';
import { getJobsColumnsByType, getJobsFiltersByType } from '../Configuration';
import { ColumnFilter } from '@tanstack/table-core/src/features/Filters';
import { useSearchParams } from 'react-router-dom';
import { updateTableSettings } from '../../../stores/users';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { RootState } from '../../../stores/types';
import styled from 'styled-components';
import { TableSettings } from '../../../api/types/GetUserDataResponseDto';

interface JobsTableProps {
  data: any[];
  moreDataExist: boolean;
  reloadData: (fetchMore?: boolean) => void;
  loading: boolean;
  tasksType: TaskType;
}

const defaultTableSettings: TableSettings = {
  columnVisibility: {
    task_id: false,
    created_at: false,
    started_at: false,
    finished_at: false,
    processing_time: false,
    outputs_path: false,
    worker_id: false,
    error: false,
  },
  pagination: {
    pageIndex: 0,
    pageSize: 10,
  },
  density: 'compact',
  columnSize: {},
};

const JobsTable = (props: JobsTableProps) => {
  const dispatch = useAppDispatch();
  const { userData } = useAppSelector((state: RootState) => state.usersReducer);
  const data = useMemo(() => props.data, [props.data]);
  const tableSettings = useAppSelector(
    (state: RootState) =>
      state.usersReducer.tableSettings?.['jobs'] ?? defaultTableSettings,
  );
  const [currentTableSettings, setCurrentTableSettings] =
    useState<TableSettings>(tableSettings);
  const [savedPageIndex, setSavedPageIndex] = useState<number | null>(null);
  const [showProgressBars, setShowProgressBars] = useState(false);

  const isLoading = useMemo(() => props.loading, [props.loading]);
  const [searchParams] = useSearchParams();
  const initFilters = useMemo(() => {
    let filter: ColumnFilter[] = [];
    searchParams.forEach((value, key) => {
      if (getJobsFiltersByType(props.tasksType).includes(key)) {
        let tempValue = key === 'state' ? value.split(',') : value;
        filter.push({ id: key, value: tempValue });
      }
    });
    return filter;
    // eslint-disable-next-line
  }, [searchParams]);
  const [columnFilters, setColumnFilters] =
    useState<ColumnFilter[]>(initFilters);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => {
      return getJobsColumnsByType(props.tasksType);
    },
    // eslint-disable-next-line
    [props],
  );

  const handleTableSettingsChange = async (settings: TableSettings) => {
    setShowProgressBars(true);
    await dispatch(
      updateTableSettings({
        userName: userData!.userName,
        tableName: 'jobs',
        tableSettings: settings,
      }),
    );
    setShowProgressBars(false);
  };

  const resetTableSettings = () => {
    setCurrentTableSettings(tableSettings);
  };

  const showTableSettingsSaveResetButtons =
    currentTableSettings.pagination.pageSize !==
      (tableSettings.pagination?.pageSize ?? 10) ||
    JSON.stringify(currentTableSettings.columnSize) !==
      JSON.stringify(tableSettings.columnSize ?? {}) ||
    JSON.stringify(currentTableSettings.columnVisibility) !==
      JSON.stringify(
        tableSettings.columnVisibility ?? {
          id: false,
          created_by: false,
          created_at: false,
        },
      ) ||
    currentTableSettings.density !== (tableSettings.density ?? 'compact');

  const updateCurrentTableSettings = <K extends keyof TableSettings>(
    key: K,
    value: TableSettings[K] | ((old: TableSettings[K]) => TableSettings[K]),
  ) => {
    setCurrentTableSettings((prev) => ({
      ...prev,
      [key]:
        typeof value === 'function'
          ? (value as (old: TableSettings[K]) => TableSettings[K])(prev[key])
          : value,
    }));
  };

  const handleSaveTableSettingsChanges = () => {
    const updatedSettings: TableSettings = {
      ...tableSettings,
      ...currentTableSettings,
    };
    if (JSON.stringify(updatedSettings) !== JSON.stringify(tableSettings)) {
      setCurrentTableSettings(updatedSettings);
      handleTableSettingsChange(updatedSettings);
    }
  };

  useEffect(() => {
    if (
      (currentTableSettings.pagination.pageIndex + 1) *
        currentTableSettings.pagination.pageSize >
        props.data.length &&
      props.data.length > 0 &&
      props.moreDataExist
    ) {
      setSavedPageIndex(currentTableSettings.pagination.pageIndex);
      props.reloadData(true);
    }
    // eslint-disable-next-line
  }, [currentTableSettings.pagination]);

  useEffect(() => {
    if (savedPageIndex !== null && props.data.length > 0) {
      Promise.resolve().then(() => {
        updateCurrentTableSettings('pagination', (prevPagination) => ({
          ...prevPagination,
          pageIndex: savedPageIndex,
        }));
      });
      setSavedPageIndex(null);
    }
    // eslint-disable-next-line
  }, [props.data]);

  const table = useMaterialReactTable({
    columns,
    data,
    rowCount: props.moreDataExist ? props.data.length + 1 : props.data.length,
    getRowId: (row) => row.id,
    onColumnFiltersChange: setColumnFilters,
    positionToolbarAlertBanner: 'bottom',
    muiTableContainerProps: {
      sx: {
        minHeight: '405px',
      },
    },
    renderTopToolbarCustomActions: () => (
      <TopToolbarContainer>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => props.reloadData()}>
            <CachedIcon />
          </IconButton>
        </Tooltip>
        {showTableSettingsSaveResetButtons && (
          <TableSettingsButtonsContainer>
            <Typography
              variant="body1"
              color={'rgb(1, 67, 97)'}
              style={{
                paddingRight: '25px',
              }}
            >
              Table structure changes detected
            </Typography>
            <Button onClick={handleSaveTableSettingsChanges}>
              Save changes
            </Button>
            <Button onClick={resetTableSettings}>Reset changes</Button>
          </TableSettingsButtonsContainer>
        )}
      </TopToolbarContainer>
    ),
    muiTopToolbarProps: showTableSettingsSaveResetButtons
      ? {
          sx: {
            backgroundColor: 'rgb(229, 246, 253)',
          },
        }
      : undefined,
    state: {
      isLoading,
      showProgressBars,
      pagination: currentTableSettings.pagination,
      columnFilters,
      columnVisibility: currentTableSettings.columnVisibility,
      density: currentTableSettings.density,
      columnSizing: currentTableSettings.columnSize,
    },
    onPaginationChange: (updaterOrValue) => {
      updateCurrentTableSettings('pagination', updaterOrValue);
    },
    onColumnVisibilityChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnVisibility', updaterOrValue);
    },
    onColumnSizingChange: (updaterOrValue) => {
      updateCurrentTableSettings('columnSize', updaterOrValue);
    },
    onDensityChange: (updaterOrValue) => {
      updateCurrentTableSettings('density', updaterOrValue);
    },
    enableColumnResizing: true,
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MaterialReactTable table={table} key={props.tasksType.key} />
    </LocalizationProvider>
  );
};

const TopToolbarContainer = styled.div`
  display: flex;
`;

const TableSettingsButtonsContainer = styled.div`
  display: flex;
  padding-left: 100px;
  align-items: center;
`;

export default JobsTable;
