import { MRT_ColumnDef } from 'material-react-table';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import React, { useState } from 'react';
import { EntityType, TaskType } from '../../../configs';
import { ContentCopy } from '@mui/icons-material';
import Modal from '@mui/material/Modal';

export const getEntitiesColumnsByType = (entityType: EntityType) => {
  if (entityType === EntityType.IDENTITY) return identityColumns;
  else if (entityType === EntityType.STUDIO) return studioColumns;
  return [];
};

export const getJobsColumnsByType = (tasksType: TaskType) => {
  if (
    [
      TaskType.ML,
      TaskType.RENDER,
      TaskType.INPAINT,
      TaskType.SEGMENTATION,
      TaskType.FIT,
      TaskType.RECONSTRUCTION,
    ].includes(tasksType)
  )
    return jobColumns;
  return [];
};

export const getJobsFiltersByType = (tasksType: TaskType) => {
  if (
    [
      TaskType.ML,
      TaskType.RENDER,
      TaskType.INPAINT,
      TaskType.SEGMENTATION,
      TaskType.FIT,
      TaskType.RECONSTRUCTION,
    ].includes(tasksType)
  )
    return ['id'];
  return [];
};

const validateNewEntity = (value: string) => !!value?.length;

export function validateEntity(data: any, entityType: EntityType) {
  if (entityType === EntityType.IDENTITY)
    return {
      name: !validateNewEntity(data.name) ? 'Name is Required' : '',
      version: !validateNewEntity(data.version) ? 'Version is Required' : '',
      gender: !validateNewEntity(data.gender) ? 'Gender is Required' : '',
      link: !validateNewEntity(data.path) ? 'Path is Required' : '',
    };
  else if (entityType === EntityType.STUDIO)
    return {
      name: !validateNewEntity(data.name) ? 'Name is Required' : '',
      calibration_matrix: !validateNewEntity(data.calibration_matrix)
        ? 'Calibration matrix is Required'
        : '',
      distortion_coefficients: !validateNewEntity(data.distortion_coefficients)
        ? 'Distortion coefficients are Required'
        : '',
    };
  return {};
}

const identityColumns: MRT_ColumnDef<any>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 250,
    enableEditing: false,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    size: 180,
    muiEditTextFieldProps: {
      required: true,
    },
    Cell: ({ cell, row }) => {
      const [fullScreenImage, setFullScreenImage] = useState('');
      const [showFullScreen, setShowFullScreen] = useState(false);

      return (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <img
              alt="avatar"
              height={50}
              src={row.original.img}
              loading="lazy"
              style={{ borderRadius: '50%', cursor: 'zoom-in' }}
              onClick={() => {
                setFullScreenImage(row.original.img);
                setShowFullScreen(true);
              }}
            />
            <span>{cell.getValue<string>()}</span>
          </Box>
          <Modal
            open={showFullScreen}
            onClose={() => setShowFullScreen(false)}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
              }}
            >
              <img
                src={fullScreenImage}
                alt="img"
                onClick={() => setShowFullScreen(false)}
                style={{
                  maxHeight: '80%',
                  cursor: 'zoom-out',
                }}
              />
            </Box>
          </Modal>
        </>
      );
    },
  },
  {
    accessorKey: 'version',
    header: 'Version',
    size: 155,
    muiEditTextFieldProps: {
      required: true,
    },
  },
  {
    accessorKey: 'gender',
    header: 'Gender',
    size: 175,
    filterVariant: 'multi-select',
    filterSelectOptions: ['man', 'woman'],
    editVariant: 'select',
    editSelectOptions: ['man', 'woman'],
    muiEditTextFieldProps: {
      required: true,
    },
  },
  {
    accessorKey: 'path',
    header: 'Path',
    size: 200,
    muiEditTextFieldProps: {
      required: true,
    },
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableClickToCopy: true,
  },
  {
    accessorKey: 'poses', //normal accessorKey
    header: 'Poses',
    size: 200,
    enableClickToCopy: true,
    Cell: ({ cell }) => {
      let value: any = Object.keys(cell.getValue<any>()).join(', ');
      return (
        <Box
          title={value}
          sx={{
            textWrap: 'nowrap',
            width: '300px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Box>
      );
    },
    enableEditing: false,
    enableGlobalFilter: false,
  },
];

const studioColumns: MRT_ColumnDef<any>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 250,
    enableEditing: false,
  },
  {
    accessorKey: 'name',
    header: 'Name',
    size: 150,
    muiEditTextFieldProps: {
      required: true,
    },
  },
  {
    accessorKey: 'calibration_matrix',
    header: 'Calibration matrix',
    size: 350,
    muiEditTextFieldProps: {
      required: true,
    },
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableClickToCopy: true,
  },
  {
    accessorKey: 'distortion_coefficients',
    header: 'Distortion coefficients',
    size: 350,
    muiEditTextFieldProps: {
      required: true,
    },
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableClickToCopy: true,
  },
];

export const taskColumns: MRT_ColumnDef<any>[] = [
  {
    accessorKey: 'id', //access nested data with dot notation
    header: 'ID',
    size: 280,
  },
  {
    accessorKey: 'source_link', //normal accessorKey
    header: 'Source link',
    size: 600,
    enableClickToCopy: true,
  },
  {
    accessorKey: 'created_at',
    size: 220,
    accessorFn: (originalRow) => new Date(originalRow.created_at),
    header: 'Created at',
    filterVariant: 'date',
    filterFn: 'equals',
    muiFilterDatePickerProps: {
      format: 'DD/MM/YYYY',
    },
    enableGlobalFilter: false,
    Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
  },
  {
    accessorKey: 'created_by', //normal accessorKey
    header: 'Created by',
    size: 180,
  },
  {
    accessorKey: 'segmentation_data', //normal accessorKey
    header: 'Segmentation configs',
    size: 200,
    enableClickToCopy: true,
    Cell: ({ cell }) => {
      let value =
        cell.getValue<string>() === '{}' ? '' : cell.getValue<string>();
      return (
        <Box
          title={value}
          sx={{
            textWrap: 'nowrap',
            width: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Box>
      );
    },
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'render_data', //normal accessorKey
    header: 'Render configs',
    size: 200,
    enableClickToCopy: true,
    Cell: ({ cell }) => {
      let value =
        cell.getValue<string>() === '{}' ? '' : cell.getValue<string>();
      return (
        <Box
          title={value}
          sx={{
            textWrap: 'nowrap',
            width: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Box>
      );
    },
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'ml_data', //normal accessorKey
    header: 'ML configs',
    size: 200,
    enableClickToCopy: true,
    Cell: ({ cell }) => {
      let value =
        cell.getValue<string>() === '{}' ? '' : cell.getValue<string>();
      return (
        <Box
          title={value}
          sx={{
            textWrap: 'nowrap',
            width: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Box>
      );
    },
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'inpaint_data', //normal accessorKey
    header: 'Inpaint configs',
    size: 200,
    enableClickToCopy: true,
    Cell: ({ cell }) => {
      let value =
        cell.getValue<string>() === '{}' ? '' : cell.getValue<string>();
      return (
        <Box
          title={value}
          sx={{
            textWrap: 'nowrap',
            width: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {value}
        </Box>
      );
    },
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'state',
    header: 'State',
    size: 170,
    filterVariant: 'multi-select',
    filterSelectOptions: ['created', 'processing', 'finished', 'failed'],
  },
  {
    accessorKey: 'result_link',
    header: 'Result',
    size: 120,
    enableColumnFilter: false,
    Cell: ({ cell }) => (
      <Box>
        {cell.getValue<string>() && (
          <Link
            href={cell.getValue<string>()}
            style={{ cursor: 'pointer', color: 'black' }}
            target="_blank"
          >
            Browse
          </Link>
        )}
      </Box>
    ),
    enableClickToCopy: true,
    muiCopyButtonProps: {
      fullWidth: true,
      startIcon: <ContentCopy />,
      sx: { justifyContent: 'flex-start' },
    },
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'error',
    header: 'Error',
    size: 200,
    enableClickToCopy: true,
    Cell: ({ cell }) => (
      <Box sx={{ textWrap: 'nowrap' }} title={cell.getValue<string>()}>
        {cell.getValue<string>()}
      </Box>
    ),
  },
];

const jobColumns: MRT_ColumnDef<any>[] = [
  {
    accessorKey: 'task_id', //access nested data with dot notation
    header: 'Task ID',
    size: 260,
  },
  {
    accessorKey: 'id', //access nested data with dot notation
    header: 'ID',
    size: 260,
  },
  {
    accessorKey: 'created_at',
    size: 160,
    accessorFn: (originalRow) => new Date(originalRow.created_at),
    header: 'Created at',
    filterVariant: 'date',
    filterFn: 'greaterThan',
    enableGlobalFilter: false,
    Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
  },
  {
    accessorKey: 'source_link', //normal accessorKey
    header: 'Source link',
    size: 600,
    enableClickToCopy: true,
  },
  {
    accessorKey: 'state',
    header: 'State',
    size: 150,
    filterVariant: 'multi-select',
    filterSelectOptions: ['created', 'processing', 'finished', 'failed'],
  },
  {
    accessorKey: 'started_at',
    size: 220,
    accessorFn: (originalRow) => new Date(originalRow.started_at),
    header: 'Started at',
    filterVariant: 'date',
    filterFn: 'greaterThan',
    enableGlobalFilter: false,
    Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
  },
  {
    accessorKey: 'finished_at',
    size: 220,
    accessorFn: (originalRow) => new Date(originalRow.finished_at),
    header: 'Finished at',
    filterVariant: 'date',
    filterFn: 'greaterThan',
    enableGlobalFilter: false,
    Cell: ({ cell }) => new Date(cell.getValue<Date>()).toLocaleString(),
  },
  {
    accessorKey: 'processing_time',
    size: 175,
    header: 'Processing time',
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'result_link',
    header: 'Result',
    size: 150,
    enableColumnFilter: false,
    Cell: ({ cell, row }) => (
      <Box>
        {row.getValue<string>('state') === 'finished' &&
          cell.getValue<string>() && (
            <Link
              href={cell.getValue<string>()}
              style={{ cursor: 'pointer', color: 'black' }}
              target="_blank"
            >
              Browse
            </Link>
          )}
      </Box>
    ),
    enableClickToCopy: true,
    muiCopyButtonProps: {
      fullWidth: true,
      startIcon: <ContentCopy />,
      sx: { justifyContent: 'flex-start' },
    },
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'error',
    header: 'Error',
    size: 200,
    enableClickToCopy: true,
    Cell: ({ cell }) => (
      <Box sx={{ textWrap: 'nowrap' }} title={cell.getValue<string>()}>
        {cell.getValue<string>()}
      </Box>
    ),
  },
  {
    accessorKey: 'outputs_path',
    header: 'Outputs path',
    size: 200,
    enableGlobalFilter: false,
  },
  {
    accessorKey: 'worker_id',
    header: 'Worker ID',
    size: 180,
  },
];
